import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { BehaviorSubject, fromEvent, merge, Observable, scheduled, Scheduler, Subscriber } from 'rxjs';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { map, mergeAll } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NetworkService {

  public isInternetAccessAvailable = new BehaviorSubject<boolean>(null);
  private online$;
  private offline$;

  constructor(
    private network: Network,
    private platform: Platform
  ) {
    this.platform.ready().then(() => {
      if (this.platform.is('cordova')) {
        if (this.network.type === 'none') {
          this.isInternetAccessAvailable.next(false);
        } else {
          this.isInternetAccessAvailable.next(true);
        }
        this.network.onDisconnect().subscribe(() => {
          this.isInternetAccessAvailable.next(false);
        });
        this.network.onConnect().subscribe(() => {
          this.isInternetAccessAvailable.next(true);
        });
      } else {
        this.checkConnection().subscribe((response: boolean) => {
          this.isInternetAccessAvailable.next(response);
        });
      }
    });
  }

  private checkConnection() {
    return merge(
      fromEvent(window, 'offline').pipe(map(() => false)),
      fromEvent(window, 'online').pipe(map(() => true)),
      new Observable(sub => {
        sub.next(navigator.onLine);
        sub.complete();
      })
    );
  }

  public get isInternetAvailable(): Observable<boolean> {
    return this.isInternetAccessAvailable.asObservable();
  }
}