import { Injectable } from '@angular/core';
import { SQLite, SQLiteObject } from '@awesome-cordova-plugins/sqlite/ngx';
import { Platform } from '@ionic/angular';
import { BehaviorSubject, catchError, from, map, Observable, of, Subscriber, switchMap, tap, throwError } from 'rxjs';
import { PlatformService } from '../platform/platform.service';
import { Storage } from '@ionic/storage-angular';
import { Logger, LoggingService } from 'ionic-logging-service';
import { StorageMap } from '@ngx-pwa/local-storage';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  private db: SQLiteObject = null;
  private dbReady: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private dataStream = new BehaviorSubject([]);
  private isCordova: boolean = this.platformService.isCordova;
  private logger: Logger;
  private mainDBName = 'data.mainDB';
  private tableNameKeyValue = 'printixapp_key_value';
  private prefix = 'ls.';

  constructor(
    protected platform: Platform,
    private platformService: PlatformService,
    protected SQLite: SQLite,
    private pwaStorage: StorageMap,
    private loggingService: LoggingService,
    private storage: Storage
  ) {
    this.logger = this.loggingService.getLogger("[StorageService]");
    const methodName = "ctor";
    this.logger.entry(methodName);
    this.init();
  }

  private init() {
    this.platform.ready().then(() => {
      if (this.isCordova) {
        this.createDB().then((SQLiteObject: SQLiteObject) => {
          this.db = SQLiteObject;
          this.createTable().subscribe(() => {});
        });
      }
    });
  }

  getDatabaseState() {
    return this.dbReady.asObservable();
  }

  private prefixKey(key: string): string {
    return `${this.prefix}${key}`;
  }

  private createDB(): Promise<void | SQLiteObject> {
    this.logger.info('CREATE_DB');
    return this.SQLite.create({
      name: this.mainDBName,
      location: 'default'
    }).then((SQLiteObject: SQLiteObject) => {
      return SQLiteObject;
    }, (error: any) => {
      this.logger.info('SQLite.create() ERROR: ' + JSON.stringify(error));
    });
  }

  private createTable(): Observable<void> {
    this.logger.info('CREATE_TABLE');
    let query: string = 'CREATE TABLE IF NOT EXISTS ' + this.tableNameKeyValue +
      ' (key TEXT PRIMARY KEY, value TEXT)';
    return from(this.db.executeSql(query, [])).pipe(
      map(() => {
        this.dbReady.next(true);
        return;
      }),
      catchError((error: any) => {
        this.logger.info('CREATE_TABLE() - ERROR: ' + JSON.stringify(error));
        return throwError(error);
      })
    );
  }

  public addItemToLocalStorage(key: string, value?: any, fromMethod?: string): Observable<void> {
      if (this.isCordova) {  // set items in storage for devices
        return this.addItemToLocalStorage_native(key, value, fromMethod);
      } else {  // set items in storage for browser
        return this.addItemToLocalStorage_pwa(key, value, fromMethod);
      }
  }

  private addItemToLocalStorage_native(key: string, value?: any, fromMethod?: string): Observable<void> {
    this.logger.info('addItemToLocalStorage_native: ' + 'key: ' + key + ' fromMethod: ' + fromMethod);
    value = JSON.stringify(value);
    let query: string = 'INSERT OR REPLACE INTO ' + this.tableNameKeyValue + ' (key, value) VALUES (?, ?)';
    let values: Array<any> = [key, value];
    return this.executeQuery(query, values).pipe(
      map(() => {
        return; // Emit void
      }),
      catchError((error: any) => {
        this.logErrorMessage(error, 'addItemToLocalStorage_native(), executeQuery');
        return throwError(error);
      })
    );
  }

  private addItemToLocalStorage_pwa(key: string, value?: any, fromMethod?: string): Observable<void> {
    this.logger.info('STORING PREFIXED KEY: ' + 'key: ' + key + ' fromMethod: ' + fromMethod);
    return this.pwaStorage.set(this.prefixKey(key), value).pipe(
      map(() => {
        return; // Emit void
      }),
      catchError((error: any) => {
        this.logErrorMessage(error, 'addItemToLocalStorage_native(), pwaStorage.set');
        return of(error); // Emit void on error
      })
    );
  }

  public getItemFromLocalStorage(key: string, fromMethod?: string): Observable<any> {
    this.logger.info('getItemFromLocalStorage()' + 'key: ' + key + ' fromMethod: ' + fromMethod);
      if (this.isCordova) { // get stored items for devices
      return this.getItemFromLocalStorage_native(key, fromMethod);
    } else { // get stored items for browser
      return this.getItemFromLocalStorage_pwa(key);
    }
  }

  private getItemFromLocalStorage_native(key: string, fromMethod?: string): Observable<any> {
    let query: string = 'SELECT value FROM ' + this.tableNameKeyValue + ' WHERE key=?';
    let values: Array<string> = [key];
    return this.executeQuery(query, values, fromMethod).pipe(
      map((storedItem: any) => {
        return storedItem;
      }),
      catchError((error: any) => {
        this.logErrorMessage(error, 'getItemFromLocalStorage_native(), executeQuery');
        return of(undefined); // Return a safe value or handle the error appropriately
      })
    );
  }

  private getItemFromLocalStorage_pwa(key: string): Observable<any> {
    return this.pwaStorage.get(this.prefixKey(key)).pipe(
      map((storedString: string | undefined) => {
        return storedString;
      }),
      catchError((error: any) => {
        this.logErrorMessage(error, 'getItemFromLocalStorage_pwa(), executeQuery');
        return of(error); // Return a safe value or handle the error appropriately
      })
    );
  }

  protected executeQuery(query: string, values: Array<any>, fromMethod?: string): Observable<any> {
    return new Observable((observer) => {
      this.db.executeSql(query, values).then((data: any) => {
        if (data.rows.length > 0) {
          if (data.rows.length === 1) {
            observer.next(JSON.parse(data.rows.item(0).value));
            observer.complete();
          } else {
            let listToReturn: Array<any> = [];
            for (let i = 0; i < data.rows.length; i++) {
              listToReturn.push(JSON.parse(data.rows.item(i).value));
            }
            observer.next(listToReturn);
            observer.complete();
          }
        } else {
          observer.next();
          observer.complete();
        }
      }, (error: any) => {
        this.logErrorMessage(error, 'executeQuery(), executeSql');
        return of(error); // Return a safe value or handle the error appropriately
      });
    });
  }

  public removeItemFromLocalStorage(key: string): Observable<void> {
      return new Observable((observer) => {
      if (this.isCordova) {
        let query: string = 'DELETE FROM ' + this.tableNameKeyValue + ' WHERE key=?';
        let values: Array<string> = [key];
        this.executeQuery(query, values).subscribe(() => {
          observer.next();
          observer.complete();
        }, (error: any) => {
          this.logErrorMessage(error, 'removeItemFromLocalStorage(), executeQuery');
        });
      } else {
        this.pwaStorage.delete(this.prefixKey(key)).subscribe({
          next: () => {
            observer.next();
            observer.complete();
          },
          error: (error: any) => {
            this.logErrorMessage(error, 'removeItemFromLocalStorage()');
            return of(error); // Return a safe value or handle the error appropriately
          }
        });
      }
    });
  }

  public removeAllItemsFromLocalStorage(): Observable<void> {
    if (this.isCordova) {
      // let query: string = 'DELETE FROM ' + this.tableNameKeyValue + ' WHERE key=?';
      // return this.executeQuery(query).pipe(
      // map(() => {
      //   return; // Emit void
      // }),
      // catchError((error: any) => {
      //   this.logErrorMessage(error, 'removeAllItemsFromLocalStorage(), executeQuery');
      //   return of(null); // Return a safe value or handle the error appropriately
      // })
    // )
    } else {
      return this.pwaStorage.clear().pipe(
        map(() => {
          return; // Emit void
        }),
        catchError((error: any) => {
          this.logErrorMessage(error, 'removeAllItemsFromLocalStorage(), pwaStorage.set');
          return of(error); // Emit void on error
        })
      );
    }
  }

  protected logErrorMessage(error: any, calledFunction: string): void {
    console.log('calledFunction', calledFunction);
    console.log('error', error);
    this.logger.info('logErrorMessage()' + calledFunction + ' ERROR: ' + JSON.stringify(error));
    // subscriber.error(error);
    // subscriber.complete();
  }
}
