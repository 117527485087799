<ion-content padding no-bounce text-center>
    <app-header
    [iconName]="'menu'"
    [title]="null"
    [menuToggleRight]="true"
    [showErrorMessageNetwork]="true">
    </app-header>
    <div class="error-network" *ngIf="!isInternetAccessAvailable">
        <span class="error-network-container">
            <i class="icon-warning"></i>
            <span>{{"NoAccessInternet" | translate}}</span>
        </span>
    </div>
    <div class="choose-printer" *ngIf="showPrinterList">{{'SelectPrinter' | translate}}</div>
    <div *ngIf="showPrinterList">
        <div class="printer-list-actions">
            <ion-searchbar
            placeholder="{{'Search' | translate}}"
            [(ngModel)]="myInput"
            (search)="onSearch($event, myInput)"
            (ionCancel)="onSearch($event, null)">
            </ion-searchbar>
        </div>
        <ion-list>
            <ion-item lines="none" class="queue-list-item" *ngFor="let printer of printerList">
                <div class="printer-queue-container">
                    <button class="select-printer-clickzone printer-queue" (click)="choosePrinterToRegister(printer)">
                        <div class="printer-id queues-list printer-id--online pull-left">
                            <div>{{printer.signId}}</div>
                        </div>
                    </button>
                    <button class="select-printer-clickzone printer-queue" (click)="choosePrinterToRegister(printer)">
                        <div class="queue-text pull-left">
                            <div class="queue-name">{{printer.name}} </div>
                            <div class="printer-location" *ngIf="printer.location">{{printer.location}}</div>
                        </div>
                    </button>
                    <button class="favorite-printer-clickzone printer-info" tappable (click)="presentModal('printerInfo', printer)">
                        <span class="favorite-printer-icon">
                            <i class="favorite-color info-icon">i</i>
                        </span>
                    </button>
                </div>
            </ion-item>
        </ion-list>
    </div>
    <div class="pagination-container" [ngClass]="{'iphone': platform.is('iphone')}" *ngIf="pagingObject.totalPages > 0 && showPrinterList && showPagination">
        <button class="btn btn-sm prev" (click)="currentPage > 0 ? getNextPage(true) : false" [disabled]="pagingObject.number === 0">
          <i class="icon-left-open"></i>
      </button>
      <span class="paging-numerics">{{pagingObject.number + 1}}/{{pagingObject.totalPages}}</span>
      <button class="btn btn-sm next" [disabled]="currentPage + 1 >= pagingObject.totalPages" (click)="getNextPage()">
          <i class="icon-right-open"></i>
      </button>
  </div>
</ion-content>