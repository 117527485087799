<!-- <ion-header>
  <ion-toolbar>
    <ion-title>capture</ion-title>
  </ion-toolbar>
</ion-header> -->
<!-- <ion-content no-bounce [ngClass]="{'hide-header': hideHeader}"> -->
<ion-content no-bounce>
  <!-- <ion-refresher id="refresher" (ionRefresh)="getWorkflows($event)" slot="fixed">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher> -->
  <!-- HEADER -->
  <div  [hidden]="false" #app_header> <!-- added div to be able to access a native element to hide/show header component  -->
    <app-header
      [iconName]="'menu'"
      [title]="null"
      [menuToggleRight]="true"
      [showErrorMessageNetwork]="true">
    </app-header>
    <!-- TOP_CONTENT -->
    <div class="top-content">
      <div class="greetings-container">
        <div class="greetings" *ngIf="user && user.name">
          <div *ngIf="user.embedded.authentication_details && user.embedded.authentication_details.source">
            <!-- <div>Signed in with method enabled</div> -->
            <span>{{'SignedInAs'| translate | stringular: user.name}}</span>
            <!-- <span>{{'SignedInWithAs'| translate | stringular: user.name : (signInMethods[user.embedded.authentication_details.source] | translate)}}</span> -->
          </div>
          <div *ngIf="!user.embedded.authentication_details || !user.embedded.authentication_details.source">
            <span ng-class="{'hide-greeting': path !== '/print'}">{{'SignedInAs'| translate | stringular: user.name}}</span>
          </div>
        </div>
        <div class="greetings" *ngIf="tenant && tenant.tenantData.hostingDomain">
          <span ng-class="{'hide-greeting': path !== '/print'}">{{tenant.tenantData.hostingDomain}}</span>
        </div>
      </div>
      <!-- ACTION_BUTTONS -->
      <div class="action-button-container">
        <div class="top-action-buttons">
          <!-- <button class="title-text" #capture_screen_title>{{'CapturePhoto' | translate}}</button> -->
          <!-- <button class="title-text" #preview_screen_title>{{'PreviewImage' | translate}}</button> -->
          <!-- <button class="title-text" #edit_screen_title>{{'EditImage' | translate}}</button> -->
          <!-- <button class="title-text" #crop_screen_title>{{'CropImage' | translate}}</button> -->
          <!-- <button class="title-text" #thumbnail_screen_title>{{'CapturedImages' | translate}}</button> -->
          <!-- <button class="title-text" #thumbnail_preview_screen_title>Captured Images</button> -->
          <!-- <button>Crop</button> -->
          <!-- <button class="size120 page-action-button"
            ion-button
            no-margin-horizontal
            icon-only
            large
            item-end
            (click)="checkPusherConnection()">
            <i class="icon-folder-plus"></i>
          </button> -->
          <button class="mr10 size120 page-action-button refresh-button"
            ion-button
            icon-only
            large
            item-end
            [hidden]="!showFrontCaptureScreen"
            (click)="getWorkflows()">
            <i class="icon-refresh"></i>
          </button>
          <button class="size120 page-action-button" #history_icon_button
            ion-button
            no-margin-horizontal
            icon-only
            large
            item-end
            [hidden]="!showFrontCaptureScreen"
            [disabled]="!isInternetAccessAvailable"
            (click)="goToCaptureHistoryPage()">
            <i class="icon-history"></i>
            <!-- This icon should have a flashing effect -->
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- NO_INTERNET_CONNECTION-->
  <div class="error-network" [hidden]="true" #error_network>
    <span class="error-network-container">
      <i class="icon-warning"></i>
      <span>{{"NoAccessInternet" | translate}}</span>
    </span>
  </div>
  <!-- PAGE_CONTENT -->
  <div class="capture__content">
    <!-- FRONT_CAPTURE_SCREEN -->
    <div data-role="page" id="frontcapturescreen"  #front_capture_screen>
      <!-- EMPTY_STATE -->
      <div class="empty-state pl20 pr20 height100" *ngIf="dataIsLoaded && workflows.length < 1">
        <div>
          <img src="https://assets.printix.net/img/v2/illustrations/no-print-jobs.svg" alt="no printjobs image">
          <div class="loader loader--sm loader--compact"><span></span></div>
          <h2 class="empty-state__heading ng-binding">
            {{'NoSearchResultWorkflows' | translate}}
          </h2>
        </div>
      </div>
      <div data-role="toolbar" data-type="header" data-position="fixed" id="header" data-tap-toggle="false">
      </div>
        <!-- WORKFLOW_LIST -->
        <div class="list-content" #workflow_list>
          <div class="list-row list__item"
          *ngFor="let workflow of workflows | orderBy : 'created'" (click)="selectWorkflow(workflow)">
          <button class="list-cell actionbutton">
            <div class="list-cell__title">
              <i class="icon-envelope-o" *ngIf="checkDestinationType(workflow) === 'Email'"></i>
              <i class="icon-onedrive" *ngIf="checkDestinationType(workflow) === 'MicrosoftOneDrive'"></i>
              <i class="icon-sharepoint" *ngIf="checkDestinationType(workflow) === 'MicrosoftSharePoint'"></i>
              <!-- <i class="icon-connection" *ngIf="checkDestinationType(workflow) === 'Connector'"></i> -->
              <span class="connector-icon" *ngIf="checkDestinationType(workflow) === 'Connector'">
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 2200 2200" style="enable-background:new 0 0 2200 2200;" xml:space="preserve">
                  <g>
                    <path d="M1100.15,2187.8l-461.68-461.68l77.54-16.69c54.1-11.65,111.15-32.41,164.99-60.03c51.04-26.19,95.45-56.92,125.05-86.51
                      c50.16-50.16,77.79-116.86,77.79-187.8c0-70.94-27.63-137.64-77.79-187.8s-116.86-77.79-187.8-77.79
                      c-70.94,0-137.64,27.63-187.8,77.79c-29.6,29.6-60.32,74.01-86.51,125.05c-27.62,53.84-48.38,110.89-60.03,164.99l-16.69,77.54
                      L5.54,1093.18l343.45-343.45c-90.22-34.29-175.51-85.45-228.94-138.88c-33.59-33.59-59.6-72.77-77.32-116.46
                      c-17.1-42.18-25.78-86.73-25.78-132.43c0-45.7,8.67-90.25,25.78-132.43c17.72-43.69,43.73-82.87,77.32-116.46
                      c33.59-33.59,72.77-59.6,116.46-77.32c42.18-17.1,86.73-25.78,132.43-25.78s90.25,8.67,132.43,25.78
                      c43.69,17.72,82.87,43.73,116.46,77.32c53.43,53.43,104.59,138.72,138.88,228.94L1100.15-1.44l343.45,343.45
                      c34.29-90.22,85.45-175.5,138.88-228.94c33.59-33.59,72.77-59.6,116.46-77.32c42.18-17.1,86.73-25.78,132.43-25.78
                      c45.7,0,90.25,8.67,132.43,25.78c43.69,17.72,82.87,43.73,116.46,77.32s59.6,72.77,77.32,116.46
                      c17.1,42.18,25.78,86.73,25.78,132.43c0,45.7-8.67,90.25-25.78,132.43c-17.72,43.69-43.73,82.87-77.32,116.46
                      c-53.43,53.43-138.72,104.59-228.94,138.88l343.45,343.45L1100.15,2187.8z M808.09,1773.55l292.07,292.07l972.43-972.43
                      L1687.91,708.5l71.79-19.6c99-27.02,203.27-82.94,259.47-139.14c50.16-50.16,77.79-116.86,77.79-187.8
                      c0-70.94-27.62-137.64-77.79-187.8c-50.16-50.16-116.86-77.79-187.8-77.79c-70.94,0-137.64,27.63-187.8,77.79l0,0
                      c-56.2,56.2-112.12,160.47-139.14,259.47l-19.6,71.79l-384.68-384.68L715.48,505.42l-19.6-71.79
                      c-27.02-98.99-82.94-203.27-139.14-259.47C506.57,124,439.88,96.38,368.94,96.38c-70.94,0-137.63,27.63-187.8,77.79
                      c-50.16,50.16-77.79,116.86-77.79,187.8c0,70.94,27.62,137.64,77.79,187.8c56.2,56.2,160.47,112.12,259.47,139.14l71.79,19.6
                      l-384.68,384.68l292.06,292.06c33.53-100.72,89.43-198.9,149.58-259.05c33.59-33.59,72.77-59.6,116.46-77.32
                      c42.18-17.1,86.73-25.78,132.43-25.78c45.7,0,90.25,8.67,132.43,25.78c43.69,17.72,82.87,43.73,116.46,77.32
                      c33.59,33.59,59.6,72.77,77.32,116.46c17.1,42.18,25.78,86.73,25.78,132.43c0,45.69-8.67,90.25-25.78,132.43
                      c-17.72,43.69-43.73,82.87-77.32,116.46C1006.99,1684.12,908.81,1740.02,808.09,1773.55z"/>
                  </g>
                </svg>
              </span>
              <span class="list__item-title name">{{workflow.name}}</span>
            </div>
            <div>
              <span>{{'FileType' | translate}}: </span>
              <span class="list__item-title">{{workflow.fileType | translate}}</span>
            </div>
            <div>
              <span>{{'ImageQuality' | translate}}: </span>
              <span class="list__item-title">{{workflow.imageQuality | translate}}</span>
            </div>
            <div>
              <span>{{'Destination' | translate}}: </span>
              <span class="list__item-title" *ngFor="let destination of workflow.destinations">
                <!-- <div *ngIf="checkDestinationType(workflow) === 'Email'">{{destination}}</div> -->
                <div>{{checkDestinationType(workflow) | translate}}</div>
              </span>
            </div>
            <div *ngIf="checkPermissionToShowActiveWorkflow()">
              <span>{{'Active' | translate}}: </span>
              <span class="list__item-title" *ngIf="workflow.active === true">{{'Yes' | translate}}</span>
              <span class="list__item-title" *ngIf="workflow.active === false">{{'No' | translate}}</span>
            </div>
          </button>
          </div>
          <!-- <div class="list-row list__item">
            <button class="list-cell actionbutton" (click)="selectWorkflow(workflow)">
              <div>
                <span class="list__item-title name">Capture Documents</span>
              </div>
              <div>
                <span>File Type: </span>
                <span class="list__item-title">PDF</span>
              </div>
              <div>
                <span>Image Quality: </span>
                <span class="list__item-title">Medium</span>
              </div>
              <div>
                <span>Destination: </span>
                <span class="list__item-title">
                  <div>Microsoft OneDrive</div>
                </span>
              </div>
            </button>
          </div> -->
          <!-- <div class="list-row list__item">
            <button class="list-cell actionbutton" (click)="selectWorkflow(workflow)">
              <div>
                <span class="list__item-title name">Capture Documents</span>
              </div>
              <div>
                <span>File Type: </span>
                <span class="list__item-title">Microsoft Word</span>
              </div>
              <div>
                <span>Image Quality: </span>
                <span class="list__item-title">Medium</span>
              </div>
              <div>
                <span>Destination: </span>
                <span class="list__item-title">
                  <div>Microsoft SharePoint Online</div>
                </span>
              </div>
            </button>
          </div> -->
        </div>
    </div>

    <!-- CAPTURE_SCREEN -->
    <div data-role="page" id="capturescreen" #capture_screen>
      <div class="capture-header">
        <i class="icon-close" tappable (click)="promptForCancellingCapture()" [hidden]="true" #capture_button></i>
        <i class="icon-flash-solid" tappable (click)="flashButtonClicked()" #flash_button_on></i>
        <i class="icon-flash" [ngClass]="{'disabled': !isFlashSupport}" tappable (click)="flashButtonClicked()" [hidden]="true" #flash_button_off #capture_button></i>
        <div class="error-network-content" [hidden]="true" #error_network>
          <ion-icon class="icon-warning"></ion-icon>
          <span>{{"NoAccessInternet" | translate}}</span>
        </div>
      </div>
      <div data-role="content" id="captureViewContent" style="padding: 0; margin: 0;"></div>
      <!-- CAMERA_FOOTER -->
      <div class="capture-footer" id="capturefooter">
        <div class="capture-footer__camera-footer">
          <div class="capture-footer__camera-footer__button-left">
            <!-- <img src="./assets/images/gallery.png" class="center" id=""/> -->
          </div>
          <div class="capture-footer__camera-footer__button-center take-picture-button" (click)="forceTakePicture()" [hidden]="true" #capture_button>
            <img src="./assets/images/camera_icon.png" class="center" id=""/>
          </div>
          <div class="capture-footer__camera-footer__button-right gallery-img" (click)="initiateGallery()" [hidden]="true" #capture_button>
            <img src="./assets/images/gallery.png" class="center" id=""/>{{'Gallery' | translate}}
          </div>
        </div>
      </div>
    </div>

    <!-- PREVIEW_SCREEN  -->
    <div data-role="page" id="previewscreen" #preview_screen>
      <div class="capture-header">
        <i class="icon-close" tappable (click)="promptForCancellingCapture()" #capture_button></i>
        <div class="error-network-content" [hidden]="true" #error_network>
          <ion-icon class="icon-warning"></ion-icon>
          <span>{{"NoAccessInternet" | translate}}</span>
        </div>
      </div>
      <!-- PREVIEW_FOOTER -->
      <div class="capture-footer" id="capturefooter">
        <div class="capture-footer__preview-footer">
          <div class="capture-footer__preview-footer__button-left" (click)="retakeAction(true)" #capture_button>
            <img src="./assets/images/Camera/Camera.png" class="center"/>{{'Retake' | translate}}
          </div>
          <div class="capture-footer__preview-footer__button-center" (click)="showEditScreen()" #capture_button><i class="icon-modify"></i>{{'Edit' | translate}}</div>
          <div class="capture-footer__preview-footer__button-right" (click)="useImage()" #capture_button><i class="icon-check"></i>{{'Use' | translate}}<!-- <img src="./assets/images/gallery.png" class="center" id=""/> --></div>
        </div>
      </div>
    </div>

    <!-- EDIT_SCREEN  -->
    <div data-role="page" id="editscreen" #edit_screen>
      <div class="capture-header">
        <i class="icon-close" tappable (click)="promptForCancellingCapture()"></i>
        <div class="error-network-content" [hidden]="true" #error_network>
          <ion-icon class="icon-warning"></ion-icon>
          <span>{{"NoAccessInternet" | translate}}</span>
        </div>
      </div>
      <!-- EDIT_FOOTER -->
      <div class="capture-footer" id="editfooter">
        <div class="capture-footer__edit-footer">
          <div class="capture-footer__edit-footer__button-left" (click)="showCroppingTetragon()"><i class="icon-crop"></i>{{'Crop' | translate}}</div>
          <div class="capture-footer__edit-footer__button-center" (click)="editImage('rotateImage')"><i class="icon-synchronize"></i>{{'Rotate'| translate}}</div>
          <!-- <div class="capture-footer__edit-footer__button-center" (click)=""><i class="icon-delete"></i>Delete</div> -->
          <div class="capture-footer__edit-footer__button-right" (click)="useImage()"><i class="icon-check"></i>{{'Use' | translate}}<!-- <img src="./assets/images/gallery.png" class="center" id=""/> --></div>
        </div>
      </div>
    </div>

    <!-- CROP_SCREEN  -->
    <div data-role="page" id="cropscreen" #crop_screen>
      <div class="capture-header">
        <i class="icon-close" tappable (click)="promptForCancellingCapture()"></i>
        <div class="error-network-content" [hidden]="true" #error_network>
          <ion-icon class="icon-warning"></ion-icon>
          <span>{{"NoAccessInternet" | translate}}</span>
        </div>
      </div>
      <!-- CROP_FOOTER -->
      <div class="capture-footer" id="editfooter">
        <div class="capture-footer__edit-footer">
          <!-- <div class="capture-footer__edit-footer__button-left" (click)="cancelCropping()"><i class="icon-close"></i>{{'Close' | translate}}</div> -->
          <div class="capture-footer__edit-footer__button-left"></div>
          <div class="capture-footer__edit-footer__button-center" (click)="editImage('cropImage')"><i class="icon-check"></i>{{'Done' | translate}}</div>
          <div class="capture-footer__edit-footer__button-right"></div>
        </div>
      </div>
    </div>

    <!-- THUMBNAIL_SCREEN  -->
    <div data-role="page" id="thumbnailscreen" #thumbnail_screen>
      <div class="capture-header">
        <i class="icon-close" tappable (click)="promptForCancellingCapture()"></i>
        <div class="error-network-content" [hidden]="true" #error_network>
          <ion-icon class="icon-warning"></ion-icon>
          <span>{{"NoAccessInternet" | translate}}</span>
        </div>
      </div>
      <!-- <div>
        <ion-button (click)="presentPopover($event)">Click Me</ion-button>
        <ion-popover #popover (didDismiss)="isOpen = false">
          <ng-template>
            <ion-content class="ion-padding">Hello World!</ion-content>
          </ng-template>
        </ion-popover>
      </div> -->
      <div class="capture-thumbnails">
        <!--  SHOW/HIDE TAB IMAGE TEXT  -->
        <h6 class="capture_1_h61" *ngIf="showTabImageText" #show_tab_image_text>Tap on image to preview</h6>
        <!--  TUMBNAILS  -->
        <div class="thumbnail-container" #thumbnail_container>
          <div class="image-div" (click)="previewProcessedImage(imgObj)" *ngFor="let imgObj of processedImagesArray">
            <img [src]="imgObj.base64String" class="thumbnail-image" id="imgObj.imgID" draggable="false" #id_frontthumbnail />
          </div>
        </div>
      </div>

      <!-- THUMBNAIL_FOOTER -->
      <div class="capture-footer" id="capturefooter">
        <div class="capture-footer__thumbnail-footer">
          <div class="capture-footer__thumbnail-footer__button-left" (click)="retakeAction(false)"><i class="icon-add"></i><span class="button-text">{{'Add' | translate}}</span></div>
          <div class="capture-footer__thumbnail-footer__button-center"><span class="button-text"></span></div>
          <div class="capture-footer__thumbnail-footer__button-right" (click)="startCaptureUpload()"> <i class="icon-freedom"></i><span class="button-text">{{'Send' | translate}}</span></div>
        </div>
      </div>
    </div>

     <!-- PREVIEW_PROCESSED_IMAGE  -->
     <div data-role="page" id="thumbnailpreviewscreen" #thumbnail_preview_screen>
      <div class="capture-header">
        <i class="icon-close" tappable (click)="promptForCancellingCapture()"></i>
        <div class="error-network-content" [hidden]="true" #error_network>
          <ion-icon class="icon-warning"></ion-icon>
          <span>{{"NoAccessInternet" | translate}}</span>
        </div>
      </div>
      <!-- PREVIEW_PROCESSED_IMAGE_FOOTER -->
      <div class="capture-footer" id="">
        <div class="capture-footer__edit-footer">
          <div class="capture-footer__preview-processed-image-footer__button-left" (click)="useImage()"><i class="icon-close"></i>{{'Close' | translate}}</div>
          <div class="capture-footer__preview-processed-image-footer__button-center"></div>
          <div class="capture-footer__preview-processed-image-footer__button-right" (click)="deleteProcessedImageFromArray()"><i class="icon-delete"></i>{{'Delete' | translate}}</div>
        </div>
      </div>
    </div>
  </div>
</ion-content>
