import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EmailComposer } from '@awesome-cordova-plugins/email-composer/ngx';
import { Observable, Subject, Subscriber } from 'rxjs';
import { delay, finalize, retryWhen, take, takeUntil, tap } from 'rxjs/operators';
import { DialogService } from '../dialog/dialog.service';
import { ErrorService } from '../error/error.service';
import { TenantService } from '../tenant/tenant.service';
import { IUser } from '../user/models/user.model';
import { UserService } from '../user/user.service';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { Logger, LoggingService } from 'ionic-logging-service';
import { Platform } from '@ionic/angular';
import { Broadcaster } from '../events/broadcaster.class';

@Injectable({
  providedIn: 'root'
})
export class SendLogsService {

  private allLogs: string = null;
  private arrayOfLogs: Array<string> = [];
  private currentUser: IUser = null;
  private deviceLogsUploadUrl: string = null;
  private logger: Logger;
  private logUploadHeaders: {} = null;
  private logUploadResourceUrl: string = null;
  private logUploadUrl: string = null;
  // private EMAIL_FOR_LOGS: string = 'gambarle@gmail.com';
  private EMAIL_FOR_LOGS: string = 'support+id4710@printix.zendesk.com';
  private SUPPORT_TICKET_ID: number =  null;
  private unSubscribe = new Subject<void>();
  private httpErrorResponse: HttpErrorResponse;

  constructor (
    private broadcaster: Broadcaster,
    private dialogService: DialogService,
    private emailComposer: EmailComposer,
    private errorService: ErrorService,
    private file: File,
    private httpClient: HttpClient,
    private loggingService: LoggingService,
    private platform: Platform,
    private tenantService: TenantService,
    private userService: UserService
  )
  {
    this.logger = loggingService.getLogger("[SendLogsService]");
    const methodName = "ctor";
    this.logger.entry(methodName);
  }

  private getLogsOnDevice(): Observable<any> {
    this.logger.info('getLogsOnDevice()');
    return new Observable<any>((observer: Subscriber<any>) => {
      let logs = this.loggingService.getLogMessagesFromLocalStorage('deviceLogs');

      observer.next(logs);
      observer.complete();
    });
  }

  public handleLogsForEmail() {
    this.logger.info('handleLogsForEmail()');

    this.getLogsOnDevice().subscribe((entries: any) => {

      this.createLogArray(entries);

      }, (getLogsError) => {
        this.logger.info('getLogsError: ' + JSON.stringify(getLogsError));
      });
  }

  private createLogArray(entries) {
    this.logger.info('createLogArray()');

    const logArray = this.mapEntriesToArray(entries);
    const logText = logArray.join('\r\n');

    this.createFileForLogs(logText);
  }

  private mapEntriesToArray(entries) {
    this.logger.info('mapEntriesToArray()');
    return entries.map(item => {
      const timeStamp = item['timeStamp'].toString();
      const dateTime = timeStamp.split('(')[0];
      const log = dateTime + item['logger'] + item['methodName'];
      return log;
    });
  }

  private createFileForLogs(logText) {
    this.logger.info('createFileForLogs()');

    this.broadcaster.broadcast('DISABLE_RESUME');
    this.file.createFile(this.file.dataDirectory, 'logs', true).then((fileCreatedData) => {
      let logTextBlob = new Blob([logText], { type: 'plain/text'});
      // GET_ANDROID_PRINT_LOGS
      this.file.writeFile(this.file.dataDirectory, 'logs', logTextBlob, {replace: true, append: false}).then((fileWrittenData) => {
        if (this.platform.is('android')) {
          this.appendAndroidPrintLogs(fileWrittenData);
        } else {
          this.openEmailClient(fileWrittenData["nativeURL"]);
        }
      });
    }, (error) => {
      this.broadcaster.broadcast('ENABLE_RESUME');
      this.logger.error('Error on creating logs-file for email: ' + JSON.stringify(error));
    });
  }

  private appendAndroidPrintLogs(ionicLogsFile) {
    this.logger.info('appendAndroidPrintLogs()');
    this.readAndroidPrintLogs().subscribe((androidPrintLogs) => {
      this.file.writeFile(this.file.dataDirectory, 'logs', androidPrintLogs, {replace: false, append: true}).then((fileWrittenData) => {
        this.emailComposer.hasAccount().then((hasAccount) => {
          this.openEmailClient(fileWrittenData["nativeURL"]);
        });
      });
    }, (error) => {
      this.logger.error('Error on appending androidPrintLogs-file for email: ' + JSON.stringify(error));
      this.emailComposer.hasAccount().then((hasAccount) => {
        this.openEmailClient(ionicLogsFile["nativeURL"]);
      });
    });
  }

  public readAndroidPrintLogs(): Observable<any> {
    this.logger.info('readAndroidPrintLogs()');
    const logFileName = 'android_print_logs.txt';
    const logFilePath = this.file.dataDirectory; // Adjust the path if necessary
    return new Observable<any>((observer: Subscriber<any>) => {
      this.file.readAsText(logFilePath, logFileName).then(androidPrintLogs => {
        observer.next(androidPrintLogs);
        observer.complete();
      }).catch(error => {
        console.error('Error reading logs:', error);
        observer.error(error);
        observer.complete();
      });

    });
  }

  private openEmailClient(logsURL) {
    this.logger.info('openEmailClient()');
    this.currentUser = this.userService.user;
    let subject = this.currentUser ? 'Logs from Printix App, User: ' + this.currentUser.name : 'Logs from Printix App';

    let email = {
      to: '',
      cc: '',
      bcc: '',
      attachments: logsURL,
      subject: subject,
      body: '',
      isHtml: false
    };

    this.emailComposer.open(email).then((res) => {
      // Consider showing modal for email sendt! but we don't know if the emial was sendt or cancelled
      this.file.removeFile(this.file.dataDirectory, 'logs');
      this.broadcaster.broadcast('ENABLE_RESUME');
    });
  }
}