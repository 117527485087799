import { ChangeDetectorRef, Component, ContentChildren, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { CaptureService } from 'src/app/services/capture/capture.service';
import { CaptureUtils } from './capture-files/capture-utils';
import { CaptureScreenHelperComponent } from './capture-files/capture-screen-helper/capture-screen-helper.component';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { IUser } from 'src/app/services/user/models/user.model';
import { ITenant } from 'src/app/services/tenant/models/tenant.model';
import { NavController, Platform, ToastController } from '@ionic/angular';
import { Logger, LoggingService } from 'ionic-logging-service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { UserService } from 'src/app/services/user/user.service';
import { TenantService } from 'src/app/services/tenant/tenant.service';
import { first, takeUntil } from 'rxjs/operators';
import { Observable, Subject, Subscription } from 'rxjs';
import { ITenantLite } from 'src/app/services/tenant/models/tenant-lite.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { IWorkflow } from 'src/app/services/capture/models/workflow.model';
import { Camera, CameraOptions, DestinationType } from '@awesome-cordova-plugins/camera/ngx';
import { CaptureReviewService } from 'src/app/services/capture/capture-review.service';
import { CaptureProcessorService } from 'src/app/services/capture/capture-processor.service';
import { Diagnostic } from '@awesome-cordova-plugins/diagnostic/ngx';
import { Broadcaster } from 'src/app/services/events/broadcaster.class';
import { CHANNELS, EventService } from 'src/app/services/events/event.service';
import { TranslateService } from '@ngx-translate/core';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { Flashlight } from '@awesome-cordova-plugins/flashlight/ngx';
import { ErrorService } from 'src/app/services/error/error.service';
import { NetworkService } from 'src/app/services/network/network.service';

const captionStringsForSDKMessages = [
	'CapturedMessage',
	'CenterMessage',
	'HoldParallelMessage',
	'HoldSteadyMessage',
	'RotateMessage',
	'TiltForwardMessage',
	'TiltUpMessage',
	'TutorialDismissMessage',
	'UserInstructionMessage',
	'ZoomInMessage',
	'ZoomOutMessage'
]

@Component({
  selector: 'app-capture',
  templateUrl: './capture.page.html',
  styleUrls: ['./capture.page.scss'],
})
export class CapturePage implements OnInit {

  public showThumnail = false;
  public  isInternetAccessAvailable = true;
  public workflows: Array<IWorkflow> = [];
  public selectedWorkflow: IWorkflow = null;
  public translatedSDKMessages = {};
  public selectedImageID: string = null;
  public  dataIsLoaded: boolean;
  public showWorkflowList = true;
  public currentScreen: string;
  public showFrontCaptureScreen = true;
  public showCaptureScreen = false;
  public presentPreviewScreen = false;
  public showThumbnailScreen = false;
  public showTabImageText = false;
  public isProcessComplete = false;
  public extractionErrorMessage = "";
  public currentImg = {};
  public scanServerProcessState = null;
  private reProcess = false;
  private imageIsEdited = false;
  private ImageReviewControl;
  private boundingTetragon = null;
  private imageManuallyCropped = false;
  private galleryImageSelected = false;
  public nav: any;

  private captureCameraOptions = null;
  private uiProcessOptions = { doQuickAnalysis: false, usedefaultsettings: true, ipString:"" };
  public  tenant: ITenant = null;
  public  user: IUser = null;
  private useVideoFrame = false;
  private capturedImage = null;
  private processedImage = null;
  private capturedImageToDelete = null;
  private processedImageToDelete = null;
  public processedImagesArray = [];
  private isRetakeCancel = false;
  private isForImagePreview = false;
  private isRetakeClicked = false;
  private license: any;
  private mSdkVersion: any

  private defaultProcessString_Old =
  "_DeviceType_2"+
  "_DoBinarization_"+
  "_DoCropCorrection_"+
  "_DoSkewCorrectionAlt_4"+
//   "_DoSharpen_3"+
//   "_LoadInlineSetting_[CSkewDetect.convert_to_gray.Bool=1]"+
//   "_LoadInlineSetting_[CSkewDetect.scale_image_down.Bool=1]"+
//   "_LoadInlineSetting_[CSkewDetect.scale_down_factor.Int=80]"+
//   "_LoadInlineSetting_[CSkewDetect.document_size.Int=2]"+
//   "_LoadInlineSetting_[CSkewDetect.correct_illumination.Bool=0]"+
//   "_Do90DegreeRotation_4"+
//   "_DoScaleImageToDPI_500"+
  "";

  private processingString =
  "_DeviceType_2_"+
  "_DoBinarization_"+
  "_DoCropCorrection_"+
  "_DoScaleImageToDPI_500"+
  "_DoSkewCorrectionPage_"+
  "_DocDimLarge_3.375"+
  "_DocDimSmall_2.125"+
  "_LoadInlineSetting_"+
  "[CSkewDetect.correct_illumination.Bool=0]"+
  "";

  private processingString_noCrop =
  "_DeviceType_2_"+
  "_DoBinarization_"+
  "_DoScaleImageToDPI_500"+
  "_DoSkewCorrectionPage_"+
  "_DocDimLarge_3.375"+
  "_DocDimSmall_2.125"+
  "_LoadInlineSetting_"+
  "[CSkewDetect.correct_illumination.Bool=0]"+
  "";

//   private processingString = this.defaultProcessString;

//  private processString_Gallery =
// 	"_DeviceType_2"+
// 	"_DoBinarization_"+
// 	"_DoCropCorrection_"+
// 	"_DoSkewCorrectionAlt_4"+
// 	"_DoSharpen_3"+
// 	"_LoadInlineSetting_[CSkewDetect.convert_to_gray.Bool=1]"+
// 	"_LoadInlineSetting_[CSkewDetect.scale_image_down.Bool=1]"+
// 	"_LoadInlineSetting_[CSkewDetect.scale_down_factor.Int=80]"+
// 	"_LoadInlineSetting_[CSkewDetect.document_size.Int=2]"+
// 	"_LoadInlineSetting_[CSkewDetect.correct_illumination.Bool=0]"+
// 	"_Do90DegreeRotation_4"+
// 	"_DoScaleImageToDPI_500"+
// 	"";

// 	private processString_Gallery_noCrop =
// 	"_DeviceType_2"+
// 	"_DoBinarization_"+
// 	"_DoSkewCorrectionAlt_4"+
// 	"_DoSharpen_3"+
// 	"_LoadInlineSetting_[CSkewDetect.convert_to_gray.Bool=1]"+
// 	"_LoadInlineSetting_[CSkewDetect.scale_image_down.Bool=1]"+
// 	"_LoadInlineSetting_[CSkewDetect.scale_down_factor.Int=80]"+
// 	"_LoadInlineSetting_[CSkewDetect.document_size.Int=2]"+
// 	"_LoadInlineSetting_[CSkewDetect.correct_illumination.Bool=0]"+
// 	"_Do90DegreeRotation_4"+
// 	"_DoScaleImageToDPI_500"+
// 	"";

//   private processingString_noCrop =
//   "_DeviceType_2"+
//   "_DoBinarization_"+
//   "_DoSkewCorrectionAlt_4";

  private defaultProcessString_CREDIT_CARD = "_DeviceType_2_Do90DegreeRotation_4_DoCropCorrection_DoScaleImageToDPI_300_DoSkewCorrectionPage__DocDimLarge_3.375_DocDimSmall_2.125_LoadInlineSetting_[CSkewDetect.correct_illumination.Bool=0]_LoadInlineSetting_[CSkewDetect.double_bkg_check_variability_thr.Int=-1]_LoadInlineSetting_[CSkewDetect.color_stats_error_sum_thr_white_bkg=24]";

  // MobileID
  CLASSIFIER_PROCESSING_PARAMS_2_0 =
  "_DeviceType_2__Do90DegreeRotation_4__DoCropCorrection__DoScaleImageToDPI_500_DoSkewCorrectionPage__DocDimLarge_3.375_DocDimSmall_2.125_LoadInlineSetting_[CSkewDetect.correct_illumination.Bool=0]";

  // German New Ids
  CLASSIFIER_PROCESSING_PARAMS_ID1 =
  "_DeviceType_2_ScalingMode_2.4_DoSkewCorrectionPage__DoCropCorrection__Do90DegreeRotation_4_DoScaleImageToDPI_300_DocDimSmall_2.123_DocDimLarge_3.363_LoadInlineSetting_[CSkewDetect.prorate_error_sum_thr_bkg_brightness.Bool=1]_LoadInlineSetting_[CSkwCor.Do_Fast_Rotation.Bool=0]_LoadInlineSetting_[CSkewDetect.correct_illumination.Bool=0]_LoadInlineSetting_[CSkwCor.Fill_Color_Scanner_Bkg.Bool=0]_LoadInlineSetting_[CSkwCor.Fill_Color_Red.Byte=255]_LoadInlineSetting_[CSkwCor.Fill_Color_Green.Byte=255]_LoadInlineSetting_[CSkwCor.Fill_Color_Blue.Byte=255]_LoadInlineSetting_[EdgeCleanup.enable=0]";

  ODE_PROCESSING_PARAMS =
  '_DeviceType_2_Do90DegreeRotation_4_DoCropCorrection_DoScaleImageToDPI_500_DoSkewCorrectionPage__DocDimLarge_3.375_DocDimSmall_2.125_LoadSetting_<Property Name="CSkewDetect.correct_illumination.Bool" Value="0"';

  private mobileidIPPUIProcessorOptions = {
	  mode: "_DoColor_",
	  autoCrop: "_DoCropCorrection_",
	  autoRotate: "_Do90DegreeRotation_4",
	  deskew: "true",
	  deskewBy: "_DoSkewCorrectionPage_",
	  backgroundSmoothing: "",
	  scaleDpi: "_DoScaleImageToDPI_300",
	  despeckle: "0",
	  sharpen: "",
	  cSkewSettings: "true",
	};

	private ippObject = {
		isDefaultSettings: true,
		processorOptions: "",
		defaultProcessString: "",
		commonProcessString: "",
		cskewString: "",
	};

	private extractedResults = null;

	private FIELD_BILLERS = "Billers";
    private FIELD_SOURCE = "Source";
    private FIELD_DUEDATE = "DueDate";

	public serverSettingsOptions = {
        serverType: "RTTI", //KTA
        serverURLForRTTI: 'https://mobiledemo.kofax.com:443/mobilesdk/api/billpay?customer=Kofax',
        serverURLForKTA: "",
        ktaSessionID:"C640521793431F4486D4EF1586672385",
        processIdentityName: "KofaxBillPaySync"
    };


   //Reference variable for ImageCaptureControl
	private imageCaptureControl = null;
	// Reference variable for setting image/video mode on ImageCaptureControl
	private imageCaptureControlOptions;
	//Reference variable for DocumentCaptureExperience
	private documentCaptureExperience = null;
  	private documentCaptureExperienceOptions = null;
	//Reference variable for LayoutProperties
	private layoutProperties = null;
	//Reference variable for ImageObject
	private imageObject = null;
	private imageArray: any
	private imageCaptureControlId = null;
	private imageprocessSuccessCB;
	private imageprocessErrorCB;
	// private imgCapturedSuccessCallback;
  	// private imgCapturedErrorCallback;
	private footerHeight = null;
	private isResumed = false;
	private isAnimationNotFinished = true;
	private animStartTime;
	private animEndTime;

  private logger: Logger;
  public getTenantAndUserSubscription = new Subject<void>();
  public scanCreatedSubscription: Subscription = null;
  public workflowCreatedSubscription: Subscription = null;
  public workflowUpdatedSubscription: Subscription = null;
  public workflowDeletedSubscription: Subscription = null;
  public scanUpdatedSubscription: Subscription = null;
  public scanDeletedSubscription: Subscription = null;
  public isInternetAvailableSubscription: Subscription = null;
  public showTransparentBody = false;
  public platformResumeEventSubscription: Subscription = null;


  // FROM CAPTURE SCREEN_HELPER COMPONENT //
  	private manualTimer = null;
	private pictureTaken = false;
	private forcePictureTaken = false;
	private galleryButtonClicked = false;
	private retakeActionClicked = false;
	private editImageClicked = false;
	private cancelClicked = false;
	private isflashOn = false;
	private isOpen = false;
	public isFlashSupport = true;
	public backButtonAllowed = true;

	// FROM CAPTURE_PROCESSOR_SERVICE //
	 //Alias name for kfxCordova.kfxEngine.ImageProcessor
	 private ImageProcessor = null;

	 //Reference variable for ImageProcessor Options
	 private imageProcessorOptions = null;

	// VIEW_CHILDREN //
	@ViewChild("app_header") appHeader: any;
	@ViewChild("workflow_list") workflowList: any;
	@ViewChild("history_icon_button") historyIconButton: any;
	@ViewChild("thumbnail_container") thumbnailContainer: any;
	// @ViewChild("id_frontthumbnail") imgFrontElement: any;
	@ViewChild("front_capture_screen") frontCaptureScreen: any;
	@ViewChild("capture_screen") captureScreen: any;
	// @ViewChild("capture_screen_title") captureScreenTitle: any;
	@ViewChild("preview_screen") previewScreen: any;
	// @ViewChild("preview_screen_title") previewScreenTitle: any;
	@ViewChild("edit_screen") editScreen: any;
	// @ViewChild("edit_screen_title") editScreenTitle: any;
	@ViewChild("crop_screen") cropScreen: any;
	// @ViewChild("crop_screen_title") cropScreenTitle: any;
	@ViewChild("thumbnail_screen") thumbnailScreen: any;
	// @ViewChild("thumbnail_screen_title") thumbnailScreenTitle: any;
	@ViewChild("thumbnail_preview_screen") thumbnailPreviewScreen: any;
	// @ViewChild("thumbnail_preview_screen_title") thumbnailPreviewScreenTitle: any;
	@ViewChild("flash_button_on") flashButtonOn: any;
	@ViewChild("flash_button_off") flashButtonOff: any;
	// @ViewChild('popover') popover: any;
	@ViewChildren("id_frontthumbnail") thumbnailContainerImages: QueryList<ElementRef>;
	@ViewChildren("capture_button") captureButtons: QueryList<ElementRef>;
	@ViewChildren("error_network") errorNetwork: QueryList<ElementRef>;

  constructor (
	private androidPermissions: AndroidPermissions,
    private authService: AuthService,
    private broadcaster: Broadcaster,
	private camera: Camera,
    private captureService: CaptureService,
    private captureReviewService: CaptureReviewService,
    private captureProcessorService: CaptureProcessorService,
    private device: Device,
    private eventService: EventService,
    private diagnostic: Diagnostic,
    private dialogService: DialogService,
    private errorService: ErrorService,
	private flashlight: Flashlight,
    private loggingService: LoggingService,
    public  navCtrl: NavController,
    private networkService: NetworkService,
    private platform: Platform,
    private changeDetector: ChangeDetectorRef,
    private storageService: StorageService,
    private tenantService: TenantService,
    private toastController: ToastController,
    private translateService: TranslateService,
    public  userService: UserService,

  ) {

		if (this.platform.is('android')) {
	        this.platform.backButton.subscribeWithPriority(-1, () => { // handling Android device 'back' button
				this.logger.info('Android backButton clicked - this.currentScreen' + this.currentScreen);
				console.log('Android backButton clicked - this.currentScreen', this.currentScreen);

				if (!this.showFrontCaptureScreen && this.backButtonAllowed) {
					this.backButtonAllowed = false;
					switch (this.currentScreen) {
						case 'captureScreen':
							// this.cancelCapture();
							console.log('## PROMPT_FOR_CAPTURE_SCREEN');
							this.promptForCancellingCapture();
						break;
						case 'previewScreen':
							this.retakeAction(true);
						break;
						case 'editScreen':
							this.retakeAction(true);
						break;
						case 'cropScreen':
							this.retakeAction(true);
						break;
						case 'thumbnailScreen':
							this.promptForCancellingCapture();
						break;
						case 'thumbnailPreviewScreen':
							this.useImage();
						break;
					}
				} {
					this.logger.info('## DO_NOTHING_ON_BACK_BUTTON_CLICK!!');
					console.log('## DO_NOTHING_ON_BACK_BUTTON_CLICK!!');
				}
	        });
	    }
	}

	ngOnInit() {
		this.platform.ready().then(() => {
			this.logger = this.loggingService.getLogger("[CapturePage]");
			const methodName = "ctor";
			this.logger.entry(methodName);
		});
	}

	ionViewWillLeave(): void {
		this.platformResumeEventSubscription.unsubscribe();
		this.scanCreatedSubscription.unsubscribe();
		this.workflowCreatedSubscription.unsubscribe();
		this.workflowUpdatedSubscription.unsubscribe();
		this.workflowDeletedSubscription.unsubscribe();
		this.scanUpdatedSubscription.unsubscribe();
		this.scanDeletedSubscription.unsubscribe();
		this.isInternetAvailableSubscription.unsubscribe();

	}

	ionViewWillEnter(): void {
		this.platform.ready().then(() => {
			this.logger.info('ionViewWillEnter()');
			this.dataIsLoaded = false;
			this.setViewElements('frontCaptureScreen').subscribe(() => {
				this.storageService.getItemFromLocalStorage('lastUsedTenant')
				.pipe(
					first() // Automatically unsubscribe after the first value is received)
				)
				.subscribe({
					next: (tenant: ITenantLite) => {
						this.logger.info('ionViewWillEnter() - got last used tenant');
						if (tenant) {
							this.dialogService.showLoadingSpinnerDialog('ionviewWillEnter() - refreshTenant/refreshUser').subscribe(() => {
								this.tenantService.refreshCurrentTenant(tenant.links.self)
								.pipe(takeUntil(this.getTenantAndUserSubscription))
								.subscribe((tenant) => {
									this.userService.refreshCurrentUser(this.tenantService.tenant.links.currentUser)
									.pipe(takeUntil(this.getTenantAndUserSubscription))
									.subscribe((user) => {
										// console.log('user', user);
										this.dialogService.hideLoadingSpinnerDialog('ionviewWillEnter CapturePage');
										this.startListener();
										this.user = user;
										this.tenant = tenant;
										this.removeCameraView();
										this.removeImageReview().subscribe(() => {
											let cordovaVersion = window['kfxCordova'].getCordovaVersion();
											let kfxPluginVersion = window['kfxCordova'].getkfxPluginVersion();
											console.log('cordovaVersion', cordovaVersion);
											console.log('kfxPluginVersion', kfxPluginVersion);

											this.getTenantAndUserSubscription.next();
											this.getTenantAndUserSubscription.complete();
											this.setDefaultCaptureCameraOptions();
											this.initializeFrontCaptureScreenEvents();
											this.eventService.startTokenExpireCountDown();
											this.getWorkflows();
											this.setLicense().subscribe(() => {
												console.log('## license set');
											});
										});
									});
								});
							});
						} else {
							this.authService.logOutUnAuthenticatedUser('ACCESS_DENIED');
						}
					},
					error: (e) => { this.logger.error('ionViewWillEnter() - error: ' + JSON.stringify(e)); },
					complete: () => {}
				});
			});
		});
	}

	public setViewElements(view): Observable<any> {
		this.logger.info('setViewElements() - View: ' + view);
		console.log('## setViewElements() - View: ', view);
		return new Observable((observer) => {
			this.currentScreen = view;
			switch (view) {
				case 'frontCaptureScreen':
					this.initializeFrontCaptureScreenEvents();
					this.showWorkflowList = true;
					this.showFrontCaptureScreen = true;
					this.showCaptureScreen = false;
					this.toggleCaptureButtons(true);

					this.appHeader.nativeElement.hidden = false;
					this.workflowList.nativeElement.hidden = false;
					this.frontCaptureScreen.nativeElement.hidden = false;
					this.captureScreen.nativeElement.hidden = true;
					// this.captureScreenTitle.nativeElement.hidden = true;
					this.previewScreen.nativeElement.hidden = true;
					// this.previewScreenTitle.nativeElement.hidden = true;
					this.editScreen.nativeElement.hidden = true;
					// this.editScreenTitle.nativeElement.hidden = true;
					this.cropScreen.nativeElement.hidden = true;
					// this.cropScreenTitle.nativeElement.hidden = true;
					this.thumbnailScreen.nativeElement.hidden = true;
					// this.thumbnailScreenTitle.nativeElement.hidden = true;
					this.thumbnailPreviewScreen.nativeElement.hidden = true;
					this.flashButtonOff.nativeElement.hidden = true;
					this.flashButtonOn.nativeElement.hidden = true;
					// this.popover.nativeElement.hidden = true;
					this.changeDetector.detectChanges();

					this.backButtonAllowed = true;
					observer.next();
					observer.complete();
				break;

				case 'captureScreen':
					this.showWorkflowList = false;
					this.showFrontCaptureScreen = false;
					this.showCaptureScreen = true;
					this.toggleCaptureButtons(true);

					console.log('this.captureButtons', this.captureButtons);

					this.appHeader.nativeElement.hidden = true;
					this.workflowList.nativeElement.hidden = true;
					this.frontCaptureScreen.nativeElement.hidden = true;
					this.captureScreen.nativeElement.hidden = false;
					// this.captureScreenTitle.nativeElement.hidden = false;
					this.previewScreen.nativeElement.hidden = true;
					// this.previewScreenTitle.nativeElement.hidden = true;
					this.editScreen.nativeElement.hidden = true;
					// this.editScreenTitle.nativeElement.hidden = true;
					this.cropScreen.nativeElement.hidden = true;
					// this.cropScreenTitle.nativeElement.hidden = true;
					this.thumbnailScreen.nativeElement.hidden = true;
					// this.thumbnailScreenTitle.nativeElement.hidden = true;
					this.thumbnailPreviewScreen.nativeElement.hidden = true;
					this.flashButtonOn.nativeElement.hidden = true;
					this.backButtonAllowed = true;

					observer.next();
					observer.complete();
				break;

				case 'previewScreen':
					this.showWorkflowList = false;
					this.showFrontCaptureScreen = false;
					this.showCaptureScreen = false;

					this.appHeader.nativeElement.hidden = true;
					this.workflowList.nativeElement.hidden = true;
					this.frontCaptureScreen.nativeElement.hidden = true;
					this.captureScreen.nativeElement.hidden = true;
					// this.captureScreenTitle.nativeElement.hidden = true;
					this.previewScreen.nativeElement.hidden = false;
					// this.previewScreenTitle.nativeElement.hidden = false;
					this.editScreen.nativeElement.hidden = true;
					// this.editScreenTitle.nativeElement.hidden = true;
					this.cropScreen.nativeElement.hidden = true;
					// this.cropScreenTitle.nativeElement.hidden = true;
					this.thumbnailScreen.nativeElement.hidden = true;
					// this.thumbnailScreenTitle.nativeElement.hidden = true;
					this.thumbnailPreviewScreen.nativeElement.hidden = true;
					if (this.isflashOn) {
						this.flashButtonClicked();
					} else {
						this.flashButtonOff.nativeElement.hidden = true;
						this.flashButtonOn.nativeElement.hidden = true;
					}
					this.backButtonAllowed = true;

					observer.next();
					observer.complete();
				break;

				case 'editScreen':
					this.showWorkflowList = false;
					this.showFrontCaptureScreen = false;
					this.showCaptureScreen = false;

					this.appHeader.nativeElement.hidden = true;
					this.workflowList.nativeElement.hidden = true;
					this.frontCaptureScreen.nativeElement.hidden = true;
					this.captureScreen.nativeElement.hidden = true;
					// this.captureScreenTitle.nativeElement.hidden = true;
					this.previewScreen.nativeElement.hidden = true;
					// this.previewScreenTitle.nativeElement.hidden = true;
					this.editScreen.nativeElement.hidden = false;
					// this.editScreenTitle.nativeElement.hidden = false;
					this.cropScreen.nativeElement.hidden = true;
					// this.cropScreenTitle.nativeElement.hidden = true;
					this.thumbnailScreen.nativeElement.hidden = true;
					// this.thumbnailScreenTitle.nativeElement.hidden = true;
					this.thumbnailPreviewScreen.nativeElement.hidden = true;
					this.flashButtonOff.nativeElement.hidden = true;
					this.flashButtonOn.nativeElement.hidden = true;
					this.backButtonAllowed = true;

					observer.next();
					observer.complete();
				break;

				case 'cropScreen':
					this.showWorkflowList = false;
					this.showFrontCaptureScreen = false;
					this.showCaptureScreen = false;

					this.appHeader.nativeElement.hidden = true;
					this.workflowList.nativeElement.hidden = true;
					this.frontCaptureScreen.nativeElement.hidden = true;
					this.captureScreen.nativeElement.hidden = true;
					// this.captureScreenTitle.nativeElement.hidden = true;
					this.previewScreen.nativeElement.hidden = true;
					// this.previewScreenTitle.nativeElement.hidden = true;
					this.editScreen.nativeElement.hidden = true;
					// this.editScreenTitle.nativeElement.hidden = true;
					this.cropScreen.nativeElement.hidden = false;
					// this.cropScreenTitle.nativeElement.hidden = false;
					this.thumbnailScreen.nativeElement.hidden = true;
					// this.thumbnailScreenTitle.nativeElement.hidden = true;
					this.thumbnailPreviewScreen.nativeElement.hidden = true;
					this.flashButtonOff.nativeElement.hidden = true;
					this.flashButtonOn.nativeElement.hidden = true;
					this.backButtonAllowed = true;

					observer.next();
					observer.complete();
				break;

				case 'thumbnailScreen':
					this.showWorkflowList = false;
					this.showFrontCaptureScreen = false;
					this.showCaptureScreen = false;

					this.appHeader.nativeElement.hidden = true;
					this.workflowList.nativeElement.hidden = true;
					this.frontCaptureScreen.nativeElement.hidden = true;
					this.captureScreen.nativeElement.hidden = true;
					// this.captureScreenTitle.nativeElement.hidden = true;
					this.previewScreen.nativeElement.hidden = true;
					// this.previewScreenTitle.nativeElement.hidden = true;
					this.editScreen.nativeElement.hidden = true;
					// this.editScreenTitle.nativeElement.hidden = true;
					this.cropScreen.nativeElement.hidden = true;
					// this.cropScreenTitle.nativeElement.hidden = true;
					this.thumbnailScreen.nativeElement.hidden = false;
					// this.thumbnailScreenTitle.nativeElement.hidden = false;
					this.thumbnailPreviewScreen.nativeElement.hidden = true;
					this.flashButtonOff.nativeElement.hidden = true;
					this.flashButtonOn.nativeElement.hidden = true;

					const ImageArray = window['kfxCordova'].kfxEngine.createImageArray();
					ImageArray.getTotalImages((result) => {
						console.log('result', result);
						// if (result > 0) {
						// 	ImageArray.removeAllImages(function(result) {
						// 		console.log("Delete all images success callback " + JSON.stringify(result));
						// 	}, function(deleteImageError) {
						// 		alert("Delete all  images error callback " + JSON.stringify(deleteImageError));
						// 		console.log("Delete all images error callback " + JSON.stringify(deleteImageError));
						// 	});
						// }
					},
					(getImageCountError) => {
						alert("Get images count error" + JSON.stringify(getImageCountError));
						console.log("Get images count error " + JSON.stringify(getImageCountError));
					});

					this.backButtonAllowed = true;

					observer.next();
					observer.complete();
				break;

				case 'thumbnailPreviewScreen':
					this.showWorkflowList = false;
					this.showFrontCaptureScreen = false;
					this.showCaptureScreen = false;

					this.appHeader.nativeElement.hidden = true;
					this.workflowList.nativeElement.hidden = true;
					this.frontCaptureScreen.nativeElement.hidden = true;
					this.captureScreen.nativeElement.hidden = true;
					// this.captureScreenTitle.nativeElement.hidden = true;
					this.previewScreen.nativeElement.hidden = true;
					// this.previewScreenTitle.nativeElement.hidden = true;
					this.editScreen.nativeElement.hidden = true;
					// this.editScreenTitle.nativeElement.hidden = true;
					this.cropScreen.nativeElement.hidden = true;
					// this.cropScreenTitle.nativeElement.hidden = true;
					this.thumbnailScreen.nativeElement.hidden = true;
					// this.thumbnailScreenTitle.nativeElement.hidden = true;
					this.thumbnailPreviewScreen.nativeElement.hidden = false;
					this.flashButtonOff.nativeElement.hidden = true;
					this.flashButtonOn.nativeElement.hidden = true;
					this.backButtonAllowed = true;

					observer.next();
					observer.complete();
				break;

				default:
				break;
			}

			const ImageArray = window['kfxCordova'].kfxEngine.createImageArray();
				ImageArray.getTotalImages((result) => {
					console.log('result', result);
					// if (result > 0) {
					// 	ImageArray.removeAllImages(function(result) {
					// 		console.log("Delete all images success callback " + JSON.stringify(result));
					// 	}, function(deleteImageError) {
					// 		alert("Delete all  images error callback " + JSON.stringify(deleteImageError));
					// 		console.log("Delete all images error callback " + JSON.stringify(deleteImageError));
					// 	});
					// }
				},
				(getImageCountError) => {
					alert("Get images count error" + JSON.stringify(getImageCountError));
					console.log("Get images count error " + JSON.stringify(getImageCountError));
				});
		});
	}

	private toggleCaptureButtons(hideButtons: boolean) { // control when to show buttons in the capture workflow, to prevent user interacting with the UI before SDK is ready
		this.captureButtons.forEach(button => {
			button.nativeElement.hidden = hideButtons;
		});
	}

	//Here we are initializing the all events in front capture screen
	private initializeFrontCaptureScreenEvents() {
		this.logger.info('initializeFrontCaptureScreenEvents()');
		this.changeDetector.detectChanges();
		setTimeout(() => {
			this.translateMessagesForSDK().subscribe(() => {
				this.setDefaultCaptureExperienceOptions();
			});
		}, 500);
    };

	private translateMessagesForSDK(): Observable<any> {
		this.logger.info('translateMessagesForSDK()');
		return new Observable((observer) => {
			const messageCount = captionStringsForSDKMessages.length;
			captionStringsForSDKMessages.forEach((messageString, index) => {
				this.translateService.get(messageString).subscribe( async (translatedMessage: string) => {
					this.logger.info('translateMessagesForSDK() - translatedMessage: ' + translatedMessage);
					const dataToAssign = {[captionStringsForSDKMessages[index]]: translatedMessage};
					Object.assign(this.translatedSDKMessages, dataToAssign);
					if (messageCount - 1 === index) {
						observer.next();
						observer.complete();
					};
				});
			});
		});
	}

	public imgCapturedSuccessCallback(imageData) {
		this.logger.info('imgCapturedSuccessCallback()');
		this.deleteImage(this.capturedImage).subscribe(() => {
			// console.log('response', response);
			console.log('imageData', imageData);
			this.capturedImage = imageData;
			this.toggleCaptureButtons(true);
			this.removeImageCaptureView().subscribe(() => {
				this.initializePreviewScreenEvents('previewScreen', true);
			});
		});
	}

	public imgCapturedErrorCallback() {
		this.logger.info('imgCapturedErrorCallback() - Go to frontCaptureScreen');
		this.removeImageReview().subscribe(() => {
			this.removeImageCaptureView().subscribe(() => {
				// this.cancelCapture();
				this.setViewElements('frontCaptureScreen').subscribe(() => {});
			});
		});
	}

	public setLicense(): Observable<any> {
		this.logger.info('setLicense()');
		console.log('this.license', this.license);
		return new Observable((observer) => {
			var isValidlicense = true;
			this.license = window['kfxCordova'].kfxUtilities.createLicense();
			console.log('license', this.license);
			this.license.setMobileSDKLicense(
				function (setLicenseSuccess) {
					isValidlicense = true;
					console.log("Set license success callback " + JSON.stringify(setLicenseSuccess));
					// this.getSDKVersionInfo();
					var Logging = window['kfxCordova'].kfxUtilities.createLogging();

					Logging.enableMobileSDKLogging(function(enableLoggingSuccess) {
						console.log('JSON.stringify(enableLoggingSuccess)', JSON.stringify(enableLoggingSuccess));
					    // alert(JSON.stringify(enableLoggingSuccess));
						observer.next();
						observer.complete();
					},
					function(enableLoggingError){
						alert(JSON.stringify(enableLoggingError));
					});
				},
				function (setLicenseError) {
					isValidlicense = false;

					alert(JSON.stringify(setLicenseError));

					console.log("Set license error callback " + JSON.stringify(setLicenseError));
				},
				"ttz,L@xtN#VD$B,#vF8vt@&4BXX$ljRnPf[089@p5n[6qb04[(NPIUEAWGUNKl;dfvkhzdf7rglcvjck=,mIOF&^BUL?!!!!!!0t"
			);


		});
	}

	// #############################################################################################################################################################################
	// #############################################################################################################################################################################
	// ### 			1 - setDefaultCaptureCameraOptions()            #########################################################################################################################################
	// #############################################################################################################################################################################
	// #############################################################################################################################################################################

	private setDefaultCaptureCameraOptions() {
		if (!this.captureCameraOptions) {
			this.captureCameraOptions = { captureControlType: "DocumentCaptureExperience", manualCaptureTimer: 10, isUseGallery: true };
		}
		// console.log('this.captureCameraOptions', this.captureCameraOptions);
	}

	// #############################################################################################################################################################################
	// #############################################################################################################################################################################
	// ### 		3 - setDefaultCaptureExperienceOptions()            #########################################################################################################################################
	// #############################################################################################################################################################################
	// #############################################################################################################################################################################

	private setDefaultCaptureExperienceOptions() {
		this.logger.info('setDefaultCaptureExperienceOptions()');
		if (!this.documentCaptureExperienceOptions) {
			this.documentCaptureExperience = window['kfxCordova'].kfxUicontrols.createDocumentCaptureExperience();
			this.documentCaptureExperienceOptions = this.documentCaptureExperience.getDocumentCaptureOptions();
		}
		this.documentCaptureExperienceOptions.CapturedMessage.message = this.translatedSDKMessages['CapturedMessage'];
		this.documentCaptureExperienceOptions.CenterMessage.message = this.translatedSDKMessages['CenterMessage'];
		this.documentCaptureExperienceOptions.HoldParallelMessage.message = this.translatedSDKMessages['HoldParallelMessage'];
		this.documentCaptureExperienceOptions.HoldSteadyMessage.message = this.translatedSDKMessages['HoldSteadyMessage'];
		this.documentCaptureExperienceOptions.RotateMessage.message = this.translatedSDKMessages['RotateMessage'];
		this.documentCaptureExperienceOptions.TiltForwardMessage.message = this.translatedSDKMessages['TiltForwardMessage'];
		this.documentCaptureExperienceOptions.TiltUpMessage.message = this.translatedSDKMessages['TiltUpMessage'];
		this.documentCaptureExperienceOptions.TutorialDismissMessage.message = this.translatedSDKMessages['TutorialDismissMessage'];
		this.documentCaptureExperienceOptions.UserInstructionMessage.message = this.translatedSDKMessages['UserInstructionMessage'];
		this.documentCaptureExperienceOptions.ZoomInMessage.message = this.translatedSDKMessages['ZoomInMessage'];
		this.documentCaptureExperienceOptions.ZoomOutMessage.message = this.translatedSDKMessages['ZoomOutMessage'];
		this.documentCaptureExperienceOptions.LookAndFeel.enableAnimationTutor = true;
		this.documentCaptureExperienceOptions.CaptureCriteria.DocumentDetectionSettings.DetectionSettings.minFillFraction = 0.65;
		this.documentCaptureExperienceOptions.CaptureCriteria.DocumentDetectionSettings.DetectionSettings.maxFillFraction = 1.1;
		this.documentCaptureExperienceOptions.CaptureCriteria.glareDetectionEnabled = false;
		this.documentCaptureExperienceOptions.CaptureCriteria.launchGlareRemoverExperience = false;
		this.logger.info('setDefaultCaptureExperienceOptions() - options: ' + JSON.stringify(this.documentCaptureExperienceOptions));
		// console.log('this.documentCaptureExperienceOptions', this.documentCaptureExperienceOptions );
	};

	 // Sets the titles for front capture screen depending on the module selected .
	 private changeTitlesForFrontCaptureScreen() {
        if (this.processedImage || this.capturedImage) {
			this.showTabImageText = true;
			//     $('.capture_1_h61').show(); // show "Tap on image to preview" H6 text
			//     $("#frontscreen_extract").attr("disabled", "");
			//     $("#frontscreen_extract").children().attr("disabled", "");
			//     $("#frontscreen_extract").attr("style", "background-color:rgb(255,255,255);");
			//     $("#fs_extract").attr("style", "color:rgb(0,0,0);");
        } else {
			this.showTabImageText = false;
        //     $('.capture_1_h61').hide(); // hide the "Tap on image to preview" text
        //     $("#frontscreen_extract").attr("disabled", "disabled"); // if there is not a processed or captured image, disable extract functionality
        //     $("#frontscreen_extract").children().attr("disabled", "disabled");
        //     $("#frontscreen_extract").attr("style", "background-color:rgba(200,200,200,0.1);"); // and change the color
        //     $("#fs_extract").attr("style", "color:rgb(183,184,185);");
        }
    };

	// #############################################################################################################################################################################
	// #############################################################################################################################################################################
	// ### 		GET_WORKFLOWS            #########################################################################################################################################
	// #############################################################################################################################################################################
	// #############################################################################################################################################################################

	public getWorkflows() {
		this.logger.info('getWorkflows()');
		this.dialogService.showLoadingSpinnerDialog('getWorkflows()').subscribe(() => {
			this.dataIsLoaded = false;
			this.captureService.getWorkflows(this.user.links.workflows)
			.subscribe((currentWorkflows) => {
				console.log('currentWorkflows', currentWorkflows);
				this.dataIsLoaded = true;
				this.workflows = currentWorkflows;
				this.changeDetector.detectChanges();
				this.dialogService.hideLoadingSpinnerDialog('getWorkflows()');
			});
		});
	}

	public checkDestinationType(workflow) {
		workflow['destinations'][0]
		let destinationType = null;
		if (workflow) {
      destinationType = workflow['destinations'][0];
			switch (destinationType) {
				case 'EMAIL':
					destinationType = 'Email'
				break;
				case 'ONEDRIVE':
					destinationType = 'MicrosoftOneDrive'
				break;
				case 'SHAREPOINT':
					destinationType = 'MicrosoftSharePoint'
				break;
				default:
					break;
			}
		}
		return destinationType;
	}

	public checkPermissionToShowActiveWorkflow() {
		let hasPermissionToShowActiveParameter = false;
		const userPermissions = this.user.embedded.permissions.permissions;
		if (userPermissions.includes('INACTIVE_WORKFLOW_USE_ALLOWED')) {
			hasPermissionToShowActiveParameter = true;
		}
		return hasPermissionToShowActiveParameter;
	}

	public selectWorkflow(workflow) {
		this.logger.info('selectWorkflow()');
		this.selectedWorkflow = workflow;
		// this.dialogService.showLoadingSpinnerDialog('selectWorkflow()');
		this.broadcaster.broadcast('DISABLE_RESUME');
		this.setViewElements('captureScreen').subscribe(() => {
			// this.dialogService.hideLoadingSpinnerDialog('selectWorkflow()');
			this.startCapture();
		});
	}

	// #############################################################################################################################################################################
	// #############################################################################################################################################################################
	// ### 			START_CAPTURE CONFIGURATIONS            #########################################################################################################################################
	// #############################################################################################################################################################################
	// #############################################################################################################################################################################

	//setting module specific capture options on ImageCaptureControl.
	public setCaptureControlOptions() {
		this.logger.info('setCaptureControlOptions()');
		console.log('setCaptureControlOptions()');
		console.log('##  TWO - setCaptureControlOptions()');
		this.imageCaptureControl.setOptions(
			(success) => {
				console.log("Capture control setOptions success callback " + JSON.stringify(success));
			},
			(error) => {
				alert("Capture control setOptions error:: " + JSON.stringify(error));
				console.log("Capture control setOptions error:: " + JSON.stringify(error));
			},
			this.imageCaptureControlOptions
		);
	}

	//This method renders the camera layout.
	public renderCameraLayout() {
		this.logger.info('renderCameraLayout()');
		console.log('## renderCameraLayout()');
		//Get the layout properties of cordova.

		this.layoutProperties = window['kfxCordova'].getLayoutProperties();

		let footerHeight = 79;
		let headerHeight = 58;
		let platform = null;

		if (this.platform.is('ios')) {
			this.logger.info('renderCameraLayout() - platform.is("ios")');
			platform = 'ios';
		} else if (this.platform.is('android')) {
			this.logger.info('renderCameraLayout() - platform.is("android")');
			platform = 'android';
		}

		//Calculate layout of camera
		//Initialise x position to 0
		let XPOS = 0,
			YPOS = headerHeight,
			YPOS_iOS = 20;

		//This variable holds height of the content(i.e. camera control)to be increased to fit in the available area in Android
		let pixelOffset_Android = 5;

		//This variable holds height of the content(i.e. camera control)to be increased to fit in the available area in iOS
		let pixelOffset_iOS = 1;

		this.layoutProperties.x = XPOS;

		//Get the height of the header which is 'captureheader'
		this.layoutProperties.y = YPOS;

		//Check if the platform is iOS.Considering height of status bar(20),calculate the ypos of the camera
		//layoutProperties.y = 20+document.getElementById('captureheader').offsetHeight;
		//ypos of camera=statusbarHeight+Height of the header('captureheader')
		//        if (parseInt(device.version) >= 11 && this.platform.is('ios)) {
		//            pixelOffset_iOS = 21;
		//        }
		//Width of the camera is the width of the window
		this.layoutProperties.width = window.innerWidth;

		//Calculate the total height left for the camera
		//Android:Height of the camera=TotalHeight-HeaderHeight+pixelOffset_Android
		this.layoutProperties.height = window.innerHeight - (footerHeight + headerHeight);

		this.layoutProperties.visibility = true;
		//For iOS the height of the camera is 20px less as status bar height should be considered
		//iOS:Height of the camera=TotalHeight-HeaderHeight+pixelOffset_iOS
		// if (CaptureUtils.hasNotch() && this.platform.is('ios')) {
		// 	this.layoutProperties.height = window.innerHeight - this.footerHeight + pixelOffset_iOS - YPOS_iOS;
		// } else if (parseInt(this.device.version) >= 7 && this.platform.is('ios')) {
		// 	this.layoutProperties.height = window.innerHeight - this.footerHeight + pixelOffset_iOS;
		// }
	}

	public initializeCameraScreenevents(cameraOptions) {
		console.log('## initializeCameraScreenEvents()');
		this.isflashOn = false;
		// this.manualTimer = setTimeout(() => {
		// 	this.showCaptureButton();
		// }, cameraOptions.manualCaptureTimer * 1000);

		this.flashlight.available().then((isAvailable) => {
			console.log('isAvailable', isAvailable);
			if (isAvailable) {
				this.isFlashSupport = true;
			} else {
				this.isFlashSupport = false;
			}
		});

		// window.plugins.flashlight.available(function (isAvailable) {
		// 	if (isAvailable) {
		// 		this.isFlashSupport = true;
		// 	} else {
		// 		this.isFlashSupport = false;
		// 		document.getElementById("flashButton").disabled = true;
		// 		document.getElementById("flashButton").style.opacity = "0.2";
		// 	}
		// });
	};

	// #############################################################################################################################################################################
	// #############################################################################################################################################################################
	// ### 						START_CAPTURE 							############################################################################################################
	// #############################################################################################################################################################################
	// #############################################################################################################################################################################

	public startCapture() {
		this.logger.info('startCapture()');
		this.backButtonAllowed = false;

		// if (CaptureUtils.hasNotch() && this.platform.is('ios)) {
		// 	document.getElementById("capturefooter").style.marginBottom = "20px";
		// }
		// const footerHeight = document.getElementById("capturefooter").offsetHeight;
		// document.getElementById("capturefooter").style.display = "block";

		this.initializeCameraScreenevents(this.captureCameraOptions);
		this.pictureTaken = false;
		this.forcePictureTaken = false;
		this.galleryButtonClicked = false;
		this.retakeActionClicked = false;
		this.editImageClicked = false;
		this.galleryImageSelected = false;

		/* Create an instance of ImageCaptureControl by calling "createImageCaptureControl".*/
		//ImageCaptureControl renders the camera preview to the screen and returns an image
		console.log('##  ONE - startCapture()');
		this.imageCaptureControl = window['kfxCordova'].kfxUicontrols.createImageCaptureControl();

		/* Create an instance of DocumentCaptureExperience/checkCaptureExperience by calling
		"createDocumentCaptureExperience/createCheckCaptureExperience".DocumentCaptureExperience/checkCaptureExperience
		renders a visual experience on top of the capture control and returns an image depending on the criteria set by the user*/
		this.documentCaptureExperience = window['kfxCordova'].kfxUicontrols.createDocumentCaptureExperience();

		this.imageCaptureControlOptions = this.imageCaptureControl.getImageCaptureViewOptions();
		this.imageCaptureControlOptions.CaptureOptions.videoFrame = this.useVideoFrame;

		//Method to set ImageCaptureView properties.
		this.setCaptureControlOptions();

		//This method calculates the layout of the camera
		this.renderCameraLayout();

		if (this.platform.is('android')) {
			// check for camera permissions
			this.checkCameraPermission().subscribe(() => {
				console.log('## GETTING_BACK_FROM_PERMISSION_COUNTRY');
				//Add camera if license is set successfully
				this.addCamerainitializedListeners();
			});
		} else {
			this.addCamerainitializedListeners();
		}

		//Adding onResume listener
		// this.platformResumeEventSubscription = this.platform.resume.subscribe(async () => {
		// 	this.onResume();
		// });
	};

	// Method to reset application state after resume
	private onResume() {
		// this.isResumed = true;
	}

	// #############################################################################################################################################################################
	// #############################################################################################################################################################################
	// ### 			ADD_CAPTURE_LISTENERS            #########################################################################################################################################
	// #############################################################################################################################################################################
	// #############################################################################################################################################################################


	//  Add KofaxSDK camera listeners
	public addCamerainitializedListeners() {
		//Calling this method would add a listener which would be called upon successful initialization.
		this.addCameraInitializationListener();

		//Calling this method would add a listener which would be called when camera initialization fails.
		this.addCameraInitializationFailedListener();
	}

	//Calling this method would add a listener which would be called upon successful initialization.

	public addCameraInitializationListener() {
		this.logger.info('addCameraInitializationListener()');
		console.log('##  THREE - addCameraInitializationListener()');

		this.imageCaptureControl.addCameraInitializationListener(
			(cameraInitializeSuccess) => {
				console.log('##  FIVE - imageCaptureControl.addCameraInitializationListener()');
				console.log("camera initialize success callback", cameraInitializeSuccess);
				//On successful initialization add the camera with layout.
				//No need to call addCameraView with setTimeout. In order handle jquery page refresh issues on lowend devices like Asus, Samsung S3 Version 4.4.2, we are setting timeout.
				this.addCameraView();
				// if (this.platform.is('ios')) {
				// } else {
				// 	setTimeout(() => {
				// 		this.addCameraView();
				// 	}, 300);
				// }
			},
			(cameraInitializeError) => {
				alert("camera initialize error callback " + JSON.stringify(cameraInitializeError));
				console.log("camera initialize error callback", cameraInitializeError);
				this.logger.info('addCameraInitializationListener() - cameraInitializeError: ' + cameraInitializeError);
				this.imgCapturedErrorCallback();
			},
			this.cameraInitializeSuccessCallback.bind(this)
		);
	}

	//This event fires when the camera is initialized or reinitialized
	//On successful initialization of camera associate the respective capture control with specified options i.e.DocumentCaptureExperience in this case
	public cameraInitializeSuccessCallback(result) {
		this.logger.info('cameraInitializeSuccessCallback()');
		console.log("camera initialize event raised success callback", result);
		console.log('## SEVEN - cameraInitializeSuccessCallback()');

		//To get the unique Id for imageCaptureControl. This id is needed to differentiate different capture controls if present
		this.imageCaptureControl.getId(
			(captureControlId) => {
				console.log('## TEN - imageCaptureControl.getId()');
				console.log("Capture control get Id success callback " + JSON.stringify(captureControlId));
				this.imageCaptureControlId = captureControlId;

				//Method to associate the created ImageCapture Control with the DocumentCaptureExperience class and setting custom options
				this.documentCaptureExperience.bindCaptureControlWithOptions(
					(bindSuccess) => {
						this.logger.info('cameraInitializeSuccessCallback() - bindCaptureControlWithOptions() - bindSuccess');
						console.log('## ELLEVEN - documentCaptureExperience.bindCaptureControlWithOptions()');
						console.log("Bind capture control success callback", bindSuccess);

						//Calling this method would add a listener which would be called when image is captured.
						this.addImageCapturedEventListener(); // for takePicture()
						this.addImageCapturedListener(); // for forceTakePicture()
					},(bindError) => {
						this.logger.info('cameraInitializeSuccessCallback() - bindError: ' + JSON.stringify(bindError));
						console.log("Bind capture control error ::", bindError);
						alert("bindCaptureControlWithOptions error ::" + JSON.stringify(bindError));
						this.imgCapturedErrorCallback();
					},
					{
						ImageCaptureControlID: captureControlId,
						CaptureExperienceOptions: this.documentCaptureExperienceOptions,
					}
				);
			},
			(getIdError) => {
				this.logger.info('cameraInitializeSuccessCallback() - GetIDError: ' + JSON.stringify(getIdError));
				console.log("Capture control get Id error:: ", getIdError);
				alert("Capture control get Id error:: " + JSON.stringify(getIdError));
				// this.imgCapturedErrorCallback();
				this.removeImageReview().subscribe(() => {
					this.cancelCapture();
				});
			}
		);
	};

	public addImageCapturedEventListener() {
		this.logger.info('addImageCapturedEventListener()');
		console.log('##  TWELVE - addImageCapturedEventListener()');
		this.documentCaptureExperience.addImageCapturedEventListener(
			(imageCapturedEventSuccess) => {
				console.log('##  THIRTEEN - documentCaptureExperience.addImageCapturedEventListener()');
				console.log("add image captured event listener success callback", imageCapturedEventSuccess);
				this.setCaptureExperienceOptions();
			},
			(imageCapturedEventError) => {
				this.logger.info('addImageCapturedEventListener() - imageCapturedEventError: '  + JSON.stringify(imageCapturedEventError));
				console.log("add image captured event listener error callback", imageCapturedEventError);
				alert("add image captured event listener error callback " + JSON.stringify(imageCapturedEventError));
				// this.imgCapturedErrorCallback();
				this.removeImageReview().subscribe(() => {
					this.cancelCapture();
				});
			},
			this.imageCapturedEventSuccessCallback.bind(this)
		);
	}

	/*
	 *Calling this method would add a listener which would be called when image is captured by forceTakePicture()
	 */
	 public addImageCapturedListener() {
		this.logger.info('addImageCapturedListener()');

		this.documentCaptureExperience.addImageCapturedListener(
			(imageCapturedSuccess) => {
				console.log("add image captured listener success callback " + JSON.stringify(imageCapturedSuccess));
			},
			(imageCapturedError) => {
				this.logger.info('addImageCapturedListener() - imageCapturedError: ' + JSON.stringify(imageCapturedError));
				console.log("add image captured listener error callback " + imageCapturedError);
				alert("add image captured listener error callback " + JSON.stringify(imageCapturedError));
				// this.imgCapturedErrorCallback();
				this.removeImageReview().subscribe(() => {
					this.cancelCapture();
				});
			},
			this.imageCapturedEventSuccessCallback.bind(this)
		);
	}

	/*On successful capture of image,preview of captured image is shown and camera is cleaned up from the memory .So any listeners associated with the camera are removed viz.CameraInitializationListener,CameraInitializationFailedListener
	 */
	public imageCapturedEventSuccessCallback(resultObject) {
		this.logger.info('imageCapturedEventSuccessCallback()');

		if (this.pictureTaken) {
			return;
		}
		// this.stopTimer();
		this.pictureTaken = true;

		console.log("image is getting in image captured event callback", resultObject);
		if (resultObject.glareFreeImage) {
			console.log('## successCB GKAREFREE');
			this.imgCapturedSuccessCallback(resultObject.glareFreeImage); // glarefree image captured success
		} else if (resultObject.errorInfo) {
			this.logger.info('imageCapturedEventSuccessCallback() - errorInfo: ' + JSON.stringify(resultObject.errorInfo));
			alert(JSON.stringify(resultObject.errorInfo)); // image captured error
			// this.imgCapturedErrorCallback();
			this.removeImageReview().subscribe(() => {
				this.cancelCapture();
			});
		} else {
			this.imgCapturedSuccessCallback(resultObject.primaryImage); // image captured success
		}
	};


	//This method sets options to CaptureControl and DocumentCaptureExperience
	public setCaptureExperienceOptions() {
		this.logger.info('setCaptureExperienceOptions()');
		console.log('## setCaptureExperienceOptions()');
		console.log('this.documentCaptureExperienceOptions', this.documentCaptureExperienceOptions);

		if (this.platform.is('ios')) {
			this.takePicture();
		} else {
			this.documentCaptureExperience.setOptions(
				(setOptionsSuccess) => {
					console.log("Document capture control set options success callback " + JSON.stringify(setOptionsSuccess));
					this.takePicture();
				},
				(setOptionsError) => {
					this.logger.info('setCaptureExperienceOptions() - setOptionsError: ' + JSON.stringify(setOptionsError));
					console.log("Document capture control set options error callback", setOptionsError);
					alert("Document capture control set options error callback: " + JSON.stringify(setOptionsError));
					// Cancel capture and go back to frontCaptureScreen.
					// this.imgCapturedErrorCallback();
					this.removeImageReview().subscribe(() => {
						this.cancelCapture();
					});
				},
				this.documentCaptureExperienceOptions
			);
		}

		// Ignore logic in if block for real time applications. Below additional setOptions helps in auto dismiss of Animation Tutor, when app relaunched from background.
		if (this.isResumed) {
			console.log('## IS_RESUMED_DO_NOTHING');
			// this.animEndTime = new Date();
			// var animTime = Date.parse(this.animEndTime) - Date.parse(this.animStartTime);
			// var animDur = Math.floor((animTime / 1000) % 60);
			// var tempOptions = JSON.parse(JSON.stringify(this.documentCaptureExperienceOptions));
			// if (this.documentCaptureExperienceOptions.LookAndFeel && this.documentCaptureExperienceOptions.LookAndFeel.enableAnimationTutor) {
			// 	tempOptions.LookAndFeel.enableAnimationTutor = false;
			// }
			// if (animDur > 5) {
			// 	this.documentCaptureExperience.setOptions(
			// 		(setOptionsSuccess) => {
			// 			console.log(
			// 				"Document capture control set options after resume success callback " +
			// 					JSON.stringify(setOptionsSuccess)
			// 			);
			// 		},
			// 		(setOptionsError) => {
			// 			console.log(
			// 				"Document capture control set options  after resume error callback " +
			// 					JSON.stringify(setOptionsError)
			// 			);
			// 		},
			// 		tempOptions
			// 	);
			// 	this.isResumed = false;
			// 	this.takePicture();
			// 	return;
			// } else {
			// 	setTimeout(() => {
			// 		this.documentCaptureExperience.setOptions((setOptionsSuccess) => {
			// 				this.takePicture();
			// 				console.log(
			// 					"Document capture control set options after resume success callback " +
			// 						JSON.stringify(setOptionsSuccess)
			// 				);
			// 			},(setOptionsError) => {
			// 				console.log(
			// 					"Document capture control set options  after resume error callback " +
			// 						JSON.stringify(setOptionsError)
			// 				);
			// 			},
			// 			tempOptions
			// 		);
			// 		this.isResumed = false;
			// 	}, 2000);
			// }
		} else {
		}
	}

	// #############################################################################################################################################################################
	// #############################################################################################################################################################################
	// ### 			CAMERA_INIT_FAILED_LISTENERS            #########################################################################################################################################
	// #############################################################################################################################################################################
	// #############################################################################################################################################################################

	//Calling this method would add a listener which would be called when camera initialization fails.

	public addCameraInitializationFailedListener() {
		this.logger.info('addCameraInitializationFailedListener()');
		console.log('##  FOUR - addCameraInitializationFailedListener()');

		this.imageCaptureControl.addCameraInitializationFailedListener(
			(cameraInitializationFailedListener) => {
				this.logger.info('addCameraInitializationFailedListener(): ' +  JSON.stringify(cameraInitializationFailedListener));
				console.log('##  EIGHT - imageCaptureControl.addCameraInitializationFailedListener(): ' + cameraInitializationFailedListener);
			},
			(cameraInitializeFailedError) => {
				this.logger.info('addCameraInitializationFailedListener() - Error: ' +  JSON.stringify(cameraInitializeFailedError));
				console.log("camera initialize error callback", cameraInitializeFailedError);
				alert("camera initialize error callback " + JSON.stringify(cameraInitializeFailedError));
				this.imgCapturedErrorCallback();
			},
			this.cameraInitializationFailedCallback.bind(this)
		);
	}

	//This event fires when the camera is initialization/reinitialization fails
	public cameraInitializationFailedCallback(error) {
		this.logger.info('cameraInitializationFailedCallback() - Error: ' + JSON.stringify(error));
		console.log('## cameraInitializationFailedCallback()');
		alert("Unable to open camera - " + error.cameraInitializationFailedReason);
		this.imgCapturedErrorCallback();
	}

	// #############################################################################################################################################################################
	// #############################################################################################################################################################################
	// ### 			ADD/REMOVE CAMERA_VIEW           #########################################################################################################################################
	// #############################################################################################################################################################################
	// #############################################################################################################################################################################

	/*
	 * Add cameraView with parameter "layoutProperties" obtained from renderCameraLayout()
	 */
	public addCameraView() {
		this.logger.info('addCameraView()');
		this.logger.info('addCameraView() LayoutProperties: ' + JSON.stringify(this.layoutProperties));
		console.log('##  SIX - addCameraView()');

		this.imageCaptureControl.addCameraView(
			(addCameraSuccess) => {
				console.log('##  NINE - imageCaptureControl.addCameraView() - cameraViewAdded');
				console.log("add camera view success callback", addCameraSuccess);
			},
			(addCameraError) => {
				this.logger.info('addCameraView() - addCameraError: ' + JSON.stringify(addCameraError));
				console.log("add camera view error callback", addCameraError);
				alert("add camera view error callback " + JSON.stringify(addCameraError));
				this.imgCapturedErrorCallback();
			},
			this.layoutProperties
		);
	}

	//After the image is captured preview is seen and camera is no longer needed.So this is removed and cleaned up from the memory
	public removeCameraView() {
		this.logger.info('removeCameraView()');

		if (this.imageCaptureControl === null) {
			this.imageCaptureControl = window['kfxCordova'].kfxUicontrols.createImageCaptureControl();
		}
		this.imageCaptureControl.removeCameraView(
			(removeCameraSuccess) => {
				console.log("remove camera view success " + JSON.stringify(removeCameraSuccess));
			},
			(removeCameraError) => {
				this.logger.info('removeCameraView() - removeCameraError: ' + JSON.stringify(removeCameraError));
				console.log("remove camera view error", removeCameraError);
				alert("remove camera view error " + JSON.stringify(removeCameraError));
				this.imgCapturedErrorCallback();
			}
		);
	}

	// #############################################################################################################################################################################
	// #############################################################################################################################################################################
	// ### 		TAKE_PICTURE / FORCE_TAKE_PICTURE          #########################################################################################################################################
	// #############################################################################################################################################################################
	// #############################################################################################################################################################################

	/*Calling this method will start the process of monitoring the various constraints passed to determine when a level, focused, and non-blurry shot of the document can be taken.The event listener  "addImageCapturedListener" will receive the  call back message and will have the corresponding capture image details
     takePicture can also be called from ImageCaptureControl.As the current experience is DocumentCaptureExperience takePicture is called on DocumentCaptureExperience.
     */
	 public takePicture() {
		this.logger.info('takePicture()');

		this.animStartTime = new Date();
		this.documentCaptureExperience.takePicture(
			(result) => {
				this.broadcaster.broadcast('ENABLE_RESUME');
				this.backButtonAllowed = true;
				console.log('takePicture result', result);
				this.toggleCaptureButtons(false);
			},
			(error) => {
				this.broadcaster.broadcast('ENABLE_RESUME');
				this.logger.info('takePicture() error: ' + error);
				this.backButtonAllowed = true;
				alert("takePicture error!" + error);
				this.imgCapturedErrorCallback();
			}
		);
	}
	/*
     ForceTakePicture : Calling this method will start the image capture process but constraints like levelness,stability, and page detection will be ignored but doesn't ignore focus constraint where as "takePicture" will consider these constraints and takes picture only if these constraints are satisfied

     The event listener  "addImageCapturedListener" will receive the delegate call back message and will have the corresponding capture image details*/
	public forceTakePicture() {
		this.logger.info('forceTakePicture()');

		if (!this.forcePictureTaken) {
			this.forcePictureTaken = true;
			this.galleryButtonClicked = true;
			this.imageCaptureControl.forceTakePicture(
				(result) => {
					console.log("forceTakePicture success");
				},(error) => {
					this.logger.info('forceTakePicture() error: ' + error);
					alert("force takePicture error!" + error);
					this.imgCapturedErrorCallback();
				}
			);
		}
	}

	// #############################################################################################################################################################################
	// #############################################################################################################################################################################
	// ### 			REMOVE_CAPTURE_LISTENERS            #########################################################################################################################################
	// #############################################################################################################################################################################
	// #############################################################################################################################################################################

	/*
	 * Remove the image capture view
	 *
	 */
	public removeImageCaptureView(): Observable<any> {
		this.logger.info('removeImageCaptureView()')
		console.log('## REMOVE_CAPTURE_VIEW');
		return new Observable((observer) => {
			//Method to remove CameraInitializationListener
			this.removeCameraInitializationListener()
			.pipe(first())
			.subscribe(() => {
				//Method to remove CameraInitializationFailedListener
				this.removeCameraInitializationFailedListener()
				.pipe(first())
				.subscribe(() => {
					observer.next();
					observer.complete();
				});
			});
		});

	};

	/*In this function we first removed cameraInitializationListener.On receiving successcallBack ImageCapturedListener is removed and then camera is cleaned up from the memory*/
	public removeCameraInitializationListener(): Observable<any> {
		this.logger.info('removeCameraInitializationListener()');
		console.log('removeCameraInitializationListener()');

		return new Observable((observer) => {
			this.imageCaptureControl.removeCameraInitializationListener(
				(cameraInitializeSuccess) => {
					console.log("remove camera initialize success callback " + JSON.stringify(cameraInitializeSuccess));
					//Remove imagecapturedListener as image is captured and camera is no longer needed
					this.removeImageCapturedEventListener()
					.subscribe(() => {
						observer.next();
						observer.complete();
					});
				},
				(cameraInitializeError) => {
					alert("camera initialize error callback " + JSON.stringify(cameraInitializeError));
					console.log("remove camera initialize error callback " + JSON.stringify(cameraInitializeError));
					observer.next();
					observer.complete();
				}
			);
		})
	}

	/*In this method ImageCapturedListener is removed and then camera is cleaned up from the memory by calling removeCameraView.
	 */
	public removeImageCapturedEventListener(): Observable<any> {
		this.logger.info('removeImageCapturedEventListener()');

		return new Observable((observer) => {
			this.documentCaptureExperience.removeImageCapturedEventListener(
				(removeListenerEventSuccess) => {
					console.log("remove image captured event listener success " + removeListenerEventSuccess);
					//After the image is captured preview is seen and camera is no longer needed.So this is removed and cleaned up from the memory
					this.removeCameraView();
					observer.next();
					observer.complete();
				},
				(removeListenerEventError) => {
					alert("remove iamge captured event listener error " + JSON.stringify(removeListenerEventError));
					console.log("remove iamge captured event listener error " + JSON.stringify(removeListenerEventError));
					observer.next();
					observer.complete();
				}
			);
		});
	}

	//Remove camera initialization failed listener.
	public removeCameraInitializationFailedListener(): Observable<any> {
		this.logger.info('removeCameraInitializationFailedListener()');

		return new Observable((observer) => {
			this.imageCaptureControl.removeCameraInitializationFailedListener(
				(removeCameraInitializationFailedListener) => {
					console.log("camera initialize failed callback " + JSON.stringify(removeCameraInitializationFailedListener));
					observer.next();
					observer.complete();
				},
				(removecameraInitializeFailedListenerError) => {
					alert("camera initialize error callback " + JSON.stringify(removecameraInitializeFailedListenerError));
					console.log("camera initialize error callback " + JSON.stringify(removecameraInitializeFailedListenerError));
					observer.next();
					observer.complete();
				}
			);
		})
	}

	/*
	 *
	 * Handle click on capture cancel button;
	 */
	// public onCaptureCancel() {
	// 	this.logger.info('onCaptureCancel()');

	// 	this.stopTimer();
	// 	if (this.imgCapturedErrorCallback) {
	// 		this.sendCallback(this.imgCapturedErrorCallback, null);
	// 	};
	// }

	// #############################################################################################################################################################################
	// #############################################################################################################################################################################
	// ### 		PROCESS_IMAGE         #########################################################################################################################################
	// #############################################################################################################################################################################
	// #############################################################################################################################################################################

	public processImage(imageProcessingSetting?: string) {
		this.logger.info('processImage()');
		this.backButtonAllowed = false;
		let options = this.setImageProcessingOptions(imageProcessingSetting);
		this.specifyImageFilePath().subscribe((response) => {

			console.log('### ÅPSJONS - options', options);
			this.translateService.get('Processing').subscribe( async (translatedMessage: string) => {
			    this.dialogService.showLoadingSpinnerWithMessage(translatedMessage, 'processImage()');

				this.doProcess(
					options,
					(imageObj) => {
						this.dialogService.hideLoadingSpinnerDialogProcessImage('processImage doProcess');
						this.toggleCaptureButtons(false);
						if (imageProcessingSetting === 'ippString') {
							this.processedImage = imageObj;
							this.setImageData(imageObj);
							this.editImageClicked = false;
							this.backButtonAllowed = true;
						} else {
							this.imageIsEdited = true;
							this.capturedImage = imageObj;
							this.initializePreviewScreenEvents('editScreen', true);
						}
					},
					(processError) => {
						this.dialogService.hideLoadingSpinnerDialogProcessImage();
						this.capturedImage = null;
						console.log('processError', processError.ErrorMsg);
						if (processError.ErrorMsg === 'Image Perfection Error: Externally defined page boundary tetragon corners are invalid.') {
							alert("Image cropping could not be done with the provided settings");
							this.removeImageReview().subscribe(() => {
								this.retakeAction(false);
							});
						} else {
							alert("Image could not be processed");
							this.removeImageReview().subscribe(() => {
								this.retakeAction(false);
							});
						}
					}
				);
			});
		});
    }

	private setImageProcessingOptions(imageProcessingSetting?: string) {
		this.logger.info('setImageProcessingOptions()');
		let options;

		if (imageProcessingSetting === 'ippString') {
			console.log('this.processingString', this.processingString);
			options = this.getDefaultProcessorOptions();
			options.ProcessedImage.representation = 'IMAGE_REP_BOTH';

			// console.log('this.galleryImageSelected', this.galleryImageSelected);
			// console.log('this.imageManuallyCropped', this.imageManuallyCropped);

			if (this.imageManuallyCropped) { // do not crop image if user has cropped image and if it is a gallery image, use gallery processing string
				// console.log('## manually cropped');
				this.galleryImageSelected ? options.ImageProcessorConfiguration.ippString = this.processingString_noCrop : options.ImageProcessorConfiguration.ippString = this.processingString_noCrop;
			} else {
				// console.log('## no user crop');
				this.galleryImageSelected ? options.ImageProcessorConfiguration.ippString = this.processingString : options.ImageProcessorConfiguration.ippString = this.processingString;
			}

			this.imageManuallyCropped = false;

		} else {
			options = this.initializeBasicSettingsProfile(imageProcessingSetting); // For cropping or rotating image, set a BasicSettingsProfile
		}

		return options;
	}

	public getDefaultProcessorOptions = function () {
		this.logger.info('getDefaultProcessorOptions()');

		if (!this.ImageProcessor) {
			this.ImageProcessor = window['kfxCordova'].kfxEngine.createImageProcessor();
		}
		return this.ImageProcessor.getImageProcessingOptions();
	};

	public specifyImageFilePath(): Observable<any> {
		this.logger.info('specifyImageFilePath()');

		return new Observable((observer) => {
			function successCallback(response) {
				console.log('specifyProcessedImageFilePath response', response);
				observer.next(response);
				observer.complete();
			}

			function errorCallback(error) {
				alert(JSON.stringify(error));
				observer.next(error);
				observer.complete();
			}

			let imgObj = (this.processedImage ? this.processedImage : this.capturedImage);

			this.ImageProcessor.specifyProcessedImageFilePath(successCallback, errorCallback, imgObj.imgID + '.jpg');
		});
	}

	public doProcess(options, successCallback, errorCallback) {
		this.logger.info('doProcess()');
		// this.captureImage = imageData;
		this.imageProcessorOptions = options;
		// console.log("IPP string::" + this.imageProcessorOptions.ImageProcessorConfiguration.ippString);
		this.addImageProcessorListeners();
		this.imageprocessSuccessCB = successCallback;
		this.imageprocessErrorCB = errorCallback;
	};

	/*
	 * Add all Image processing Listeners
	 *
	 */
	public addImageProcessorListeners() {
		this.logger.info('addImageProcessorListeners()');

		/** This method would receive the on image processed at the ImageProcessor.
         imageOutHandler: The processed image is returned in this callback.
         */
         this.ImageProcessor.addImageOutEventListener(
			(imageOutSuccess) => {
				//Set the properties of the image processor
				this.setProcessorOptions();
				console.log("Image out listener registered success callback " + JSON.stringify(imageOutSuccess));
			},
			(imageOutError) => {
				this.sendCallback(this.imageprocessErrorCB, imageOutError);
				console.log("Image out listener error callback " + JSON.stringify(imageOutError));
			},
			this.imageOutHandler.bind(this)
		);

		/** This method would receive the Image process progress at the ImageProcessor.
        	processProgressHandler:Callback with a variable value representing Image processing progress will be returned.
         */
      this.ImageProcessor.addProcessProgressListener(
  			(processProgressSuccess) => {
  				console.log(
  					"Process progress listener registered success callback " + JSON.stringify(processProgressSuccess)
  				);
  			},
  			(processProgressError) => {
  				alert("Process progress listener error callback " + JSON.stringify(processProgressError));
  				console.log("Process progress listener error callback " + JSON.stringify(processProgressError));
  			},
  			this.processProgressHandler.bind(this)
		);
	};

	//Set the properties of the image processor
	public setProcessorOptions() {
		this.logger.info('setProcessorOptions()');

		/** Set the Options/properties of the ImageProcessor.
		 * imageProcessorOptionsCB is a success callback.In this callback method will call the processImage.
		 * 'imageProcessorOptions'  variable containing the properties  to be set to the ImageProcessor.
		 */
		console.log('setProcessorOptions() this.imageProcessorOptions', this.imageProcessorOptions);
     this.ImageProcessor.setImageProcessorOptions(
			this.imageProcessorOptionsCB.bind(this),
			function (result) {
				this.sendCallback(this.imageprocessErrorCB, result);
			},
			this.imageProcessorOptions
		);
	}

	/*
	 * ImageProcessorOptions Success callback
	 *
	 */

	public imageProcessorOptionsCB(message) {
		this.logger.info('imageProcessorOptionsCB()');

		/***************************************************
		 *
		 *
		 *
		 * ImageProcess method
		 *
		 *
		 *
		 * *************************************************
		 */

		/**
		 * This method when you want to perform standard image processing on the image id supplied with the method. The ImageProcessor
		 *	processes the image using the processing options contained in the profile you specified. You can specify either a basic
		 *	settings profile or an image perfection profile.
		 *  After processing the image, imageOutHandler("imageOutCallback") will get the processed image.
		 */
		// console.log(' imageProcessorOptionsCB this.capturedImage', this.capturedImage);
		// console.log(' imageProcessorOptionsCB this.processedImage', this.processedImage);

		let imgObj = (this.processedImage ? this.processedImage : this.capturedImage);

		this.ImageProcessor.processImage(
			(result) => {
				console.log("Success! processImage" + result);
			},
			(result) => {
				 this.sendCallback( this.imageprocessErrorCB, result);
				alert("Error! processImage " + JSON.stringify(result));
			},
			this.capturedImage.imgID
		);
	};

	/*
	 * will be invoked, once image processing gets completed
	 *
	 * @gets processed image imageId
	 *
	 */
	public imageOutHandler(ImgObject) {
		this.logger.info('imageOutHandler()');

		console.log('imageOutHandler imgObject', ImgObject);
		this.removeImageProcessorListeners();

		 this.sendCallback(this.imageprocessSuccessCB, ImgObject);
	};

	/*
	 * logs image processing percentage
	 *
	 * @gets image processing percentage
	 *
	 */
	public processProgressHandler(message) {
		this.logger.info('processProgressHandler() :: ' + message.ProgressPercent);
		// this.dialogService.showLoadingSpinnerWithMessage('Processing image... ' + message.ProgressPercent + ' %', 'procecessProgressHandler()')
		console.log("calling processProgressHandler from app.js ProgressPercent :: " + message.ProgressPercent);
	};

		/*
	 * Removes All Image processing Listeners
	 *
	 */
	public removeImageProcessorListeners = function () {
		this.logger.info('removeImageProcessorListeners()');

		/** The method would remove the listener and would not receive the processed image events. After removing the listener,
		there will not be any call backs from native .
			*/
		this.ImageProcessor.removeImageOutEventListener(
			(imageOutSuccess) => {
				console.log("Remove image out listener success callback " + JSON.stringify(imageOutSuccess));
			},
			(imageOutError) => {
				alert("Remove image out listener error callback " + JSON.stringify(imageOutError));
				console.log("Remove image out listener error callback " + JSON.stringify(imageOutError));
			}
		);

		/** The method would remove the listener and would not receive the Processed image progress events. After removing the listener,
		there will not be any call backs from native */

		this.ImageProcessor.removeProcessProgressListener(
			(processProgressSuccess) => {
				console.log(
					"Remove process progress listener success callback " + JSON.stringify(processProgressSuccess)
				);
			},
			(processProgressError) => {
				alert("Remove process progress listener error callback " + JSON.stringify(processProgressError));
				console.log("Remove process progress listener error callback " + JSON.stringify(processProgressError));
			}
		);
	};

	// #############################################################################################################################################################################
	// #############################################################################################################################################################################
	// ### 		PREVIEW         #########################################################################################################################################
	// #############################################################################################################################################################################
	// #############################################################################################################################################################################

	 //Here we are initializing all events in preview screen
	 private initializePreviewScreenEvents = function(screenType: string, processImage: boolean) {
		this.logger.info('initializePreviewScreenEvents()');
		console.log('## initializePreviewScreenEvents() screenType: ', screenType, 'processImage: ', processImage);
        this.isProcessComplete = false;

		this.setViewElements(screenType).subscribe(() => {
			// console.log('showImageReviewControl - this.capturedImage', this.capturedImage);
			// console.log('showImageReviewControl - this.processedImage', this.processedImage);
			this.showImageReviewControl(processImage);
		});

        // if (!this.isForImagePreview) {
        //     if (this.uiProcessOptions.doQuickAnalysis && !this.isRetakeCancel) {
        //         this.captureProcessorService.doQuickAnalysis(this.capturedImage);
        //     }
        //     this.isRetakeCancel = false;
        // }
    };

	public showImageReviewControl(processImage: boolean) {
		this.logger.info('showImageReviewControl()');
		this.logger.info('showImageReviewControl() - LayoutProperties: ' + JSON.stringify(this.layoutProperties));

		let imageId = this.capturedImage.imgID;

		if (!processImage && !this.cancelClicked) { // if the image is not to be processed or the 'close'/'cancel' button has not been clicked in the capture header, the image is selected in the thumbnail screen to see a large image.
			imageId = this.selectedImageID
		}

		this.ImageReviewControl = window['kfxCordova'].kfxUicontrols.createImageReviewControl();
		this.ImageReviewControl.addImageReviewEditView(
			(data) => {
				this.logger.info('showImageReviewControl() - addImageReviewEditView succes data: ' + JSON.stringify(data));
				this.ImageReviewControl.setImage(
					(data) => {
						this.logger.info('showImageReviewControl() - setImage succes data: ' + JSON.stringify(data));
						console.log('processImage', processImage);
						this.cancelClicked = false;
						if (processImage) {
							this.processImage('ippString'); // buttons will be shown after image is processed
						} else {
							this.toggleCaptureButtons(false); // show buttons if coming back from thumbnailview or having clicked 'no' on capture cancel prompt
						}
					  },
					  (data) => {
				console.log('## setImage');

						this.logger.info('showImageReviewControl() - setImage error data: ' + JSON.stringify(data));
						console.table(data);
						this.cancelCapture();
					},
					imageId
				);
			},
			(data) => {
				console.log('## addImageReviewEditView');
				this.logger.info('showImageReviewControl() - addImageReviewEditView error data: ' + JSON.stringify(data));
				alert("Error addImageReviewEditView! " + JSON.stringify(data));
				this.cancelCapture();
			},
			this.setLayoutForImage()
		);
	}

	private setLayoutForImage() {
		let footerHeight = 100;
		let headerHeight = 80;
		let Layout = window['kfxCordova'].getLayoutProperties();
		Layout.x = 0;
		Layout.y = headerHeight;
		Layout.width = window.innerWidth;
		Layout.height = window.innerHeight - (footerHeight + headerHeight);
		Layout.visibility = true;
		return Layout;
	}

	public showPreviewScreen() {
		this.logger.info('showPreviewScreen()');
		this.dialogService.hideLoadingSpinnerDialog('showPreviewScreen');
		this.initializePreviewScreenEvents('previewScreen', true);
    };

	// #############################################################################################################################################################################
	// #############################################################################################################################################################################
	// ### 		EDIT IMAGE         #########################################################################################################################################
	// #############################################################################################################################################################################
	// #############################################################################################################################################################################

	public showEditScreen() {
		this.logger.info('showEditScreen()');
		this.setViewElements('editScreen').subscribe(() => {});
	}

	public editImage(imageProcessorSetting: string) {
		this.logger.info('editImage()');
		this.toggleCaptureButtons(true);
		if (!this.editImageClicked) {
			this.editImageClicked = true;

			if (imageProcessorSetting === 'cropImage') {
				this.getOptions().subscribe((result) => {
					this.logger.info('editImage() getOptions() - result: ' + result);
					this.imageManuallyCropped = true;
					this.boundingTetragon = result.Tetragon
					this.removeImageReview().subscribe(() => {
						this.processImage(imageProcessorSetting);
					});
				});
			} else if (imageProcessorSetting === 'rotateImage') {
				this.removeImageReview().subscribe(() => {
					this.processImage(imageProcessorSetting);
				});
			}
		}
	}

	public showCroppingTetragon() {
		this.logger.info('showCroppingTetragon()');

		this.getOptions()
		.subscribe((result) => {
			console.log('getOptions() - result', result);
			let tetragon = result.Tetragon;

			if (this.platform.is('android')) {
				tetragon = this.setTetragonForCropping(tetragon);
			}

			this.logger.info('setTetragonForCropping() - tetragon: ' + JSON.stringify(tetragon));

			this.setOptions(tetragon).subscribe((result) => {
				this.setViewElements('cropScreen').subscribe(() => {});
			});
		});
	}

	private setTetragonForCropping(tetragon) {
		const imageWidth = this.capturedImage.imgBitmapWidth;
		const imageHeight = this.capturedImage.imgBitmapHeight;

		tetragon.TopLeft.x = 150;
		tetragon.TopLeft.y = 150;

		tetragon.TopRight.x = imageWidth - 150;
		tetragon.TopRight.y = 150;

		tetragon.BottomLeft.x = 150;
		tetragon.BottomLeft.y = imageHeight - 150;

		tetragon.BottomRight.x = imageWidth - 150;
		tetragon.BottomRight.y = imageHeight - 150;

		return tetragon;
	}

	private initializeBasicSettingsProfile(imageProcessorSetting) {
		this.logger.info('initializeBasicSettingsProfile()');

		/**
		 * Image processing engine that can process a single image according to settings contained in either an ImagePerfectionProfile object, or a BasicSettingsProfile object,
		 * but not both simultaneously.The settings in the ImagePerfectionProfile property take precedence over those in the BasicSettingsProfile property.
		 *
		 */

		//	Get the all default properties of the ImageProcessor.
		if (!this.ImageProcessor) {
			this.ImageProcessor = window['kfxCordova'].kfxEngine.createImageProcessor();
		}
		let imageProcessorOptions = this.ImageProcessor.getImageProcessingOptions();

		///which identifies the Image Representation(s) included in this Image Object.

		imageProcessorOptions.ProcessedImage.representation = "IMAGE_REP_BITMAP";
		imageProcessorOptions.ProcessedImage.mimeType = "MIMETYPE_JPEG";
		imageProcessorOptions.ProcessedImage.jpegQuality = 90;
																					/* IMAGE_REP_NONE The image object has no image content.
																					IMAGE_REP_BITMAP The image object references a bitmap.
																					IMAGE_REP_FILE The image object references a file.
																					IMAGE_REP_BOTH The image object references both a bitmap and a file.*/
		///which identifies the Mime-Type of the image file referenced by this Image Object.
		// this.imageProcessorOptions.ProcessedImage.mimeType = "MIMETYPE_PNG"; //MIMETYPE_JPEG,MIMETYPE_PNG,MIMETYPE_TIFF,MIMETYPE_UNKNOWN

		//Don't set ImagePerfectionProfile options if you want to use BasicSettingsProfile.
		imageProcessorOptions.ImagePerfectionProfile = null;
		imageProcessorOptions.ImageProcessorConfiguration = null;

		///which identifies the rotation type.
		imageProcessorOptions.BasicSettingsProfile.rotateType = "ROTATE_NONE"//ROTATE_NONE,ROTATE_90,ROTATE_180,ROTATE_270,ROTATE_AUTO
		if (imageProcessorSetting === 'rotateImage') {
			console.log('imageProcessorSetting', imageProcessorSetting);
			imageProcessorOptions.BasicSettingsProfile.rotateType = "ROTATE_90"//ROTATE_NONE,ROTATE_90,ROTATE_180,ROTATE_270,ROTATE_AUTO
		}

		///which identifies the crop type.
		imageProcessorOptions.BasicSettingsProfile.cropType = "CROP_NONE";
		if (imageProcessorSetting === 'cropImage') {
			console.log('imageProcessorSetting', imageProcessorSetting);
			imageProcessorOptions.BasicSettingsProfile.cropType = "CROP_TETRAGON";
			imageProcessorOptions.BasicSettingsProfile.BoundingTetragon = this.boundingTetragon;
			/*CROP_NONE -No Crop,
			CROP_AUTO - Automatically crop to the edges of the page found in the image, assuming the image has page edges,
			CROP_TETRAGON - Indicate you want the image cropped to a user supplied tetragon, as provided in the BoundingTetragon property
			CROP_QUICKANALYSIS -when you have previously performed a quick analysis of an input image, and the cropping results are returned in the input image object. Use this option to use these page boundaries, which will reduce image processing time. The Image Processor uses the image meta data stored in the image object after the quick analysis is performed. */
		}

		///The deskew property specifies whether to deskew the image or not.
		imageProcessorOptions.BasicSettingsProfile.inputDocShortEdge = 0.0;
		imageProcessorOptions.BasicSettingsProfile.inputDocLongEdge = 0.0;
		imageProcessorOptions.BasicSettingsProfile.doDeskew = true;
		// Use this property to set the desired output image DPI (Dots Per Inch). If you set it to 0 or null, the library will automatically detect the output image DPI and indicate it in the output object.For good extraction results minimum 96 DPI is required.
		imageProcessorOptions.BasicSettingsProfile.outputDPI = 0;
		///which identifies the output bit depth.
		imageProcessorOptions.BasicSettingsProfile.outputBitDepth = "COLOR"; //BITONAL,GRAYSCALE,COLOR

		return imageProcessorOptions;
	}

	private setOptions(tetragon): Observable<any> {
		this.logger.info('setOptions()');

		console.log('## setOptions()');

		return new Observable((observer) => {
			let imgReviewControl =  window['kfxCordova'].kfxUicontrols.createImageReviewControl();

			let  imageReviewEditViewOptions =  this.ImageReviewControl.getImageReviewEditViewOptions();

			imageReviewEditViewOptions.Tetragon.TopLeft = tetragon.TopLeft;
			imageReviewEditViewOptions.Tetragon.TopRight = tetragon.TopRight;
			imageReviewEditViewOptions.Tetragon.BottomLeft = tetragon.BottomLeft;
			imageReviewEditViewOptions.Tetragon.BottomRight = tetragon.BottomRight;

			imageReviewEditViewOptions.Crop.lineColor = '#00FF00';
			imageReviewEditViewOptions.Crop.cornerColor = '#FFFF00';
			// imageReviewEditViewOptions.Crop.lineStyle = "LINE_STYLE_SOLID";

			function successCallback(result) {
				observer.next(result);
				observer.complete();
			}
			function errorCallback(error) {
				alert(JSON.stringify(error));
			    observer.next(error);
				observer.complete();
			}

			imgReviewControl.setOptions(successCallback,errorCallback,imageReviewEditViewOptions);
		});
	}

	private getOptions(): Observable<any> {
		this.logger.info('getOptions()');

		console.log('## getOptions()');
		return new Observable((observer) => {
			function successCallback(result){
				observer.next(result);
				observer.complete();
				// alert(JSON.stringify(result));
			 }
			 function errorCallback(error){
				observer.next(error);
				observer.complete();
				alert(JSON.stringify(error));
			 }
			 var imgReviewControl =  window['kfxCordova'].kfxUicontrols.createImageReviewControl();
			 this.ImageReviewControl.getOptions(successCallback,errorCallback);
		});
	}

	// #############################################################################################################################################################################
	// #############################################################################################################################################################################
	// ### 		USE IMAGE          #########################################################################################################################################
	// #############################################################################################################################################################################
	// #############################################################################################################################################################################

	public useImage() {
		this.logger.info('useImage()');

		this.imageIsEdited = false;

		this.removeImageReview().subscribe(() => {
			this.setViewElements('thumbnailScreen').subscribe(() => {});
		});
	}

	public setImageData(imgObj) {
		this.logger.info('setImageData()');

		if (this.imageIsEdited) {
			this.processedImagesArray.splice(0, 1);
		}

		this.imageArray = window['kfxCordova'].kfxEngine.createImageArray();
		this.getBase64String(imgObj)
		.subscribe((base64String) => {
			this.getImagesAsBlob(imgObj.imgID)
			.subscribe((blob) => {
				this.currentImg = {
					imgID: imgObj.imgID,
					base64String: 'data:image/jpeg;base64,' + base64String,
					imgSize: blob.byteLength,
					imgBlob: blob,
					imgPath: imgObj.imageFilePath
				}

				this.processedImagesArray.push(this.currentImg);
				console.log('this.processedImagesArray', this.processedImagesArray);
				console.log('this.capturedImage', this.capturedImage);
				console.log('this.processedImage', this.processedImage);
				if (this.capturedImageToDelete || this.processedImageToDelete) {
					console.log('this.capturedImageToDelete', this.capturedImageToDelete);
					console.log('this.processedImageToDelete', this.processedImageToDelete);
					this.deleteImage(this.capturedImageToDelete).subscribe(() => {
						this.deleteImage(this.processedImageToDelete).subscribe(() => {
							this.capturedImageToDelete = this.capturedImage;
							this.processedImageToDelete = this.processedImage;
						});
					});
				} else {
					this.capturedImageToDelete = this.capturedImage;
					this.processedImageToDelete = this.processedImage;
				}
				// this.clearImageBitmap(this.capturedImage).subscribe(() => {
				// });
				// this.clearImageBitmap(this.processedImage).subscribe(() => {
				// });
				// function successCallback(response){
				// 	console.log(response);
				// }
				// function errorCallback(error){
				// 	alert(JSON.stringify(error));
				// }
				// this.imageArray.getImageIDs(successCallback,errorCallback);

			});
		});
	}

	// public getImageProperties(imgID): Observable<any> {
	// 	return new Observable((observer) => {
	// 		function successCallback(imgObj){
	// 			observer.next(imgObj);
	// 			observer.complete();
	// 		}

	// 		function errorCallback(error){
	// 			alert(JSON.stringify(error));
	// 		}

	// 		  this.imageArray.getImageProperties(successCallback,errorCallback, imgID);

	// 	});
	// }

	// public getImageSize(imageID): Observable<any> {
	// 	return new Observable((observer) => {
	// 		function successCallback(imageSize){
	// 			observer.next(imageSize);
	// 			observer.complete();
	// 		}
	// 		function errorCallback(error){
	// 			observer.next(error);
	// 			observer.complete();
	// 			alert(JSON.stringify(error));
	// 		}

	// 		this.imageArray.getImageSize(successCallback,errorCallback, imageID);
	// 	});
	// }

	private getBase64String(imgObj): Observable<any> {
		this.logger.info('getBase64String()');

		return new Observable((observer) => {
			imgObj.base64Image(
				(result) => {
					observer.next(result);
					observer.complete();
					// this.captureService.startCaptureUpload(this.selectedWorkflow, convertedImage.name[0])
					// .subscribe(() => {});
				},
				(error) => {
					observer.next(error);
					observer.complete();
					alert("base64Image error" + error);
				}
			);
		});
	}

	private getImagesAsBlob(imageID): Observable<any> {
		this.logger.info('getImagesAsBlob()');

		return new Observable((observer) => {
			function successCallback(blob) {
				observer.next(blob);
				observer.complete();
				// this.captureService.startCaptureUpload(this.selectedWorkflow, blob, imageSize).subscribe((res) => {console.log('res', res);});
				// alert(JSON.stringify(response));
			}

			function errorCallback(error){
				alert(JSON.stringify(error));
			}
			this.imageArray.getImageAsBlob(successCallback.bind(this),errorCallback, imageID);
		});
	}


	// public getImageIDs(imageArray): Observable<any> {
	// 	return new Observable((observer) => {
	// 		function successCallback(imageIDs) {
	// 			console.log('getImageIDs imageIDs', imageIDs);
	// 			observer.next(imageIDs);
	// 			observer.complete();
	// 		}
	// 		function errorCallback(error) {
	// 			alert(JSON.stringify(error));
	// 			observer.next(error);
	// 			observer.complete();
	// 		}
	// 		imageArray.getImageIDs(successCallback.bind(this),errorCallback);
	// 	});
	// }

	// public getImageArray() {
	// 	let imageArray = window['kfxCordova'].kfxEngine.createImageArray();
	// 	console.log('getImageArray() - imageArray', imageArray);
	// 	imageArray.getTotalImages((result) => {
	// 		console.log('result', result);
    //         if (result > 0) {
	// 			// imageArray.getImageProperties()
    //         }
    //     }, function(getImageCountError) {
	// 		alert("Get images count error" + JSON.stringify(getImageCountError));
    //         console.log("Get images count error " + JSON.stringify(getImageCountError));
    //     });
	// }

	// public showProcessedImageArray() {
	// 	console.log('this.processedImagesArray', this.processedImagesArray);
	// }

	// #############################################################################################################################################################################
	// #############################################################################################################################################################################
	// ### 		PREVIEW PROCESSED IMAGE       #########################################################################################################################################
	// #############################################################################################################################################################################
	// #############################################################################################################################################################################

	public previewProcessedImage(image) {
		this.logger.info('previewProcessedImage()');

		console.log('thumbnail preview image', image);
		console.log('this.processedImagesArray', this.processedImagesArray);
		this.selectedImageID = image.imgID;
		this.initializePreviewScreenEvents('thumbnailPreviewScreen', false);
	}

	// #############################################################################################################################################################################
	// #############################################################################################################################################################################
	// ### 		GALLERY         #########################################################################################################################################
	// #############################################################################################################################################################################
	// #############################################################################################################################################################################

	// private checkGalleryPermission() {
	// 	this.logger.info('checkGalleryPermission()');
	// 	console.log('## checkGalleryPermission');
	// 	let permission = 'gallery';
    //     this.captureService.checkPermissionEnabled(this.diagnostic.permission.CAMERA).subscribe((status) => {
	// 		console.log('CHECK_GALLERY_PERMISSION status', status);
    //         // this.openGallery();
    //     }, (errormessage) => {
	// 		console.log('errormessage', errormessage);
    //     // alert("checkPermission error::" + errormessage);
    //     //   console.log("checkPermissionEnabled error callback " + errormessage);
    //     // }, '' // just send anything until we know what to do with it
	// 	// cordova.plugins.diagnostic.runtimePermission.WRITE_EXTERNAL_STORAGE
	// 	});
    // };

	private checkCameraPermission(): Observable<any> {
		this.logger.info('checkCameraPermission()');

		return new Observable((observer) => {
			this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.CAMERA).then(
				((result) => {
					console.log('Has CAMERA permission?',result.hasPermission)
					if (!result.hasPermission) {
						this.requestCameraPermission()
						.subscribe(() => {
							observer.next();
							observer.complete();
						});
					} else {
						observer.next();
						observer.complete();
					}
				}),
				((err) => {
					this.logger.info('checkCameraPermission() - error: ' + err);
					alert('checkPermission() error: ' + err);
					console.log('err', err);
					this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.CAMERA)
				})
			);
		});
	}

	private requestCameraPermission(): Observable<any> {
		this.logger.info('requestCameraPermission()');

		return new Observable((observer) => {
			this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.CAMERA)
			.then((response) => {
				this.logger.info('requestCameraPermission() - response AFTER REQUESTED PERMISSION hasPermission: ' + response.hasPermission);
				console.log('response AFTER REQUESTED PERMISSION', response);
				observer.next();
				observer.complete();
			}),
			((err) => {
				this.logger.info('requestCameraPermission() - error: ' + err);
				alert('requestPermission() error: ' + JSON.stringify(err));
				console.log('requestCameraPermission err', err);
			});
		});
	}

	public initiateGallery() {
		this.logger.info('initiateGallery() - click()');
		this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.READ_MEDIA_IMAGES).then((read_media_vimages_result) => {
		console.log('read_media_vimages_result', read_media_vimages_result);
			if (!read_media_vimages_result.hasPermission) {
			this.requestGalleryPermission().subscribe((response) => {
				if (response.hasPermission) {
					this.openGallery();
				} else {
					this.logger.info('initiateGallery() requestGalleryPermission() - response: ' + response);
					alert('Permission Denied, go to device settings - Printix App permissions to enable photos access');
				}
			});
			} else {
				this.openGallery();
			}
		}),
		((err) => {
			this.logger.info('initiateGallery() checkPermission() - error: ' + err);
			console.log('err', err);
		});
	}



	private requestGalleryPermission(): Observable<any> {
		this.logger.info('requestGalleryPermission()');
	 	console.log('## REQUEST_GALLERY_PERMISSION');
		const deviceVersion = parseInt(this.device.version, 10);
		const permission = deviceVersion >= 13 ? 'READ_MEDIA_IMAGES' : 'READ_EXTERNAL_STORAGE';
		const galleryPermission = deviceVersion >= 13 ? this.androidPermissions.PERMISSION.READ_MEDIA_IMAGES : this.androidPermissions.PERMISSION.READ_EXTERNAL_STORAGE;
		return new Observable((observer) => {
		  this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.CAMERA).then((response) => {
			console.log('requestGalleryPermission() - CAMERA permission response', response);
			  this.androidPermissions.requestPermission(galleryPermission).then((response) => {
				this.logger.info('requestCameraPermission() - ' + permission + ' (Android ' + deviceVersion + ') hasPermission: ' + response.hasPermission);
				console.log('requestGalleryPermission() - ' + permission + ' (Android ' + deviceVersion + ') response', response);
				observer.next(response);
				observer.complete();
			  });
			});
		});
	  }

    public openGallery() {
		this.logger.info('openGallery()');

		if (!this.galleryButtonClicked) {
			this.galleryButtonClicked = true;
			this.forcePictureTaken = true;

			this.removeImageCaptureView().subscribe(() => {
				const options: CameraOptions = {
					quality: 100,
					destinationType: this.camera.DestinationType.DATA_URL,
					sourceType: this.camera.PictureSourceType.PHOTOLIBRARY
				}

				this.broadcaster.broadcast('DISABLE_RESUME');
				this.logger.info('this.camera.getPicture()');
				this.camera.getPicture(options).then( // this opens device gallery/photolibrary
				(imageData) => {
						this.logger.info('PICTURE_GOT');
						this.backButtonAllowed = false;
						// imageData is either a base64 encoded string or a file URI
						this.deleteImage(this.capturedImage).subscribe(() => { // For retake
							this.capturedImage = null;
							this.getImgIdBase64(imageData);
						});
					}, (error) => {
						this.broadcaster.broadcast('ENABLE_RESUME');
						this.galleryButtonClicked = false;
						this.forcePictureTaken = false;
						if (error === 'No Image Selected') {
							this.retakeAction(false);
						} else {
							this.logger.info('handle error from camera.getPicture() openGallery() error: ' + error);
							this.backButtonAllowed = true;
							alert('Open gallery Error: ' + JSON.stringify(error));
						}
					}
				);
			});
		}
	};

    /*
     * Saves given base64 Image in image array
     *
     * @param - image as base64 string
     *
     * @return - Image Object of saved image
     *
     */

    private getImgIdBase64(base64Str) {
		this.logger.info('getImgIdBase64()');
        //Create an instance of ImageArray
        var imageArray = window['kfxCordova'].kfxEngine.createImageArray();
        imageArray.getImageFromBase64 (
			(data) => {
				console.log('## IMAGE_FROM_BASE64_GOT');
				this.broadcaster.broadcast('ENABLE_RESUME');
				//Assign the obtained data to imageObject
				this.galleryImageSelected = true;
				console.log('this.capturedImage', this.capturedImage);
				console.log('data', data);

				this.imgCapturedSuccessCallback(data);
			},
			(error) => {
				this.broadcaster.broadcast('ENABLE_RESUME');
				this.isRetakeClicked = false;
				// this.dialogService.hideLoadingSpinnerDialog('getImageFromBase64 Error');
				this.logger.info('getImgIdBase64() - ERROR: ' + JSON.stringify(error));
				console.log('JSON.stringify(error)', JSON.stringify(error));
				this.backButtonAllowed = true;
				this.dialogService.showTranslatedMessageDialog('UnsupportedFileFormat', 'Capture');
				this.retakeAction(true);
			},
			base64Str
		);
    };

	// #############################################################################################################################################################################
	// #############################################################################################################################################################################
	// ### 		START CAPTURE UPLOAD        #########################################################################################################################################
	// #############################################################################################################################################################################
	// #############################################################################################################################################################################

	public startCaptureUpload() {
		this.logger.info('startCaptureUpload()');

		if (!this.isInternetAccessAvailable) {
			this.dialogService.showTranslatedMessageDialog('NoAccessInternet');
		} else {
			this.historyIconButton.nativeElement.classList.add('active');
			this.translateService.get('Uploading')
			.subscribe( async (translatedMessage: string) => {
				this.dialogService.showLoadingSpinnerWithMessage(translatedMessage, 'startCaptureUpload()')
				// this.dialogService.showTemporaryLoader();
				this.captureService.startCaptureUpload(this.selectedWorkflow, this.processedImagesArray)
				.subscribe(() => {
					// this.dialogService.tempDismiss();
					this.dialogService.hideLoadingSpinnerDialog('startCaptureUpload()');
					this.cancelCapture();
					// go to popover for capture upload
				},(httpErrorResponse) => {
					console.log('ERROR_RESPONSE ########## ########### httpErrorResponse', httpErrorResponse);
					this.dialogService.hideLoadingSpinnerDialog('startCaptureUpload() Error');
					if (httpErrorResponse.status === 404) {
						console.log('httpErrorResponse', httpErrorResponse);
						this.translateService.get('WorkflowDeleted').subscribe( async (translatedMessage: string) => {
							this.dialogService.showAlertDialog(translatedMessage);
						});
					} else {
						this.errorService.handleHttpClientResponseError(httpErrorResponse, 'POST', '[CapturePage] startCaptureUpload()');
					}
				});
			});
		}
	}

	// #############################################################################################################################################################################
	// #############################################################################################################################################################################
	// ### 		CANCEL - CAPTURE         #########################################################################################################################################
	// #############################################################################################################################################################################
	// #############################################################################################################################################################################

	public cancelCapture() { // Cancel capture function
		this.logger.info('cancelCapture() - currentScreen: ' + this.currentScreen);

		if (this.currentScreen === 'previewScreen' || this.currentScreen === 'editScreen' || this.currentScreen === 'cropScreen') {
			console.log('## CANCEL_PREVIEW_SCREEN');
			this.removeAllImages().subscribe(() => {
				console.log('## ALL_IMAGES_REMOVED');
				this.processedImagesArray = [];
				this.capturedImage = null;
				this.processedImage = null;
				this.setViewElements('frontCaptureScreen').subscribe(() => {});
			});
		} else if (this.currentScreen === 'captureScreen') { /////
			console.log('## CANCEL_CAPTURE_SCREEN');
			this.removeAllImages().subscribe(() => {
				console.log('## ALL_IMAGES_REMOVED');
				this.capturedImage = null;
				this.processedImage = null;
				this.processedImagesArray = [];
				this.setViewElements('frontCaptureScreen').subscribe(() => {});
			});
		} else if (this.currentScreen === 'thumbnailScreen') {
			console.log('## CANCEL_THUMBNAIL_SCREEN');
			this.removeAllImages().subscribe(() => {
				this.removeImageReview().subscribe(() => {
					this.capturedImage = null;
					this.processedImage = null;
					this.processedImagesArray = [];
					this.setViewElements('frontCaptureScreen').subscribe(() => {});
				});
			});
		} else if (this.currentScreen === 'thumbnailPreviewScreen') {
			this.removeAllImages().subscribe(() => {
				this.capturedImage = null;
				this.processedImage = null;
				this.processedImagesArray = [];
				this.removeImageReview().subscribe(() => {
					this.setViewElements('frontCaptureScreen').subscribe(() => {});
				});
			});
		}
	}

	public promptForCancellingCapture() {
		this.logger.info('promptForCancellingCapture()');
		this.toggleCaptureButtons(true);

		if (this.isflashOn) {
			this.setFlashOption(
				"OFF",
				function () {
				  this.isflashOn = false;
				  this.flashButtonOn.nativeElement.hidden = true;
				}.bind(this),
				function () {
				  this.logger.info('flashButtonClicked() - Error CB for setFlashOption');
				}
			);
		}

		if (this.currentScreen === 'previewScreen' || this.currentScreen === 'editScreen' || this.currentScreen === 'cropScreen') {
			console.log('## CANCEL_PREVIEW_SCREEN');
			this.removeImageReview().subscribe(() => {
				this.dialogService.showConfirmDialog('Cancel', 'Yes', 'No', 'Capture').subscribe((cancelCapture) => {
					if (cancelCapture) {
						this.cancelCapture();
					} else {
						this.cancelClicked = true;
						this.initializePreviewScreenEvents('previewScreen', false);
					}
				});
			});
		} else if (this.currentScreen === 'captureScreen') {
			console.log('## CANCEL_CAPTURE_SCREEN');
			this.removeImageCaptureView().subscribe(() => {
				this.dialogService.showConfirmDialog('Cancel', 'Yes', 'No', 'Capture').subscribe((cancelCapture) => {
					if (cancelCapture) {
						this.cancelCapture();
					} else {
						this.startCapture();
					}
				});
			});
		} else if (this.currentScreen === 'thumbnailScreen') {
			this.dialogService.showConfirmDialog('Cancel', 'Yes', 'No', 'Capture').subscribe((cancelCapture) => {
				if (cancelCapture) {
					this.cancelCapture();
				}
			});
		} else if (this.currentScreen === 'thumbnailPreviewScreen') {
			console.log('## ABOUT_TO_CANCEL_THE_STUFF');
			this.useImage(); // go back to thumbnailScreen
			this.dialogService.showConfirmDialog('Cancel', 'Yes', 'No', 'Capture').subscribe((cancelCapture) => {
				if (cancelCapture) {
					this.cancelCapture();
				}
			});
		};
	}

	// #############################################################################################################################################################################
	// #############################################################################################################################################################################
	// ### 		CLEAR, REMOVE - CAPTURE         #########################################################################################################################################
	// #############################################################################################################################################################################
	// #############################################################################################################################################################################

	public clearImageBitmap(image): Observable<any> {
		this.logger.info('clearImageBitmap()');

		return new Observable((observer) => {
			if (image) {
				function successCallback(response) {
					observer.next(response);
					observer.complete();
				}
				function errorCallback(error) {
					observer.next(error);
					observer.complete();
					alert(JSON.stringify(error));
				}
				let imageArray = window['kfxCordova'].kfxEngine.createImageArray();
				imageArray.clearImageBitmap(successCallback,errorCallback, image.imgID);
			} else {
				observer.next();
				observer.complete();
			}
		});
	}

	public removeImages() {
		this.logger.info('removeImages()');

		console.log('## removeImages()');
        //Create an instance of ImageArray
        const ImageArray = window['kfxCordova'].kfxEngine.createImageArray();
		console.log('ImageArray', ImageArray);
        ImageArray.getTotalImages((result) => {
			console.log('result', result);
            if (result > 0) {
                ImageArray.removeImages(function(result) {
                    console.log("Delete all images success callback " + JSON.stringify(result));
                }, function(deleteImageError) {
                    alert("Delete all  images error callback " + JSON.stringify(deleteImageError));
                    console.log("Delete all images error callback " + JSON.stringify(deleteImageError));
                });
            }
        },
		(getImageCountError) => {
            alert("Get images count error" + JSON.stringify(getImageCountError));
            console.log("Get images count error " + JSON.stringify(getImageCountError));
        });
        this.capturedImage = null;
        this.processedImage = null;
	}


	private removeAllImages(): Observable<any> {
		this.logger.info('removeAllImages()');
        //Create an instance of ImageArray
        const ImageArray = window['kfxCordova'].kfxEngine.createImageArray();
		return new Observable((observer) => {
			ImageArray.getTotalImages((result) => {
				console.log('result', result);
				if (result > 0) {
					ImageArray.removeAllImages(function(result) {
						console.log("Delete all images success callback " + JSON.stringify(result));
						observer.next();
						observer.complete();
					}, function(deleteImageError) {
						observer.next();
						observer.complete();
						alert("Delete all  images error callback " + JSON.stringify(deleteImageError));
						console.log("Delete all images error callback " + JSON.stringify(deleteImageError));
					});
				} else {
					observer.next();
					observer.complete();
				}
			},
			(getImageCountError) => {
				observer.next();
				observer.complete();
				alert("Get images count error" + JSON.stringify(getImageCountError));
				console.log("Get images count error " + JSON.stringify(getImageCountError));
			});
		});
    }

	/*
	* Hides ImageReview Edit Control view
	*
	*/
	public removeImageReview(): Observable<any> {
		this.logger.info('removeImageReview()');

		var ImageReviewControl = window['kfxCordova'].kfxUicontrols.createImageReviewControl();

		return new Observable((observer) => {
			ImageReviewControl.removeView(
			  (data) => {
				console.log("calling success ImageReviewEditView.removeView");
				observer.next();
				observer.complete();
			},
			(error) => {
				observer.next();
				observer.complete();
				alert("Error ImageReviewControl.removeView! " + error);
			  }
			);
		});
	}

	// #############################################################################################################################################################################
	// #############################################################################################################################################################################
	// ### 		DELETE - CAPTURE         #########################################################################################################################################
	// #############################################################################################################################################################################
	// #############################################################################################################################################################################

	public deleteProcessedImageFromArray() {
		this.logger.info('deleteProcessedImageFromArray()');

		console.log('## deleteProcessedImageFromArray()');
		let indexToDelete = null;
		// console.log('## startLoop');
		this.processedImagesArray.forEach((imgObj, index) => {
			// console.log('## loopIteration');
			if (imgObj.imgID === this.selectedImageID) {
				indexToDelete = index;
			}
		});
		// console.log('## endLoop');
		// console.log('indexToDelete', indexToDelete);
		if (indexToDelete !== null) {
			// console.log('## removeItemInArray');
			this.processedImagesArray.splice(indexToDelete, 1);
		}
		// console.log('## goToThumbnail');
		if (this.processedImagesArray.length > 0) {
			this.useImage();
		} else {
			this.cancelCapture();
		}
	}

	private deleteImage(img): Observable<any> {
		this.logger.info('deleteImage()');

		return new Observable((observer) => {
			if (img && img !== "undefined") {
				// Remove the previous taken image from the image array
				img.deleteImage(
					(result) => {
						console.log("Delete image success callback " + JSON.stringify(result));
						observer.next();
						observer.complete();
					},(deleteImageError) => {
						alert("Delete image error callback " + JSON.stringify(deleteImageError));
						console.log("Delete image error callback " + JSON.stringify(deleteImageError));
						this.capturedImage = null;
						observer.next();
						observer.complete();
					}
				);
			} else {
				observer.next();
				observer.complete();
			}
		});
	}

	// #############################################################################################################################################################################
	// #############################################################################################################################################################################
	// ### 		RETAKE_ACTION - CAPTURE         #########################################################################################################################################
	// #############################################################################################################################################################################
	// #############################################################################################################################################################################

	public retakeAction(retakePhoto: boolean) {
		this.logger.info('retakeAction()');

		if (!this.retakeActionClicked) {
			this.retakeActionClicked = true;
			if (retakePhoto) { // if retakePhoto is true remove image review and all images in memory, if not just show capture Screen
				console.log('## RE-TAKE_PHOTO');
				this.processedImagesArray.splice(0, 1);
				this.removeAllImages().subscribe(() => {
					this.capturedImage = null;
					this.processedImage = null;
					this.removeImageReview().subscribe(() => {
						this.setViewElements('captureScreen').subscribe(() => {
							this.broadcaster.broadcast('DISABLE_RESUME');
							this.startCapture();
						});
					});
				});
			} else {
				this.setViewElements('captureScreen').subscribe(() => {
					this.broadcaster.broadcast('DISABLE_RESUME');
					this.startCapture();
				});
			}

		}
    };

	public goToCaptureHistoryPage() {
		this.logger.info('goToCaptureHistoryPage()');
		this.navCtrl.navigateForward(['/capture-history']);
	}

	public sendCallback(callback, data) {
		this.logger.info('sendCallback()');

		if (typeof callback === "function") {
			callback(data);
		}
	};

	// public stopTimer() {
	// 	if (this.manualTimer) {
	// 		clearTimeout(this.manualTimer);
	// 	}
	// }

	// #############################################################################################################################################################################
	// #############################################################################################################################################################################
	// ### 		FLASH         #########################################################################################################################################
	// #############################################################################################################################################################################
	// #############################################################################################################################################################################

	public setFlashOption(flashType, successcallback, errorcallback) {
		this.logger.info('setFlashOption()');

		//Get the all default properties of the ImageCaptureControl
		var imageCaptureViewProperties = this.imageCaptureControl.getImageCaptureViewOptions();
		//Set leveling Options to null
		imageCaptureViewProperties.LevelingOptions = null;
		imageCaptureViewProperties.CaptureOptions.FlashMode = flashType;
		//set the properties of the native ImageCaptureControl class.

		this.imageCaptureControl.setOptions((result) => {
				this.logger.info('setFlashOption() - camera setOptions callback: ' + JSON.stringify(result));
				successcallback();
			},(error) => {
				errorcallback();
				this.logger.info('setFlashOption() - ImageCaptureControl.setOptions error: ' + JSON.stringify(error));
				alert(" ImageCaptureControl.setOptions error !" + error);
			},
			imageCaptureViewProperties
		);
	};

	// $("#flashButton").click(function (e)
	public flashButtonClicked() {
		this.logger.info('flashButtonClicked()');

	    var flashType = "OFF";

	    if (!this.isFlashSupport) {
	      return;
	    }

	    if (!this.isflashOn) {
	      flashType = "TORCH";
	    }

	    this.setFlashOption(
	      flashType,
	      function () {
	        // document.getElementById("flashButton").disabled = false;
	        if (!this.isflashOn) {
	          	this.isflashOn = true;
			  	this.flashButtonOff.nativeElement.hidden = true;
				this.flashButtonOn.nativeElement.hidden = false;
	        } else {
				this.isflashOn = false;
				this.flashButtonOff.nativeElement.hidden = false;
				this.flashButtonOn.nativeElement.hidden = true;
	        }
	      }.bind(this),
	      function () {
			this.logger.info('flashButtonClicked() - Error CB for setFlashOption');
	        // document.getElementById("flashButton").disabled = false;
	      }
	    );
	};

		// #############################################################################################################################################################################
	// #############################################################################################################################################################################
	// ### 				startListener()           ############################################################################################################
	// #############################################################################################################################################################################
	// #############################################################################################################################################################################


	public startListener() {
		this.logger.info('startListener()');

		this.workflowCreatedSubscription = this.broadcaster.on<object>(CHANNELS.ALL_TENANT_USERS.EVENTS.WORKFLOW_CREATED) // get request
		.subscribe(data => {
		  console.log('**PUSHER WORKFLOW_CREATED', data);
		  this.logger.info('**PUSHER WORKFLOW_CREATED');
		  this.getWorkflows();
		});

		this.workflowUpdatedSubscription = this.broadcaster.on<object>(CHANNELS.ALL_TENANT_USERS.EVENTS.WORKFLOW_UPDATED) // get request
		.subscribe(data => {
		  console.log('**PUSHER WORKFLOW_UPDATED', data);
		  this.logger.info('**PUSHER WORKFLOW_UPDATED');
		  this.getWorkflows();
		});

		this.workflowDeletedSubscription = this.broadcaster.on<object>(CHANNELS.ALL_TENANT_USERS.EVENTS.WORKFLOW_DELETED) // get request
		.subscribe(data => {
		  console.log('**PUSHER WORKFLOW_DELETED', data);
		  this.logger.info('**PUSHER WORKFLOW_DELETED');
		  this.getWorkflows();
		});

		this.scanCreatedSubscription = this.broadcaster.on<object>(CHANNELS.SPECIFIC_USER.EVENTS.USER_SCAN_CREATED) // get request
		.subscribe(data => {
		  console.log('**PUSHER SCAN_CREATED', data);
		//   console.log('CHANNELS.SPECIFIC_USER.EVENTS.USER_SCAN_CREATED', CHANNELS.SPECIFIC_USER.EVENTS.USER_SCAN_CREATED);
		  this.logger.info('**PUSHER SCAN_CREATED');
		});

		this.scanDeletedSubscription = this.broadcaster.on<object>(CHANNELS.SPECIFIC_USER.EVENTS.USER_SCAN_DELETED) // get request
		.subscribe(data => {
		  console.log('**PUSHER SCAN_DELETED', data);
		  this.logger.info('**PUSHER SCAN_DELETED');
		});

		this.scanUpdatedSubscription = this.broadcaster.on<object>(CHANNELS.SPECIFIC_USER.EVENTS.USER_SCAN_UPDATED).subscribe(data => { // get request
		  console.log('**PUSHER SCAN_UPDATED', data);
		  this.logger.info('**PUSHER SCAN_UPDATED');
		  // get req på linket
		  this.captureService.getScanUpdatedData(data)
		  .subscribe((scanUpdated) => {
			this.scanServerProcessState = scanUpdated.state
			console.log('getScanUpdatedData() - scanUpdated', scanUpdated);
			this.scanServerProcessState = scanUpdated.state
			if (this.scanServerProcessState === 'FINISHED') {
				// remove icon
				this.historyIconButton.nativeElement.classList.remove('active');
			}
			// this.captureService.checkFileProcessingStateOnServer(scanUpdated)
			// .subscribe((processingPending) => {
			// 	if (!processingPending) {
			// 		this.historyIconButton.nativeElement.classList.remove('active');
			// 	}
			// });
		  })
		});

		this.scanUpdatedSubscription = this.broadcaster.on('languageChange').subscribe((LanguageCode) => {
			this.logger.info('languageChange - LanguageCode: ' + LanguageCode);
			// location.reload();
	 		this.initializeFrontCaptureScreenEvents();
		});

		this.isInternetAvailableSubscription = this.networkService.isInternetAvailable
	    .subscribe((isInternetAccessAvailable: boolean) => {
			this.isInternetAccessAvailable = isInternetAccessAvailable;
			this.errorNetwork.forEach(el => {
				el.nativeElement.hidden = isInternetAccessAvailable;
			});
	    });

		this.platformResumeEventSubscription = this.platform.resume.subscribe(async () => {
			if (this.currentScreen === 'frontCaptureScreen') {
				this.getWorkflows();
			}
		});
	}

	// private showToastForCaptureUpload(message): void {
	// 	this.logger.info('showToastForReleasePrint()');
	// 	let toast = this.toastController.create({
	// 	  message: message,
	// 	  duration: 3000,
	// 	  position: 'top'
	// 	})
	// 	.then(toast => toast.present());
	// }

	// #############################################################################################################################################################################
	// #############################################################################################################################################################################
	// ### 		EXTRACTION         #########################################################################################################################################
	// #############################################################################################################################################################################
	// #############################################################################################################################################################################

	// MOVED_TO_SEPARATE_FILE

	// #############################################################################################################################################################################
	// #############################################################################################################################################################################
	// ### 				SETTINGS                   ############################################################################################################
	// #############################################################################################################################################################################
	// #############################################################################################################################################################################

	// MOVED_TO_SEPARATE_FILE

	// #############################################################################################################################################################################
	// #############################################################################################################################################################################
	// ### 				getSDKVersionInfo()           ############################################################################################################
	// #############################################################################################################################################################################
	// #############################################################################################################################################################################

	// MOVED_TO_SEPARATE_FILE
}
