import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Logger, LoggingService } from 'ionic-logging-service';
import { Observable, Subject, Subscriber } from 'rxjs';
import { delay, finalize, retryWhen, take, tap } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { ErrorService } from '../error/error.service';
import { Broadcaster } from '../events/broadcaster.class';
import { EventService } from '../events/event.service';
import { PlatformService } from '../platform/platform.service';
import { StorageService } from '../storage/storage.service';
import { TenantService } from '../tenant/tenant.service';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  public  authWaiting: boolean = false;
  public  errorMessage: string = null;
  private isCordova: boolean = this.platformService.isCordova;
  private logger: Logger;
  public  loginFailed: string = null;
  public  recoveringPassword: boolean = false;
  private httpErrorResponse: HttpErrorResponse;
  private unSubscribe = new Subject<void>();

  constructor(
    private authService: AuthService,
    private broadcaster: Broadcaster,
    private errorService: ErrorService,
    private eventService: EventService,
    private httpClient: HttpClient,
    private loggingService: LoggingService,
    private platformService: PlatformService,
    private storageService: StorageService,
    private tenantService: TenantService,
    private userService: UserService
  ) {
    this.logger = loggingService.getLogger("[HttpService]");
    const methodName = "ctor";
    this.logger.entry(methodName);
  }

  public httpRequestGET(url, component, method): Observable<any> {
    return new Observable((observer) => {
        this.httpClient.get<any>(url)
        .pipe(retryWhen(error => error.pipe(
            delay(1000),
            take(3),
            // return httpErrorResponse.status > 499 ? Observable.of(true) : Observable.throw(httpErrorResponse);
            tap((httpErrorResponse: HttpErrorResponse) => {this.httpErrorResponse = httpErrorResponse}),
            finalize(() => {
                if (this.httpErrorResponse.status === 401 || this.httpErrorResponse.status ===  403) {
                    this.logger.info('[' + component + '] ' + method + '() httpErrorResponse === ' + this.httpErrorResponse.status);
                } else {
                    this.errorService.handleHttpClientResponseError(this.httpErrorResponse, 'GET', '[' + component + '] ' + method + '()');
                }
                observer.error(this.httpErrorResponse);
                observer.complete();
            })
        )))
        .subscribe((res: any) => {
            observer.next(res);
            observer.complete();
        });
    });
  }

  public httpRequestPOST(url, params, component, method, headers?): Observable<any> {
    return new Observable((observer) => {
        this.httpClient.post<any>(url, params, headers ? headers : '')
        .pipe(retryWhen(error => error.pipe(
            delay(1000),
            take(3),
            // return httpErrorResponse.status > 499 ? Observable.of(true) : Observable.throw(httpErrorResponse);
            tap((httpErrorResponse: HttpErrorResponse) => {this.httpErrorResponse = httpErrorResponse}),
            finalize(() => {
                if (this.httpErrorResponse.status === 401 || this.httpErrorResponse.status ===  403) {
                    this.logger.info('[' + component + '] ' + method + '() httpErrorResponse === ' + this.httpErrorResponse.status);
                } else {
                    this.errorService.handleHttpClientResponseError(this.httpErrorResponse, 'GET', '[' + component + '] ' + method + '()');
                }
                observer.error(this.httpErrorResponse);
                observer.complete();
            })
        )))
        .subscribe((res: any) => {
            observer.next(res);
            observer.complete();
        });
    });
  }
}