import { Component, OnInit } from '@angular/core';
import { ModalController, NavController, NavParams, Platform, PopoverController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ErrorService } from 'src/app/services/error/error.service';
import { PagingService } from 'src/app/services/paging/paging.service';
import { PasswordService } from 'src/app/services/password/password.service';
import { IPrintJob } from 'src/app/services/print-job/models/print-job.model';
import { IPrinter } from 'src/app/services/printer/models/printer.model';
import { IQueue } from 'src/app/services/printer/models/queue.model';
import { PrinterService } from 'src/app/services/printer/printer.service';
import { ReleaseResourceService } from 'src/app/services/release-resource/release-resource.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { ITenant } from 'src/app/services/tenant/models/tenant.model';
import { TenantService } from 'src/app/services/tenant/tenant.service';
import { IUser } from 'src/app/services/user/models/user.model';
import { UserService } from 'src/app/services/user/user.service';
import { NFC } from '@awesome-cordova-plugins/nfc/ngx';
import { Logger, LoggingService } from 'ionic-logging-service';
import { Clipboard } from '@awesome-cordova-plugins/clipboard/ngx';
import { DialogService } from 'src/app/services/dialog/dialog.service';
@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
})
export class PopoverComponent implements OnInit {

  public printJob: IPrintJob = null;
  public unSubscribe = new Subject<void>();
  public unSubscribeChangePrinterQueuePinStatus = new Subject<void>();
  public unSubscribeSetSecurePrintMethods = new Subject<void>();
  public getUserSubscription = new Subject<void>();
  public printJobs: Array<IPrintJob> = [];
  public menuType: string = null;
  public selectPrinterList: Array<IPrinter> = [];
  public printerList: Array<IPrinter> = [];
  public scannedPrinters: Array<IPrinter> = [];
  public printQueuesList: Array<IQueue> = [];
  public user: IUser = null;
  public selectedPrinter: IPrinter = null;
  public showPrintJobInfo: boolean = false;
  public originalEvent: any = {};
  public tenant: ITenant = null;
  public networks: any = null;
  public resourceId: string = null;
  public filterObjects: any = [];
  public tenantNetworks: Array<string> = null;
  public storedNetworks: Array<string> = null;
  public pinnedPrinterUrls: any = null;
  public lastUsedQueueUrl: string = null;
  public lastUsedPrinterUrl: string = null;
  public lastUsedQueue: IQueue = null;
  public errorParameters: string[] = [];
  private logger: Logger;
  public filterObjectsKey: string = 'filter_objects';
  public network: any;
  public pagingObject: {} = {};
  public showDeselectPrintJobs: boolean = false;
  public showAirPrintPasswordsList: boolean = false;
  public showSecurePrintLevel: boolean = false;
  public showMustSignInAtPrinter: boolean = false;
  public showSendLogsDialog: boolean = false;
  // public passwordList: boolean = [];
  public generatedAirPrintPassword: string = null;
  public storageKeyAirPrintPrefix: string = 'air_print_password';
  public storageKeyTenant: string = this.tenantService.tenant ? this.tenantService.tenant.links.self : null;
  public storageKeyUser: string = this.userService.user ? this.userService.user.links.self : null;
  public storageKeyAirPrintPassword: string = null;
  public passwordIdentifierText: string = null;
  public securePrintLevel: string = null;
  public showUnmatchedPrinterMessage: boolean = false;
  public showSecurePrintMethods: boolean = false;
  public securePrintMethods: Array<string>;
  public releaseHistoryFilters: Array<object>;
  public activeMethods: Array<object>;
  public showScanningMethods: boolean = false;
  public showNfcScanningReady: boolean = false;
  public confirmPrinterWithTag: boolean = false;
  public nfcEnabled: boolean = false;
  public nfcId: string = null;
  public PrinterSignId: string = null;
  public showQRScanButton: boolean = false;
  public showNFCScanButton: boolean = false;
  public showDeviceVersionWarning: boolean = false;
  public showreleaseHistoryFilters: boolean = false;
  public showPromptForSupportID: boolean = false;
  public supportID: number;

  constructor(
    private clipboard: Clipboard,
    private dialogService: DialogService,
    private errorService: ErrorService,
    private loggingService: LoggingService,
    public modalCtrl: ModalController,
    public navController: NavController,
    public navParams: NavParams,
    private nfc: NFC,
    private passwordService: PasswordService,
    public platform: Platform,
    public printerService: PrinterService,
    private storageService: StorageService,
    private pagingService: PagingService,
    private popoverCtrl: PopoverController,
    private releaseResourceService: ReleaseResourceService,
    public tenantService: TenantService,
    public userService: UserService
  ) {
    this.logger = loggingService.getLogger("[PopoverComponent]");
    const methodName = "ctor";
    this.logger.entry(methodName);
   }

  ngOnInit () {
    this.user = this.userService.getCurrentUser();
    this.tenant = this.tenantService.tenant;
    this.printJob = this.navParams.get('dataPackage').printJob;
    this.errorParameters = this.navParams.get('dataPackage').errorParameters;
    this.menuType = this.navParams.get('dataPackage')['type'];
    this.logger.info('ngOnInit() menuType: ' + this.menuType);

    this.originalEvent = this.navParams.get('dataPackage')['originalEvent'];
    this.printJobs = this.navParams.get('dataPackage')['printJobs'];
    this.printerList = this.navParams.get('dataPackage')['printerList'];
    this.tenantNetworks = this.navParams.get('dataPackage')['networks'];
    this.resourceId = this.navParams.get('dataPackage')['resourceId'];
    this.storedNetworks = this.navParams.get('dataPackage')['storedNetworks'];
    this.selectedPrinter = this.navParams.get('dataPackage')['selectedPrinter'];
    this.lastUsedQueue = this.navParams.get('dataPackage')['lastUsedQueue'];
    this.scannedPrinters = this.navParams.get('dataPackage')['scannedPrinters'];
    this.securePrintLevel = this.navParams.get('dataPackage')['securePrintLevel'];
    this.securePrintMethods = this.navParams.get('dataPackage')['securePrintMethods'];
    this.nfcEnabled = this.navParams.get('dataPackage')['nfcEnabled'];
    this.releaseHistoryFilters = this.navParams.get('dataPackage')['releaseHistoryFilters'];

    if (this.menuType === 'select-printer-menu') {
      // if (this.selectedPrinter !== null) { this.selectedPrinter.options.isLastUsedPrinter = true; }
      if (this.printerList.length !== 0) { this.sortPrinters(); }
    }

    if (this.tenantNetworks) {
      // this.createFilterObjects(this.tenantNetworks);
    }

    if (this.menuType === 'deselectPrintJobs') {
      this.showDeselectPrintJobs = true;
    }

    if (this.menuType === 'airPrintPassword') {
      this.showAirPrintPasswordsList = true;
      this.getStorageKeyString();
      this.getGeneratedPassword();
    }

    if (this.menuType === 'securePrintEnabled') {
      this.showSecurePrintLevel = true;
    }

    if (this.menuType === 'onDeviceRelease') {
      this.showMustSignInAtPrinter = true;
    }

    if (this.menuType === 'unMatchedPrinter') {
      this.showUnmatchedPrinterMessage = true;
      this.printJobs = this.navParams.get('dataPackage');
    }

    if (this.menuType === 'securePrintMethods') {
      this.getUserSecurePrintMethods();
    }

    if (this.menuType === 'showScanningMethods') {
      this.showScanningMethods = true;
    }

    if (this.menuType === 'scanNfcReadyNotice') {
      this.showNfcScanningReady = true;
    }

    if (this.menuType === 'confirmPrinterWithTag') {
      this.confirmPrinterWithTag = true;
      this.PrinterSignId = this.navParams.get('dataPackage')['PrinterSignId'];
    }

    if (this.menuType === 'deviceVersionWarning') {
      this.showDeviceVersionWarning = true;
    }

    if (this.menuType === 'sendLogs') {
      this.showSendLogsDialog = true;
    }

    // if (this.menuType === 'releaseHistoryFilters') {
    //   console.log('this.relaseHistoryFilters', this.releaseHistoryFilters);
    //   this.showreleaseHistoryFilters = true;
    // }
  }

  // ############### RELEASE HISTORY FILTERS ###############################################################################
  // private toggleFilter(clickedFilter) {
  //   this.releaseHistoryFilters.forEach(filter => {
  //     filter['checked'] = false;
  //   });

  //   clickedFilter.checked = true;
  //   // this.viewCtrl.dismiss(this.releaseHistoryFilters);
  // }

  // ############### /RELEASE HISTORY FILTERS ###############################################################################

  // ############### SCANNING METHODS ###############################################################################
  public checkActiveSecureLevel(method): boolean {
    let disableButton: boolean = false;
    if (method === 'QR' && this.securePrintLevel === 'NFC') {
      disableButton = true;
    }
    return disableButton;
  }
  // ############### /SCANNING METHODS ###############################################################################

  // ############### SECURE PRINT METHODS ###############################################################################
  public getUserSecurePrintMethods(): void {
    this.logger.info('getUserSecurePrintMethods()');
    this.dialogService.showLoadingSpinnerDialog('getUserSecurePrintMethods()').subscribe(() => {
      this.userService.refreshCurrentUser(this.tenantService.tenant.links.currentUser)
      .pipe(takeUntil(this.getUserSubscription))
      .subscribe((user) => {
        this.dialogService.hideLoadingSpinnerDialog('PopoverComponent - getUserSecurePrintMethods()');
        this.user = user;
        this.getUserSubscription.next();
        this.getUserSubscription.complete();
        this.securePrintMethods =  this.user.userAppSettings.securePrintMethods;
        this.checkNfcAvailability();
        this.showSecurePrintMethods = true;
      });
    });
  }

  private checkNfcAvailability() {
    this.nfc.enabled().then(() => {
      this.nfcEnabled = true;
      this.setActiveMethods();
    }, (error) => {
      this.setActiveMethods();
    });
  }

  private setActiveMethods() {
    this.logger.info('setActiveMethods()');
    let possibleMethods: Array<string> = [];
    this.activeMethods = [];
    switch (this.securePrintLevel) {
      case null:
          possibleMethods = this.nfcEnabled ? ['QR', 'NFC'] : ['QR'];
          this.addMethod(possibleMethods);
        break;
      case 'QR':
          possibleMethods = this.nfcEnabled ? ['QR', 'NFC'] : ['QR'];
          this.addMethod(possibleMethods);
        break;
      case 'NFC':
          possibleMethods = this.nfcEnabled ? ['NFC'] : [];
          this.addMethod(possibleMethods);
        break;
      case 'ON_DEVICE_RELEASE':
          possibleMethods = ['ON_DEVICE_RELEASE'];
          this.addMethod(possibleMethods);
        break;
    }
  }

  private addMethod(activeMethods: Array<string>) {
    this.logger.info('addMethod()');
    activeMethods.forEach(method => {
      this.activeMethods.push({
        name: method,
        checked: this.securePrintMethods.indexOf(method) !== -1 ? true : false
      });
    });
  }

  public saveSecurePrintMethods(): void {
    this.logger.info('(click) saveSecurePrintMethods()');
    this.securePrintMethods = [];
    this.activeMethods.forEach(method => {
      if (method['checked']) {
        this.securePrintMethods.push(method['name']);
      }
    });
    this.userService.setSecurePrintMethods(this.securePrintMethods)
    .pipe(takeUntil(this.unSubscribeSetSecurePrintMethods))
    .subscribe((response) => {
      this.unSubscribeSetSecurePrintMethods.next();
      this.unSubscribeSetSecurePrintMethods.complete();
      this.popoverCtrl.dismiss();
    }, (error) => {
      this.dismiss(null);
      this.unSubscribeSetSecurePrintMethods.next();
      this.unSubscribeSetSecurePrintMethods.complete();
    });
  }

  // ############### PRINTER POPOVER ###############################################################################

  public sortPrinters(): void {
    this.logger.info('sortPrinters()');
    let printers: Array<IPrinter> = this.printerList;
    let selectedPrinter: IPrinter;
    let lastUsedQueue: IQueue;
    let sortedPrinters: Array<IPrinter> = [];

    function compare(a,b) {
      if (a.signId < b.signId) {
        return -1;
      }
      if (a.signId > b.signId) {
        return 1;
      }
      return 0;
    }

    printers.forEach(p => {
      p.queues.forEach(q => {
        if (q.isLastUsedQueue) {
          lastUsedQueue = q;
          selectedPrinter = p;
        }
      });
    });

    if (selectedPrinter && lastUsedQueue) {
      let selectedPrinterIndex = printers.map(function(e) { return e.links.self; }).indexOf(selectedPrinter.links.self);

      if (selectedPrinterIndex > -1) {
        printers.splice(selectedPrinterIndex, 1);
      }
    }

    sortedPrinters = printers.sort(compare);

    if (selectedPrinter && lastUsedQueue) {
      let queues: Array<IQueue> = selectedPrinter.queues;
      let lastUsedQueueIndex = queues.map(function(e) { return e.links.self; }).indexOf(lastUsedQueue.links.self);

      queues.splice(lastUsedQueueIndex, 1);
      queues.splice(0, 0, lastUsedQueue);
      sortedPrinters.splice(0, 0, selectedPrinter);
    }

    this.printerList = sortedPrinters;
  }

  public toggleFavoritePrinterQueue(printerQueue: IQueue, printer: IPrinter) {
    this.logger.info('toggleFavoritePrinterQueue()');
    printerQueue.isFavorite = !printerQueue.isFavorite;
    this.userService.changePrinterQueuePinStatus(printerQueue.links.self, printerQueue.isFavorite)
    .pipe(takeUntil(this.unSubscribeChangePrinterQueuePinStatus))
    .subscribe(() => {
      this.unSubscribeChangePrinterQueuePinStatus.next();
      this.unSubscribeChangePrinterQueuePinStatus.complete();
    }, (error) => {
      this.dismiss(null);
      this.unSubscribeChangePrinterQueuePinStatus.next();
      this.unSubscribeChangePrinterQueuePinStatus.complete();
    });
  }

  // ############### /PRINTER POPOVER ###############################################################################

  // ############### ITEM CLICK ###############################################################################

  public itemClick(target, printer, event) {
    this.logger.info('itemClick()');
    let eventPackage: any = {
      event: event,
      target: target,
      originalEvent: this.originalEvent
    };

    if (event === 'print') {
      eventPackage.printJobs = this.printJobs;
      eventPackage.printer = printer;
    } else if (event === 'printerInfo') {
      eventPackage.target = printer;
    }

    this.popoverCtrl.dismiss(eventPackage);
    this.printJob = null;
  }
  // ############### /ITEM CLICK ###############################################################################

  // ############### NETWORK FILTERS ###############################################################################

  public dismiss(returnData?, role?): void {
    this.logger.info('dismiss()');
    let eventPackage: any = {
      event: this.menuType
    };
    this.popoverCtrl.dismiss(returnData, role);
  }

  public clearAllFilters() {
    this.popoverCtrl.dismiss('clearAllFilters');
  }

  public showNetworkList(): void {
    this.popoverCtrl.dismiss('showNetworksModal');
  }

  public dismissSendLogsPopover(method: string, ID: number) {
    let sendLogsParameters: object = {
      method: method,
      ID: ID
    };

    this.popoverCtrl.dismiss(sendLogsParameters);
  }

  // ############### /NETWORK FILTERS #####################################################################################

  // ############### AIRPRINT PASSWORD LIST ###############################################################################

  public getGeneratedPassword(): void {
    this.logger.info('getGeneratedPassword()');
    this.storageService.getItemFromLocalStorage(this.storageKeyAirPrintPassword, 'PopoverComponent - getGeneratedPassword()')
    .pipe(takeUntil(this.unSubscribe))
    .subscribe((airPrintPassword: string) => {
      if (airPrintPassword) {
        this.generatedAirPrintPassword = airPrintPassword;
        this.unSubscribe.next();
        this.unSubscribe.complete();
      } else {
        this.generateAirPrintPassword();
      }
    }, () => {
      console.error('this.generatedAirPrintPassword', this.generatedAirPrintPassword);
      this.unSubscribe.next();
      this.unSubscribe.complete();
    });
  }

  public generateAirPrintPassword(): void {
    const methodName = 'generateAirPrintPassword() ';
    this.logger.info(methodName);
    this.passwordService.generateAirPrintPassword()
    .pipe(takeUntil(this.unSubscribe))
    .subscribe((response) => {
      this.storageService.addItemToLocalStorage(this.storageKeyAirPrintPassword, response.passwordEncoded)
      .pipe(takeUntil(this.unSubscribe))
      .subscribe(() => {
        this.generatedAirPrintPassword = response.passwordEncoded;
        this.unSubscribe.next();
        this.unSubscribe.complete();
      }, (error) => {
        this.logger.info(methodName + 'Save generated AirPrint Password, failed: ' + error);
        this.dismiss(null);
        this.unSubscribe.next();
        this.unSubscribe.complete();
      });
    }, (error) => {
      this.logger.info(methodName + 'Generate AirPrint Password Error: ' + error);
      this.unSubscribe.next();
      this.unSubscribe.complete();
    });
  }

  public getStorageKeyString(): void {
    this.logger.info('getStorageKeyString()');
    this.storageKeyAirPrintPassword = this.storageKeyAirPrintPrefix + '/' + this.storageKeyTenant + '/' + this.storageKeyUser;
  }

  public copyText(event) {
    this.clipboard.copy(this.generatedAirPrintPassword)
    .then((resolve: string) => {
      this.dialogService.showTranslatedMessageDialog("CopyToClipboard");
    },
    (reject: string) => {
      alert('Error: ' + reject);
    });
  }

  // ############### /AIRPRINT PASSWORD LIST ###############################################################################

  // ############### SEND LOGS TO SERVER ###############################################################################

  public sendLogsToServer() {
    // this.dismissSendLogsPopover('SendLogsToServer', 4710);
    this.showSendLogsDialog = false;
    this.showPromptForSupportID = true;
  }

  // ############### /SEND LOGS TO SERVER ###############################################################################
}

