<ion-content no-bounce padding-horizontal>
  <app-header
    [iconName]="'menu'"
    [title]="null"
    [menuToggleRight]="true"
    [showErrorMessageNetwork]="true">
  </app-header>
  <div class="error-network" *ngIf="!isInternetAccessAvailable">
    <span class="error-network-container">
      <i class="icon-warning"></i>
      <span>{{"NoAccessInternet" | translate}}</span>
    </span>
  </div>
  <h1 class="signin-title" *ngIf="!emailSignin && !recoveringPassword">
    {{"SignIn" | translate}}
  </h1>

  <div class="error-message-container mb20"  error-message-container *ngIf="errorMessage?.length > 0">
    {{errorMessage | translate}}
  </div>

  <ion-grid>
    <ion-row class="ion-align-items-center">
      <ion-col size="12" class="ion-text-center">
        <ion-button class="login-button mt20"
        *ngIf="!emailSignin && !recoveringPassword && !tenantDomainInput"
        type="button"
        fill="outline"
        expand="block"
        [disabled]="authWaiting"
        (click)="signIn('ms')">
          <span>
            <i>
              <svg class="icon-microsoft" width="24px" height="24px" viewBox="44 129 24 24" version="1.1"
              xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <g id="microsoft-logo" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"
                transform="translate(44.000000, 129.000000)">
                  <rect id="1" fill="#F65314" x="0" y="0" width="11.5121951" height="11.5121951"></rect>
                  <rect id="2" fill="#7CBB00" x="12.4878049" y="0" width="11.5121951" height="11.5121951"></rect>
                  <rect id="3" fill="#FFBB00" x="12.4878049" y="12.4878049" width="11.5121951"
                  height="11.5121951"></rect>
                  <rect id="4" fill="#00A1F1" x="0" y="12.4878049" width="11.5121951" height="11.5121951"></rect>
                </g>
              </svg>
            </i>
            <span class="button-text">
              {{"SignInWithWindows" | translate}}
            </span>
          </span>
          <ion-spinner name="lines" [hidden]="loginMethod !== 'ms'"></ion-spinner>
        </ion-button>

        <ion-button class="login-button"
        *ngIf="!emailSignin && !recoveringPassword && !tenantDomainInput"
        type="button"
        fill="outline"
        expand="block"
        [disabled]="authWaiting"
        (click)="signIn('google')">
          <span>
            <i>
              <svg class="icon-google" width="24px" height="24px" viewBox="0 0 23 24" version="1.1"
              xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="btn_google_light_normal_ios">
                  <g id="logo_googleg_48dp" transform="translate(0.709091, 0.709091)">
                    <path
                    d="M22.1301818,11.5475207 C22.1301818,10.7468926 22.0583306,9.97705785 21.9248925,9.23801652 L11.2909091,9.23801652 L11.2909091,13.6055455 L17.3674711,13.6055455 C17.1057273,15.0169091 16.3102314,16.212719 15.1144215,17.0133471 L15.1144215,19.8463389 L18.763438,19.8463389 C20.8984463,17.8806942 22.1301818,14.9861156 22.1301818,11.5475207 L22.1301818,11.5475207 Z"
                    id="Shape" fill="#4285F4" fill-rule="nonzero"></path>
                    <path
                    d="M11.2909091,22.5818182 C14.3394545,22.5818182 16.8953058,21.5707686 18.763438,19.8463389 L15.1144215,17.0133471 C14.1033719,17.6908016 12.8100496,18.0911156 11.2909091,18.0911156 C8.35014049,18.0911156 5.86100826,16.1049421 4.97313223,13.4361818 L1.20094215,13.4361818 L1.20094215,16.3615537 C3.05880992,20.0516281 6.87719008,22.5818182 11.2909091,22.5818182 L11.2909091,22.5818182 Z"
                    id="Shape" fill="#34A853" fill-rule="nonzero"></path>
                    <path
                    d="M4.97313223,13.4361818 C4.74731405,12.7587273 4.61900826,12.0350826 4.61900826,11.2909091 C4.61900826,10.5467355 4.74731405,9.82309091 4.97313223,9.14563636 L4.97313223,6.22026447 L1.20094215,6.22026447 C0.43623967,7.74453719 0,9.46896694 0,11.2909091 C0,13.1128512 0.43623967,14.837281 1.20094215,16.3615537 L4.97313223,13.4361818 L4.97313223,13.4361818 Z"
                    id="Shape" fill="#FBBC05" fill-rule="nonzero"></path>
                    <path
                    d="M11.2909091,4.49070247 C12.9486198,4.49070247 14.436967,5.06038016 15.6071156,6.17920662 L18.8455537,2.9407686 C16.8901735,1.11882645 14.3343223,0 11.2909091,0 C6.87719008,0 3.05880992,2.53019008 1.20094215,6.22026447 L4.97313223,9.14563636 C5.86100826,6.47687603 8.35014049,4.49070247 11.2909091,4.49070247 L11.2909091,4.49070247 Z"
                    id="Shape" fill="#EA4335" fill-rule="nonzero"></path>
                    <polygon id="Shape" points="0 0 22.5818182 0 22.5818182 22.5818182 0 22.5818182"></polygon>
                  </g>
                </g>
              </g>
            </svg>
          </i>
          <span class="button-text">
            {{"SignInWithGoogle" | translate}}
          </span>
          </span>
          <ion-spinner name="lines" [hidden]="loginMethod !== 'google'"></ion-spinner>
        </ion-button>

      <ion-button class="login-button"
        *ngIf="!emailSignin && !recoveringPassword && !tenantDomainInput"
        type="button"
        fill="clear"
        center
        expand="block"
        [disabled]="authWaiting"
        (click)="showInputForTenantDomain()">
        <span class="button-text">
          {{'AlternateSignIn' | translate}}
        </span>
      </ion-button>
      <!-- <ion-button class="login-button"
        *ngIf="!emailSignin && !recoveringPassword && !tenantDomainInput"
        type="button"
        fill="clear"
        center
        expand="block"
        [disabled]="authWaiting"
        (click)="testTenantExistance()">
        <span class="button-text">
          {{'TestTenant' | translate}}
        </span>
      </ion-button> -->
    </ion-col>
  </ion-row>
</ion-grid>

  <div *ngIf="showTenantDoesNotExist" class="text-center color-danger">
    {{'TenantDoesNotExist' | translate | stringular: 'Printix' : (userTenantName ? userTenantName : '') }}
  </div>
  <div *ngIf="showTenantCouldNotBeLoaded" class="text-center color-danger">
    <div>Error code: {{errorStatus}}</div>
    {{'TenantDoesNotExist' | translate | stringular: 'Printix' : (userTenantName ? userTenantName : '') }}
  </div>
  <div *ngIf="showTenantIsDeactivated" class="text-center color-danger">
    {{'TenantIsDeactivated' | translate | stringular: 'Printix' : (userTenantName ? userTenantName : '') }}
  </div>

  <form class="other-methods-form" action="" (ngSubmit)="checkUserTenant()" *ngIf="tenantDomainInput">
    <fieldset class="form-section">
      <div class="form-group form-group-lg tenant-domain-input">
        <span class="input-wrapper">
          <input class="form-control--inline width70"
            [ngClass]="{'loading': showTenantNameSpinner}"
            name="onelogin"
            type="text"
            (input)="hideErrorText($event)"
            [(ngModel)]="userTenantName"
            placeholder="{{'MyPrintixHome' | translate | stringular: 'Printix'}}"
            required
          />
          <ion-spinner class="input-spinner" name="lines" [hidden]="!showTenantNameSpinner"></ion-spinner>
        </span>
        <span class="printix-url ml10 mt5 width25">.printix.net</span>

        <button
          class="btn btn-primary width40 mt30"
          type="submit"

          block
          [disabled]="!userTenantName || showTenantDoesNotExist || showTenantIsDeactivated">
          {{"Ok" | translate}}
        </button>
      </div>
    </fieldset>
  </form>

  <a class="btn btn-block btn-link back-button"
    role="button"
    *ngIf="(tenantDomainInput)"
    (click)="errorMessage = null; userTenantName = null; tenantDomainInput = false; showTenantDoesNotExist = false; showTenantIsDeactivated = false;">
    {{"Back"| translate}}
  </a>

  <!-- <button style="width: 100%" class="login-button"
    (click)="showListOfOptions()"
    *ngIf="!emailSignin && !recoveringPassword && !alternativeOptions && !oneLoginTenantInput && !oktaTenantInput"
    type="button"
    block
    [disabled]="authWaiting"
    ion-button >
      {{'ContinueWithOtherMethods' | translate}}
    </button>  -->


  <!-- <button class="login-button"
    *ngIf="(alternativeOptions && !emailSignin)"
    type="button"
    ion-button
    block
    [disabled]="authWaiting"
    (click)="signIn('vertical')">
    <span>{{"SignInWithIdentityProvider"| translate |stringular: "Vertical"}}</span>
    <ion-spinner color="white" [hidden]="!authWaiting"></ion-spinner>
  </button>  -->

  <!-- <button class="login-button"
    *ngIf="(alternativeOptions && !emailSignin)"
    type="button"
    ion-button
    block
    [disabled]="authWaiting"
    (click)="showInputForTenant('oneLogin')">
    <i>
      <svg _ngcontent-c3="" class="signin-icon onelogin icon" xml:space="preserve" xmlns:xlink="http://www.w3.org/1999/xlink" enable-background="new 0 0 446.7 445.6" height="43px" id="Logomark_black" version="1.1" viewBox="0 0 446.7 445.6" width="43px" x="0px" xmlns="http://www.w3.org/2000/svg" y="0px">
        <g _ngcontent-c3="" class="ng-tns-c3-0">
          <path _ngcontent-c3="" class="ng-tns-c3-0" d="M223.3,111.8C161.9,111.8,112,161.6,112,223c0,61.5,49.8,111.3,111.3,111.3S334.6,284.5,334.6,223
          C334.6,161.6,284.8,111.8,223.3,111.8z" fill="#1C1F2A"></path>
          <path _ngcontent-c3="" class="ng-tns-c3-0" d="M239.9,262.4c0,2.1-1.1,3.2-3.2,3.2h-20c-2.1,0-3.2-1.1-3.2-3.2v-48.2h-15.3c-2.1,0-3.2-1.1-3.2-3.2v-20
          c0-2.1,1.1-3.2,3.2-3.2h39c2.1,0,2.6,1.1,2.6,2.6V262.4z" fill="#FFFFFF"></path>
        </g>
      </svg>
    </i>
    <span>{{"SignInWithIdentityProvider"| translate |stringular: "OneLogin"}}</span>
  </button>  -->

  <!-- <button class="login-button"
  *ngIf="(alternativeOptions && !emailSignin)"
  type="button"
  ion-button
  block
  [disabled]="authWaiting"
  (click)="showInputForTenant('okta')">
  <i>
    <svg _ngcontent-c3="" class="signin-icon okta icon mr10" height="41px" preserveAspectRatio="xMidYMid meet" version="1.0" viewBox="0 0 500.000000 169.000000" width="48px" xmlns="http://www.w3.org/2000/svg">
      <g _ngcontent-c3="" class="ng-tns-c3-1" fill="#24417e" stroke="none" transform="translate(0.000000,169.000000) scale(0.100000,-0.100000)">
      <path _ngcontent-c3="" class="ng-tns-c3-1" d="M1436 1668 c-14 -20 -16 -114 -16 -818 0 -704 2 -798 16 -818 14 -21
      22 -22 135 -22 66 0 129 5 140 10 18 10 19 23 19 205 0 127 4 203 12 220 13
      29 47 41 75 27 10 -6 115 -112 233 -236 l214 -226 171 0 c184 0 195 3 195 51
      0 23 -47 76 -256 291 -141 145 -262 277 -271 293 -10 22 -12 37 -5 55 6 14
      102 128 216 253 113 126 208 237 211 247 3 11 1 28 -5 40 -10 19 -20 20 -174
      20 l-164 0 -173 -187 c-96 -104 -178 -191 -184 -195 -18 -14 -52 -8 -68 10
      -15 17 -17 59 -17 394 0 350 -1 376 -18 391 -15 14 -41 17 -145 17 -119 0
      -127 -1 -141 -22z"></path>
      <path _ngcontent-c3="" class="ng-tns-c3-1" d="M2718 1679 c-17 -9 -18 -47 -18 -572 0 -622 1 -630 64 -755 88 -175
      239 -292 433 -336 118 -26 225 -20 244 16 14 26 -8 234 -27 266 -13 19 -23 22
      -84 22 -130 0 -236 64 -292 177 -21 44 -23 63 -26 231 -3 163 -2 186 14 203
      15 17 32 19 179 19 205 0 195 -8 195 157 0 160 9 153 -191 153 -214 0 -199
      -15 -199 211 0 229 7 219 -157 219 -65 0 -126 -5 -135 -11z"></path>
      <path _ngcontent-c3="" class="ng-tns-c3-1" d="M463 1241 c-228 -67 -394 -244 -447 -478 -20 -91 -20 -164 0 -256 83
      -372 464 -589 820 -467 246 85 424 334 424 595 0 202 -108 403 -277 519 -107
      73 -194 99 -338 103 -93 2 -134 -2 -182 -16z m295 -321 c67 -29 144 -112 167
      -179 26 -79 21 -180 -12 -245 -25 -48 -95 -116 -148 -144 -43 -23 -62 -27
      -135 -27 -73 0 -92 4 -136 27 -65 34 -137 114 -158 175 -72 211 91 432 309
      419 33 -2 84 -13 113 -26z"></path>
      <path _ngcontent-c3="" class="ng-tns-c3-1" d="M4015 1250 c-183 -37 -356 -168 -439 -332 -55 -111 -69 -182 -64
      -318 4 -98 9 -126 35 -192 96 -242 335 -408 589 -408 114 0 262 48 355 115
      l48 35 49 -49 c69 -69 139 -94 262 -95 76 -1 102 3 123 17 l27 17 0 134 c0 95
      -4 136 -12 144 -7 5 -39 12 -71 14 -68 6 -104 28 -132 84 -19 36 -20 64 -25
      432 -4 334 -7 394 -20 402 -8 5 -71 10 -141 10 -132 0 -149 -5 -149 -45 0 -33
      -9 -36 -52 -14 -97 50 -270 72 -383 49z m247 -332 c97 -45 160 -127 176 -232
      18 -116 -25 -230 -113 -298 -136 -103 -313 -86 -428 42 -54 60 -77 121 -77
      205 0 84 23 145 77 205 97 108 235 137 365 78z"></path>
      </g>
    </svg>
  </i>
  <span>{{"SignInWithIdentityProvider"| translate |stringular: "Okta"}}</span>
</button> -->

  <!-- <button class="login-button email"
    *ngIf="(alternativeOptions && !emailSignin)"
    type="button"
    ion-button
    block
    [disabled]="authWaiting"
    (click)="singInWithEmail()">
    <span>{{'SignInWithEmail' | translate}}</span>
    <ion-spinner color="white" [hidden]="!authWaiting"></ion-spinner>
  </button> -->

<!--
  <a class="btn btn-block btn-link"
    role="button"
    *ngIf="((alternativeOptions && !emailSignin) || (oneLoginTenantInput || oktaTenantInput))"
    (click)="emailSignin = false; errorMessage = null; alternativeOptions = false; authWaiting = false; oneLoginTenantInput = false; oktaTenantInput = false;">
    {{"ContinueWithOtherMethods"| translate}}
  </a> -->


  <!-- <form action="" *ngIf="emailSignin && !recoveringPassword">
      <fieldset class="form-section">
          <div class="form-group form-group-lg">

            <label class="control-label" for="emailInputField">
              {{ 'Email' | translate }}
            </label>

            <input class="form-control"
              name="username"
              type="email"
              [(ngModel)]="credentials.username"
              placeholder="{{'Email' | translate}}"
              required
              focus="true"/>
          </div>

          <div class="form-group form-group-lg" >
            <label class="control-label" for="passwordInputField">
              {{'Password'|translate}}
            </label>

            <input class="form-control"
              id="passwordInputField"
              type="password"
              name="password"
              [(ngModel)]="credentials.password"
              placeholder="{{'Password'|translate}}"
              required #password="ngModel"/>
          </div>

          <px-button-submit
          [waitingState]="buildInAuthWaiting"
          [readyStateText]="'SignIn'"
          [isDisabled]="!credentials.password || !credentials.username"
          (click)="submitEmailCredentials()">
          </px-button-submit>

          <div class="alert alert-danger text-center"
            role="alert"
            *ngIf="errorMessage">
            {{errorMessage| translate}}
          </div>

          <div class="form-group"> -->
            <!-- <a class="btn btn-block btn-link"
              role="button"
              (click)="recoverPassword()">
              {{"PasswordForgot"| translate}}
            </a> -->

            <!-- <a class="btn btn-block btn-link" role="button"  (click)="emailSignin = false; errorMessage = null; alternativeOptions = false;">
              {{"ContinueWithOtherMethods"| translate}}
            </a>
          </div>
      </fieldset>
  </form> -->
  <!-- <p>{{logData}}</p> -->

  <!-- <form [formGroup]="recoverEmailForm" *ngIf="!emailSignin && recoveringPassword">
    <fieldset class="form-section">
      <div class="form-group form-group-lg">

      <label for="" class="control-label">
        {{'Email' | translate}}
        <span class="help-block-inline ml10" *ngIf="errorMessages.email">
          {{ errorMessages.email | translate }}
        </span>
      </label>

      <input class="form-control"
        formControlName="email"
        [ngClass]="{'is-invalid': errorMessages.email, 'form-pulse': recoverEmailForm.get('email').pristine}"
        type="email"
        placeholder="{{'Email' | translate}}"
        focus="true"/>
        <i class="icon-ok is-valid mt20" *ngIf="recoverEmailForm.get('email').valid"></i>
      </div>

      <px-button-submit
        [waitingState]="buildInAuthWaiting"
        [readyStateText]="'Send'"
        [isDisabled]="!recoverEmailForm.get('email').valid"
        (click)="postRecoverEmail()">
      </px-button-submit>

      <div class="alert alert-danger text-center"
        role="alert"
        *ngIf="errorMessage">
        {{errorMessage| translate}}
      </div>

      <div *ngIf="emailSent">
        {{'EmailSent' | translate}}...
      </div>

      {{loginFailed}}
  </fieldset>
  </form> -->

  <!-- <ion-spinner  *ngIf="resolvingHttpRequest"></ion-spinner> -->
</ion-content>

