import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Logger, LoggingService } from 'ionic-logging-service';
import { Broadcaster } from 'src/app/services/events/broadcaster.class';
import { NetworkService } from 'src/app/services/network/network.service';

@Component({
  selector: 'app-error-network',
  templateUrl: './error-network.component.html',
  styleUrls: ['./error-network.component.scss'],
})
export class ErrorNetworkComponent implements OnInit {

  public isInternetAccessAvailable: boolean = true;
  private logger: Logger;

  constructor(
    private broadcaster: Broadcaster,
    private networkService: NetworkService,
    public platform: Platform,
    private loggingService: LoggingService,
    @Inject(ChangeDetectorRef) private changeDetectorRef: ChangeDetectorRef
  ) {
    this.logger = loggingService.getLogger("[ErrorNetworkComponent]");
    const methodName = "ctor";
    this.logger.entry(methodName);
  }

  private setListeners(): void {

    this.networkService.isInternetAvailable
    .subscribe((isInternetAccessAvailable: boolean) => {
    this.logger.info('isInternetAccessAvailable: ' + isInternetAccessAvailable);
    console.log('## IS_IT_AVAILABLE_FROM_NETWORK');
      if (isInternetAccessAvailable) {
        this.broadcaster.broadcast('update page');
      }
      this.isInternetAccessAvailable = isInternetAccessAvailable;
      this.changeDetectorRef.detectChanges();
    });
  }

  public reload() {
    location.reload();
  }

  ngOnInit(): void {
    this.setListeners();
  }
}
