import { inject, Injectable } from '@angular/core';
import { SpinnerDialog } from '@awesome-cordova-plugins/spinner-dialog/ngx';
import { AlertController, LoadingController, Platform } from '@ionic/angular';
import { Logger, LoggingService } from 'ionic-logging-service';
import { forkJoin, from, Observable, Subject, Subscriber } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { PlatformService } from '../platform/platform.service';
import { NetworkService } from '../network/network.service';
import { Broadcaster } from '../events/broadcaster.class';
import { StringularPipe } from '@app/pipes/stringular/stringular.pipe';
import { TranslateService, Translation } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  private loadingIndicator = null;
  public isShowingLoader = false;
  public isInternetAccessAvailable = false;
  private unSubscribe = new Subject<void>();
  public isCordova: boolean = this.platformService.isCordova;t
  private logger: Logger;

  constructor(
    private alertCtrl: AlertController,
    private broadcaster: Broadcaster,
    private loadingCtrl: LoadingController,
    private loggingService: LoggingService,
    private networkService: NetworkService,
    private platform: Platform,
    private platformService: PlatformService,
    private spinnerDialog: SpinnerDialog,
    private stringularPipe: StringularPipe,
    private translateService: TranslateService,
  ) {
    this.logger = this.loggingService.getLogger("[DialogService]");
    const methodName = "ctor";
    this.logger.entry(methodName);

    this.networkService.isInternetAvailable
    .subscribe((isInternetAccessAvailable: boolean) => {
      this.isInternetAccessAvailable = isInternetAccessAvailable;
    });
  }

  public showAlertDialog(message: string): void {
    this.logger.info('showAlertDialog()');
    let alertOkButtonText: string = '';
    this.translateService.get('Close').subscribe({
      next: (value: string) => {
        alertOkButtonText = value;
      },
      error: (err) => {
        this.logger.error(err);
      },
      complete: () => {
        this.alertCtrl.create({
          header: 'Printix App',
          subHeader: message,
          buttons: [alertOkButtonText]
        }).then(alert => alert.present());
      }
    });
  }

  public showTranslatedMessageDialog(message, stringular?: any): void {
    this.logger.info('showTranslatedMessageDialog()');
    console.log('stringular', stringular);
    const closeButtonText = 'Close';
    forkJoin({
      closeButtonText: this.translateService.get(closeButtonText),
      translatedMessage: this.translateService.get(message)
    }).subscribe({
      next: ({closeButtonText, translatedMessage}) => {
        console.log('## translatedMessage', translatedMessage);
        console.log('translatedMessage B4 args');
        const messageWithArgs = this.setStringularArgs(translatedMessage, [stringular]);
        console.log('translatedMessage After args', messageWithArgs);
        this.presentMessagePopup(closeButtonText, messageWithArgs);
      },
      error: (err) => {
        console.log('## ERR', err);
      },
      complete: () => {
        console.log('## COMPLETE');
      }
    })
  }

  setStringularArgs(message, args?: string[]) {
    return args ? this.stringularPipe.transform(message, ...args) : message;
  }

  private async presentMessagePopup(closeButtonText: Translation, translatedMessage: Translation) {
    this.logger.info('presentMessagePopup()');
    const alert = await this.alertCtrl.create({
      header: 'Printix App',
      subHeader: translatedMessage,
      buttons: [closeButtonText]
    });
    await alert.present();
  }

  public showConfirmDialog(message: string, accept: string, deny: string, title?: string): Observable<boolean> {
    this.logger.info('showConfirmDialog()');
    let confirmOkButtonText = '';
    let confirmCancelButtonText = '';
    let alertTitle = '';
    let alertMessage = '';
    return new Observable<boolean>((observer: Subscriber<boolean>) => {
    this.translateService.get([message, accept, deny, title])
    .pipe(takeUntil(this.unSubscribe))
    .subscribe( async (value: Array<string>) => {
      confirmOkButtonText = value['Yes'];
      confirmCancelButtonText = value['No'];
      alertTitle = value['Capture'];
      alertMessage = value['Cancel'];
      this.unSubscribe.next();
      this.unSubscribe.complete();
      const alert = await this.alertCtrl.create({
          header: title ? alertTitle : 'Printix App',
          cssClass: 'show-confirm-dialog',
          message: alertMessage,
          buttons: [
            {
              text: confirmCancelButtonText,
              role: 'cancel',
              handler: () => {
                observer.next(false);
                observer.complete();
              }
            },
            {
              text: confirmOkButtonText,
              handler: () => {
                observer.next(true);
                observer.complete();
              }
            }
          ]
        });
        await alert.present();
        const { role } = await alert.onDidDismiss();
      });
    });
  }

  public showLoadingSpinnerWithMessage(message: string, fromMethod?: string) {
    this.logger.info('showLoadingSpinnerWithText() ' + 'fromMethod: ' + fromMethod);
    this.spinnerDialog.show(null, message, true, {overlayOpacity: 0.3});
  }

  public async hideLoadingSpinnerDialogProcessImage(method?): Promise<void> {
    this.logger.info('hideLoadingSpinnerDialogProcessImage() from method: ' + method);
    this.spinnerDialog.hide();
  }

  public async showTemporaryLoader() {
    const loading = await this.loadingCtrl.create({
      cssClass: 'print-job-spinner',
      spinner: 'lines'
    });

    loading.present();
  }

  public async tempDismiss() {
    await this.loadingCtrl.dismiss();
  }

  public showLoadingSpinnerDialog(fromMethod?: string): Observable<any> {
    this.logger.info('showLoadingSpinnerDialog() ' + 'fromMethod: ' + fromMethod);

    if (!this.isInternetAccessAvailable) {
      console.log('## NO_INTERNET_ACCESS');
      return new Observable(observer => {
        observer.complete();
      });
    }

    return new Observable(observer => {
      if (this.isCordova) {
        if (this.platform.is('ipad')) {
          this.broadcaster.broadcast('ipad_spinner_show');
        }
        this.spinnerDialog.show(null, null, true, { overlayOpacity: 0 });
        observer.next();
        observer.complete();
      } else {
        if (!this.isShowingLoader) {
          this.isShowingLoader = true;
          from(this.loadingCtrl.create({
            cssClass: 'print-job-spinner',
            showBackdrop: false,
            spinner: 'lines'
          })).pipe(
            tap(loading => {
              this.loadingIndicator = loading;
              this.logger.info('PRESENTING_SPINNER - ' + 'fromMethod: ' + fromMethod);
              loading.present();
            })
          ).subscribe(() => {
            observer.next();
            observer.complete();
          });
        } else {
          observer.next();
          observer.complete();
        }
      }
    });
  }

  public async hideLoadingSpinnerDialog(method?): Promise<void> {
    this.logger.info('hideLoadingSpinnerDialog()');

    if (this.isCordova) {
      if (this.platform.is('ipad')) {
        this.broadcaster.broadcast('ipad_spinner_hide');
      }
      this.spinnerDialog.hide();
    } else {
      // Instead of directly closing the loader like below line
      // return await this.loadingController.dismiss();

      this.checkAndCloseLoader();

      // sometimes there's delay in finding the loader. so check if the loader is closed after one second. if not closed proceed to close again
      setTimeout(() => {
        this.checkAndCloseLoader(true)
        // setTimeout(() => {
        //   this.checkAndCloseLoader(true)
        // }, 500);
      }, 1000);
    }
  }

  private async checkAndCloseLoader(hadTimeOut?) {
    // Use getTop function to find the loader and dismiss only if loader is present.
    const loader = await this.loadingCtrl.getTop();
    // if loader present then dismiss

    if (loader) {
      await this.loadingCtrl.dismiss().then(() => {
        if (this.loadingIndicator) {
          this.loadingIndicator = null;
        }
      });
    } else {
      this.logger.info(' - No loadingIndicator to hide');
    }
    this.isShowingLoader = false;
  }

  // public hideSplashScreen(): void {
  //   this.logger.info('hideSplashScreen()');
  //   this.hideLoadingSpinnerDialog();
  //   // this.splashScreen.hide();
  //   this.platform.ready().then(() => {
  //   });
  // }

  // public showSplashScreen(origin?: string): void {
  //   this.logger.info('showSplashScreen()');
  //   // this.splashScreen.show();
  //   this.showLoadingSpinnerDialog(origin);
  // }
}
