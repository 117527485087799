import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Logger, LoggingService } from 'ionic-logging-service';
import { Observable, of, Subject, throwError } from 'rxjs';
import { catchError, delay, retry, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ErrorService } from '../error/error.service';
import { IPrintJob } from '../print-job/models/print-job.model';
import { IPrinter } from '../printer/models/printer.model';
import { IQueue } from '../printer/models/queue.model';
import { ReleaseResourceService } from '../release-resource/release-resource.service';
import { StringularPipe } from '../../pipes/stringular/stringular.pipe';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root'
})
export class ReleasePrintService {

  private unSubscribe = new Subject<void>();
  public releaseResource: any = null;
  private httpErrorResponse: HttpErrorResponse;
  private logger: Logger;

  constructor(
    private errorService: ErrorService,
    private httpClient: HttpClient,
    private loggingService: LoggingService,
    public releaseResourceService: ReleaseResourceService,
    private stringularPipe: StringularPipe,
    private toastController: ToastController,
    private translateService: TranslateService,
    public userService: UserService,
  ) {
    this.logger = this.loggingService.getLogger("[ReleasePrintService]");
    const methodName = "ctor";
    this.logger.entry(methodName);
  }

  public releaseSelectedPrintJobs(
    selectedPrinter: IPrinter,
    selectedPrintJobs: Array<IPrintJob>,
    selectedQueue: IQueue,
    startReleaseUrl: string
  ): Observable<Array<IPrintJob>> {
    this.logger.info('releaseSelectedPrintJobs()');

    if (!selectedPrinter) {
      throw new Error("PrintJobListService.releaseSelectedPrintJobs: printer is missing or it has errors");
    }

    if (!selectedPrintJobs || selectedPrintJobs.length === 0) {
      throw new Error("PrintJobListService.releaseSelectedPrintJobs: no selected print jobs");
    }

    return this.httpClient.post(startReleaseUrl, {}).pipe(
      retry({
        count: 2,
        delay: (error: HttpErrorResponse) => {
          this.httpErrorResponse = error;
          return of(error).pipe(delay(1000));
        }
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 || error.status === 403) {
          this.logger.info('releaseSelectedPrintJobs() httpErrorResponse === ' + error.status);
        } else {
          this.errorService.handleHttpClientResponseError(error, 'POST', '[ReleasePrintService] releaseSelectedPrintJobs()');
        }
        const err = new HttpErrorResponse(error);
        return  throwError(() => err);
      }),
      switchMap((response: any) => {
        this.logger.info('releaseSelectedPrintJobs() - release started without errors');
        let message: string;
        const selectedJobCount = selectedPrintJobs.length;

        if (selectedJobCount > 1) {
          message = this.translateMessageWithParameter('PrintDocs', [selectedJobCount.toString()]);
        } else if (selectedJobCount === 1) {
          message = this.translateMessageWithParameter('PrintDoc');
        }

        this.showToastForReleasePrint(message);

        if (selectedQueue !== null) {
          this.logger.info('releaseSelectedPrintJobs() - setting lastUsedPrinter: ' + selectedPrinter.links.self);
          return this.userService.setLastUsedPrinter(selectedPrinter).pipe(
            takeUntil(this.unSubscribe),
            switchMap(() => {
              this.logger.info('releaseSelectedPrintJobs() - setting lastUsedQueue: ' + selectedQueue.links.self);
              return this.userService.setLastUsedQueue(selectedQueue).pipe(
                takeUntil(this.unSubscribe),
                tap(() => {
                  this.unSubscribe.next();
                  this.unSubscribe.complete();
                }),
                switchMap(() => of(response))
              );
            })
          );
        } else {
          this.unSubscribe.next();
          this.unSubscribe.complete();
          return of(response);
        }
      })
    );
  }

 private translateMessageWithParameter(text: string, args?: string[]): string {
  this.logger.info('translateMessageWithParameter()');
  if (text) {
    this.translateService.get(text)
    .pipe(takeUntil(this.unSubscribe))
    .subscribe((value: string) => {
      text = value;
      this.unSubscribe.next();
      this.unSubscribe.complete();
    });
    if (args) {
      text = this.stringularPipe.transform(text, ...args);
    }
    return text;
  }
}

private showToastForReleasePrint(message): void {
  this.logger.info('showToastForReleasePrint()');
  let toast = this.toastController.create({
    message: message,
    duration: 3000,
    position: 'top'
  }).then(toast => toast.present());
}
}
