export const environment_test = {
  production: false,
  appType: 'IONIC_APP',
  appTitle: 'Printix App',
  appLink: 'printixapp://',
  appLogo: 'assets/img/logo/logo-img.png',
  mainDBName: 'data.mainDB',
  tableNameKeyValue: 'printixapp_key_value',
  endpointLogInResponse_WepApp: '?response_type=code&client_id=web_app&redirect_uri=https://',
  endpointLogInResponseForgotCode_WepApp: '&response_type=code&client_id=web_app&redirect_uri=https://',
  endpointLogInResponse_Native: '?response_type=code&client_id=web_app&redirect_uri=',
  endPointAuthorization: 'grant_type=authorization_code&client_id=web_app&client_secret=1234&redirect_uri=',

  // --------------------------- UnActive Endpoints ---------------------------------------------------- //

  // logoUrl: '/assets/img/logo/logo-img.png',
  // navParamLogOut: 'logout',
  // enumerationUrl: 'https://api.printix.net/v1/enumerations/PrinterType',

  /********************************************************************************************************************** */
  /** TEST */
  /********************************************************************************************************************** */
  appEndpoint: 'https://app.testenv.printix.net',
  checkServerHealth: 'https://api.testenv.printix.net/manage/health',
  internalTestWaitTime: 'https://api.testenv.printix.net/internal/testing/wait/',
  internalTestReturnSetStatusCode: 'https://api.testenv.printix.net/internal/testing/status/',
  endpointApi: 'https://api.testenv.printix.net',
  endpointCodeExchange: 'https://auth.testenv.printix.net/oauth/token',
  endpointLogOut: 'https://auth.testenv.printix.net/oauth/revoke?token=',
  endpointLogInWepAppTenant: 'https://auth.testenv.printix.net/oauth/authorize/tenant/',
  endpointLogInMS: 'https://auth.testenv.printix.net/identity-providers/azure/signin?redirect_uri=',
  endpointLogInGoogle: 'https://auth.testenv.printix.net/identity-providers/google/signin?redirect_uri=',
  endpointGetTenantUser: 'https://api.testenv.printix.net/v1/users/me',
  endpointFindTenant: 'https://api.testenv.printix.net/v1.2/find/',
  environmentState: 'testenv'

  // --------------------------- UnActive Endpoints ---------------------------------------------------- //

  // endpointRecoverPassword: 'https://auth.testenv.printix.net/recover/access',
  // endpointToken: 'https://auth.testenv.printix.net/oauth/token',
  // endpointError: 'https://api.testenv.printix.net',
  // endpointLogIn: 'https://auth.testenv.printix.net/oauth/authorize?response_type=code&client_id=hibrid_app&client_secret=thehouseisonfire&redirect_uri=',
  // endpointTenantList: 'https://api.testenv.printix.net/v1/tenants',
  // endpointLogInVertical: 'https://auth.testenv.printix.net/identity-providers/vertical/signin?redirect_uri=',
};