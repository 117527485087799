/** @angular */
import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { isUndefined } from '../../services/utils/utils';

@Pipe({
  name: 'stringular'
 })

@Injectable()
export class StringularPipe implements PipeTransform{
  public transform(template: string, ...args: string[]): string {
    return template.replace(/{(\d+)}/g, (match, number) => {
      return isUndefined(args[number]) ? match : args[number];
    });
  }
}