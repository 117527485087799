import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ReleaseHistoryPage } from './pages/release-history/release-history.page';
import { RegisterNfcTagPage } from './pages/register-nfc-tag/register-nfc-tag.page';
import { PrintJobsPage } from './pages/print-jobs/print-jobs.page';
import { PrinterListPage } from './pages/printer-list/printer-list.page';
import { GoPage } from './pages/go/go.page';
import { LoginPage } from './pages/auth/login/login.page';
import { NetworkCheckPage } from './pages/auth/network-check/network-check.page';
import { TenantSelectPage } from './pages/auth/tenant-select/tenant-select.page';
import { CaptureHistoryPage } from './pages/capture-history/capture-history.page';
import { CapturePage } from './pages/capture/capture.page';

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'network-check',
    pathMatch: 'full'
  },
  {
    path: 'release-history',
    component: ReleaseHistoryPage
    // loadChildren: () => import('./pages/release-history/release-history.module').then(m => m.ReleaseHistoryPageModule)
  },
  {
    path: 'register-nfc-tag',
    component: RegisterNfcTagPage
    // loadChildren: () => import('./pages/register-nfc-tag/register-nfc-tag.module').then(m => m.RegisterNfcTagPageModule)
  },
  {
    path: 'print-jobs',
    component: PrintJobsPage
    // loadChildren: () => import('./pages/print-jobs/print-jobs.module').then(m => m.PrintJobsPageModule)
  },
  {
    path: 'recents',
    component: PrintJobsPage
    // loadChildren: () => import('./pages/print-jobs/print-jobs.module').then(m => m.PrintJobsPageModule)
  },
  {
    path: 'printer-list',
    component: PrinterListPage
    // loadChildren: () => import('./pages/printer-list/printer-list.module').then(m => m.PrinterListPageModule)
  },
  {
    path: 'go',
    component: GoPage
    // loadChildren: () => import('./pages/go/go.module').then(m => m.GoPageModule)
  },
  {
    path: 'sign-in',
    component: LoginPage
    // loadChildren: () => import('./pages/auth/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'network-check',
    component: NetworkCheckPage
    // loadChildren: () => import('./pages/auth/network-check/network-check.module').then(m => m.NetworkCheckPageModule)
  },
  {
    path: 'tenant-select',
    component: TenantSelectPage
    // loadChildren: () => import('./pages/auth/tenant-select/tenant-select.module').then(m => m.TenantSelectPageModule)
  },
  {
    path: 'capture',
    component: CapturePage
    // loadChildren: () => import('./pages/capture-history/capture-history.module').then(m => m.CaptureHistoryPageModule)
  },
  {
    path: 'capture-history',
    component: CaptureHistoryPage
    // loadChildren: () => import('./pages/capture-history/capture-history.module').then(m => m.CaptureHistoryPageModule)
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }