<ion-content no-bounce padding-horizontal>
  <app-header
      [iconName]="'menu'"
      [title]="null"
      [menuToggleRight]="true"
      [showErrorMessageNetwork]="true">
    </app-header>
  <div class="error-network" *ngIf="!isInternetAccessAvailable">
    <span class="error-network-container">
      <i class="icon-warning"></i>
      <span>{{"NoAccessInternet" | translate}}</span>
    </span>
  </div>
  <ion-refresher (ionRefresh)="getTenantListForUser($event)" slot="fixed">
    <!-- <ion-refresher-content></ion-refresher-content> -->
  </ion-refresher>

<div class="page-header">
  <div class="page-title">
    {{'SelectTenantRedirect' | translate | stringular: 'Printix' }}
  </div>
</div>
<div class="empty-state" *ngIf="showDots">
  <div class="loader loader--sm loader--compact"><span></span></div>
</div>

<div class="tenant-list-container" *ngIf="tenantList && tenantList.length > 0">
  <div class="list-content-tenantselect width90 mb15" *ngFor="let tenant of tenantList | orderBy: 'tenantData.hostingDomain'">
    <div class="list-row list__item list__item--clickable">
      <button (click)="tenantSelectSuccessfull(tenant)" class="list-cell actionbutton text-center width100 p10 ">
        <span>{{tenant.tenantData.hostingDomain}}</span>
      </button>
    </div>
  </div>
</div>

  <div class="text-center" *ngIf="tenantList && tenantList.length <= 0">
  {{"None" | translate}}
  </div>
</ion-content>