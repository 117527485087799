import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Logger, LoggingService } from 'ionic-logging-service';
import { Observable, Subscriber } from 'rxjs';
import { delay, finalize, retryWhen, take, tap } from 'rxjs/operators';
import { ErrorService } from '../error/error.service';
import { IPrinterEmbedded } from '../printer/models/printer-embedded-model';
import { IPrinter } from '../printer/models/printer.model';
import { IQueue } from '../printer/models/queue.model';
import { TenantService } from '../tenant/tenant.service';
import { UserService } from '../user/user.service';
import { IRelease } from './models/releaseResource.model';

@Injectable({
  providedIn: 'root'
})
export class ReleaseResourceService {

  private printJobs: Array<string> = [];
  private printers: Array<string> = [];
  private queues: Array<string> = [];
  private networks: Array<string> = [];
  private RELEASE_PRINT_JOBS: string = 'RELEASE_PRINT_JOBS';
  private PRINTER: string = 'PRINTER';
  private QUEUE: string = 'QUEUE';
  private NETWORKS: string = 'NETWORKS';
  public releaseResource: any = null;
  private httpErrorResponse: HttpErrorResponse;
  private logger: Logger;

  constructor(
    private errorService: ErrorService,
    private httpClient: HttpClient,
    private loggingService: LoggingService,
    private tenantService: TenantService,
    private userService: UserService,
  ) {
    this.logger = loggingService.getLogger("[ReleaseResourceService]");
    const methodName = "ctor";
    this.logger.entry(methodName);
  }

  public getCreateReleaseObject() {
    let releaseObject: IRelease = {
      printer: null,
      queue: null,
      networks: []
    };
    return releaseObject;
  }

  private createPrintJobArray(resource: any, embedded: any): void {
    let releaseJob: any = null;

    if (embedded.releasejobs) {
      embedded.releasejobs.forEach(pj => {
        releaseJob = {
          jobId: pj._embedded.job._links.self.href,
          errors: pj.errors
        };
        resource.releaseJobs.push(releaseJob);
      });
    }
  }

  private deserializeUpdatedPrinter(input: any): IPrinter {
    let printer: IPrinter = {
      links: {
        self: null,
        registerNfcTag: null
      },
      colorSupport: input.colorSupport ? input.colorSupport : false,
      created: input.created ? input.created : null,
      duplexSupport: input.duplexSupport ? input.duplexSupport : false,
      embedded: input._embedded ? input._embedded : null,
      geoLocation: input.geoLocation ? input.geoLocation : null,
      ipAddress: input.ipAddress ? input.ipAddress : null,
      location: input.location ? input.location : null,
      macAddress: input.macAddress ? input.macAddress : null,
      name: input.name ? input.name : null,
      pdls: input.pdls ? input.pdls : null,
      serialNumber: input.serialNumber ? input.serialNumber : null,
      signId: input.signId ? input.signId : null,
      type: input.type ? input.type : null,
      version: input.version ? input.version : 0,
      network: null,
      queues: input._embedded ? this.formatQueuesArray(input._embedded) : [],
      options: {
        isAllPrintJobsSelected: false,
        allowToPrint: true,
        isSelectedToPrintOnIt: false,
        isFavorite: false
      }
    };
    if (input._links) {this.deserializeLinkObjects(printer, input._links);}
    if (input._embedded) {this.deserializeEmbeddedObjects(printer, input._embedded);}
    return printer;
  }

  private deserializeUpdatedQueue(resourceQueue: any): IQueue {
    let queue: IQueue = {
      links: {
        self: resourceQueue._links.self.href ? resourceQueue._links.self.href : null,
        printer: resourceQueue._links['px:printer'].href ? resourceQueue._links['px:printer'].href : null
      },
      active: resourceQueue.active ? resourceQueue.active : false,
      alwaysReprint: resourceQueue.alwaysReprint ? resourceQueue.alwaysReprint : false,
      autoInstall: resourceQueue.autoInstall ? resourceQueue.autoInstall : false,
      autoUpdate: resourceQueue.autoUpdate ? resourceQueue.autoUpdate : false,
      defaultDuplex: resourceQueue.defaultDuplex ? resourceQueue.defaultDuplex : false,
      defaultMono: resourceQueue.defaultMono ? resourceQueue.defaultMono : false,
      errors: [],
      global: resourceQueue.global ? resourceQueue.global : false,
      name: resourceQueue.name ? resourceQueue.name : null,
      signId: resourceQueue.signId ? resourceQueue.signId : null,
      groups: resourceQueue ? this.formateGroupsArray(resourceQueue) : [],
      isFavorite: false,
      isDisabled: false,
      isLastUsedQueue: false,
      printerDetails: {
        location: null,
        colorSupport: false,
        duplexSupport: false
      },
    };
    return queue;
  }

  private createPrinterQueueObject(resource: any, printerQueue: any) {
    if (printerQueue._embedded) {
      resource.printerQueue = {
        selectedPrinter: printerQueue._embedded.printer ? this.deserializeUpdatedPrinter(printerQueue._embedded.printer) : null,
        selectedQueue: printerQueue._embedded.queue ? this.deserializeUpdatedQueue(printerQueue._embedded.queue) : null,
        errors: printerQueue.errors
      };
    }
  }

  private deserializeCreatedResource(input: any): any {
    let releaseResource: any = {
      releaseJobs: [],
      printerId: input._embedded.printer ? input._embedded.printer._links.self.href : null,
      printerQueue: null,
      matchingQueues: input._links['px:matchingQueues'] ? input._links['px:matchingQueues'].href : [],
      resourceId: input._links.self.href,
      startRelease: input._links['px:startRelease'] ? input._links['px:startRelease'].href : null
    };
    if (input._embedded) {
      this.createPrintJobArray(releaseResource, input._embedded);
    } else {
      throw "ReleaseResource: input._embedded is missing";
    }
    if (input.printerQueue) {
      this.createPrinterQueueObject(releaseResource, input.printerQueue);
    }

    return releaseResource;
  }

  public createReleaseResource(releaseParameters: any): Observable<any> {
    this.logger.info('createReleaseResource()');
    let createResourceUrl: string = this.tenantService.tenant.links.self + '/releases?embed=' + this.RELEASE_PRINT_JOBS + '&embed=' + this.PRINTER + '&embed=' + this.QUEUE;
    return new Observable((observer) => {
      this.httpClient.post<any>(createResourceUrl, releaseParameters)
      .pipe(retryWhen(error => error.pipe(
        delay(1000),
        take(3),
        // return httpErrorResponse.status > 499 ? Observable.of(true) : Observable.throw(httpErrorResponse);
        tap((httpErrorResponse: HttpErrorResponse) => {this.httpErrorResponse = httpErrorResponse}),
        finalize(() => {
          if (this.httpErrorResponse.status === 401 || this.httpErrorResponse.status ===  403) {
            this.logger.info('createReleaseResource() httpErrorResponse === ' + this.httpErrorResponse.status);
          } else {
            this.errorService.handleHttpClientResponseError(this.httpErrorResponse, 'POST', '[ReleaseResourceService] createReleaseResource()');
          }
          observer.error(this.httpErrorResponse);
          observer.complete();
        })
      )))
      .subscribe((response: any) => {
        this.releaseResource = this.deserializeCreatedResource(response);
        observer.next(this.releaseResource);
        observer.complete();
      });
    });
  }

  public updatePrintJobsOnRelease(printJobUrlsToAdd: Array<string>, printerUrlToAdd: string, queueUrlToAdd: string, releaseId: string, releasePrinterSource?: string): Observable<any> {
    this.logger.info('updatePrintJobsOnRelease() printerUrlToAdd: ' + printerUrlToAdd);
    this.logger.info('updatePrintJobsOnRelease() queueUrlToAdd: ' + queueUrlToAdd);
    this.logger.info('updatePrintJobsOnRelease() printJobUrlsToAdd: ' + JSON.stringify(printJobUrlsToAdd));

    let releaseIdUrl = releaseId + '?embed=' + this.RELEASE_PRINT_JOBS + '&embed=' + this.PRINTER + '&embed=' + this.QUEUE;
    let objectToPatch: Array<any> = [];
    let printerSource: string = releasePrinterSource ? releasePrinterSource : 'CHOSEN';

    objectToPatch.push(
      {
        "op": "remove",
        "path": "/jobs"
      }
    );

    if (printJobUrlsToAdd.length > 0) {
      printJobUrlsToAdd.forEach((url) => {
        objectToPatch.push(
          {
            "op": "add",
            "path": "/jobs/-",
            "value": {
              "jobId": url
            }
          }
        );
      });
    }

    if (queueUrlToAdd && printerUrlToAdd) {

      objectToPatch.push(
        {
          "op": "remove",
          "path": "/printerQueue",
        },
        {
          "op": "add",
          "path": "/printerQueue",
          "value": {
            "printer": printerUrlToAdd,
            "printerqueue": queueUrlToAdd,
            "printerSource": printerSource
          }
        }
      );
    }

    return new Observable((observer) => {
      this.httpClient.patch(releaseIdUrl, objectToPatch)
      .pipe(retryWhen(error => error.pipe(
        delay(1000),
        take(1),
        tap((httpErrorResponse: HttpErrorResponse) => {this.httpErrorResponse = httpErrorResponse}),
        finalize(() => {
          this.logger.info('updatePrintJobsOnRelease() httpErrorResponse.status: ' + this.httpErrorResponse.status);
          if (this.httpErrorResponse.status === 401 || this.httpErrorResponse.status ===  403) {
            this.logger.info('updatePrintJobsOnRelease() httpErrorResponse === ' + this.httpErrorResponse.status);
          } else if (this.httpErrorResponse.status === 400 && this.httpErrorResponse.error.errorCode === 'PATCHING_FINISHED_RELEASE') {
            this.logger.info('updatePrintJobsOnRelease() httpErrorResponse === 400 && errorCode === PATCHING_FINISHED_RELEASE');
          } else if (this.httpErrorResponse.status === 422 && this.httpErrorResponse.error.errorCode === 'TOO_MANY_PRINT_JOBS_IN_RELEASE') {
            this.logger.info('updatePrintJobsOnRelease() httpErrorResponse === 422 && errorCode === TOO_MANY_PRINT_JOBS_IN_RELEASE');
          } else {
            this.logger.info('updatePrintJobsOnRelease() PATCH ERROR - Object to send: ' + JSON.stringify(objectToPatch));
            this.errorService.handleHttpClientResponseError(this.httpErrorResponse, 'PATCH', '[ReleaseResourceService] updatePrintJobsOnRelease()');
          }
          observer.error(this.httpErrorResponse);
          observer.complete();
        })
      )))
      .subscribe((response: any) => {
        this.releaseResource = this.deserializeCreatedResource(response);
        observer.next(this.releaseResource);
        observer.complete();
      });
    });
  }

  public getAvailableQueuesForRelease(releaseQueuesUrl: string, printJobUrls: Array<string>, printerId: string): Observable<any> {
    this.logger.info('getAvailableQueuesForRelease() releaseQueuesUrl: ' + releaseQueuesUrl);
    this.logger.info('getAvailableQueuesForRelease() printerId: ' + printerId);

    let availableQueuesObject: object = {
      page: 0,
      pageSize: 50,
      jobs: printJobUrls,
      printers: [printerId],
      sort: 'QUEUE_NAME',
      direction: 'DESC',
      scannedPrinter: printerId
    };

    return new Observable((observer) => {
      this.httpClient.post(releaseQueuesUrl, availableQueuesObject)
      .pipe(retryWhen(error => error.pipe(
        delay(1000),
        take(3),
        // return httpErrorResponse.status > 499 ? Observable.of(true) : Observable.throw(httpErrorResponse);
        tap((httpErrorResponse: HttpErrorResponse) => {this.httpErrorResponse = httpErrorResponse}),
        finalize(() => {
          if (this.httpErrorResponse.status === 401 || this.httpErrorResponse.status ===  403) {
            this.logger.info('getAvailableQueuesForRelease() httpErrorResponse === ' + this.httpErrorResponse.status);
          } else {
            this.errorService.handleHttpClientResponseError(this.httpErrorResponse, 'POST', '[ReleaseResourceService] getAvailableQueuesForRelease()');
          }
          observer.error(this.httpErrorResponse);
          observer.complete();
        })
      )))
      .subscribe((response: any) => {
        observer.next(this.deserializeAvailablePrinterQueues(response));
        observer.complete();
      });
    });
  }

  private deserializeAvailablePrinterQueues(input: any): Array<object> {
    let queues: Array<IQueue> = input._embedded ? this.formatAvailableQueuesArray(input._embedded['px:printerQueueResources']) : [];
    return queues;
  }

  private formatAvailableQueuesArray(printerQueueResources) {
    let queusArray: Array<IQueue> = [];
    if (printerQueueResources) {
      for (let i = 0; i < printerQueueResources.length; i++) {
        if(printerQueueResources[i]) {
          let resourceQueue: any = printerQueueResources[i];
          let queue: IQueue = {
            links: {
              self: resourceQueue._links.self.href ? resourceQueue._links.self.href : null,
              printer: resourceQueue._links['px:printer'].href ? resourceQueue._links['px:printer'].href : null
            },
            active: resourceQueue.active ? resourceQueue.active : false,
            alwaysReprint: resourceQueue.alwaysReprint ? resourceQueue.alwaysReprint : false,
            autoInstall: resourceQueue.autoInstall ? resourceQueue.autoInstall : false,
            autoUpdate: resourceQueue.autoUpdate ? resourceQueue.autoUpdate : false,
            defaultDuplex: resourceQueue.defaultDuplex ? resourceQueue.defaultDuplex : false,
            defaultMono: resourceQueue.defaultMono ? resourceQueue.defaultMono : false,
            errors: printerQueueResources[i].errors && printerQueueResources[i].errors.length > 0 ? printerQueueResources[i].errors : [],
            global: resourceQueue.global ? resourceQueue.global : false,
            name: resourceQueue.name ? resourceQueue.name : null,
            signId: resourceQueue.signId ? resourceQueue.signId : null,
            groups: resourceQueue ? this.formateGroupsArray(resourceQueue) : [],
            isFavorite: false,
            isDisabled: false,
            isLastUsedQueue: false,
            printerDetails: {
              location: null,
              colorSupport: false,
              duplexSupport: false
            },
          };
          queusArray.push(queue);
        }
      }
    }
    return queusArray;
  }

  public getAvailablePrintersList(matchingQueuesUrl: string, releaseQueues: any, page: number, pageSize: number, searchString: string, storedNetworks: Array<string>): Observable<any> {
    this.logger.info('getAvailablePrintersList() matchingQueuesUrl: ' + matchingQueuesUrl);

    let getResourceUrl = matchingQueuesUrl + "?query=true" ;

    let availablePrintersObject: object = {
      page: page,
      pageSize: pageSize,
      queueIds: [],
      networkIds: [],
      printerIds: [],
      embed: [
        this.PRINTER,
        this.QUEUE
      ]
    };

    if (searchString) {
      availablePrintersObject['match'] = searchString;
    }

    if (releaseQueues) {
      releaseQueues.forEach(rq => {
        availablePrintersObject['queueIds'].push(rq);
      });
    }

    if (storedNetworks.length > 0) {
      storedNetworks.forEach(nw => {
        availablePrintersObject['networkIds'].push(nw);
      });
    }

    return new Observable((observer) => {
      this.httpClient.post(getResourceUrl, availablePrintersObject)
      .pipe(retryWhen(error => error.pipe(
        delay(1000),
        take(3),
        // return httpErrorResponse.status > 499 ? Observable.of(true) : Observable.throw(httpErrorResponse);
        tap((httpErrorResponse: HttpErrorResponse) => {this.httpErrorResponse = httpErrorResponse}),
        finalize(() => {
          if (this.httpErrorResponse.status === 401 || this.httpErrorResponse.status ===  403) {
            this.logger.info('getAvailablePrintersList() httpErrorResponse === ' + this.httpErrorResponse.status);
          } else {
            this.errorService.handleHttpClientResponseError(this.httpErrorResponse, 'POST', '[ReleaseResourceService] getAvailablePrintersList()');
          }
          observer.error(this.httpErrorResponse);
          observer.complete();
        })
      )))
      .subscribe((response: any) => {
        observer.next(this.deserializeAvailablePrinters(response));
        observer.complete();
      });
    });
  }

  private deserializeAvailablePrinters(input: any): any {
    let availableResources: any = {
      printerIds: input._embedded ? this.createResourceArrays(input._embedded) : [],
      queues: input._embedded ? this.formatQueuesArray(input._embedded) : [],
      printers: input._embedded ? this.formatPrintersArray(input._embedded) : [],
      page: input.page ? input.page : null
    };
    return availableResources;
  }

  private formatPrintersArray(embedded): Array<IPrinter> {
    let printerArray: Array<IPrinter> = [];
    let matchingArray: Array<string> = [];
    let printerQueueResource = embedded['px:releasePrinterQueueResources'];
    if (printerQueueResource) {
      for (let i = 0; i < printerQueueResource.length; i++) {
        if(printerQueueResource[i]._embedded.printer) {
          let resourcePrinter: any = printerQueueResource[i]._embedded.printer;
          if (matchingArray.indexOf(resourcePrinter._links['self'].href) === -1) {
              matchingArray.push(resourcePrinter._links['self'].href);
              let printer: IPrinter = {
              links: {
                self: null,
                registerNfcTag: null
              },
              colorSupport: resourcePrinter.colorSupport ? resourcePrinter.colorSupport : false,
              created: resourcePrinter.created ? resourcePrinter.created : null,
              duplexSupport: resourcePrinter.duplexSupport ? resourcePrinter.duplexSupport : false,
              embedded: resourcePrinter._embedded ? resourcePrinter._embedded : null,
              geoLocation: resourcePrinter.geoLocation ? resourcePrinter.geoLocation : null,
              ipAddress: resourcePrinter.ipAddress ? resourcePrinter.ipAddress : null,
              location: resourcePrinter.location ? resourcePrinter.location : null,
              macAddress: resourcePrinter.macAddress ? resourcePrinter.macAddress : null,
              name: resourcePrinter.name ? resourcePrinter.name : null,
              pdls: resourcePrinter.pdls ? resourcePrinter.pdls : null,
              serialNumber: resourcePrinter.serialNumber ? resourcePrinter.serialNumber : null,
              signId: resourcePrinter.signId ? resourcePrinter.signId : null,
              type: resourcePrinter.type ? resourcePrinter.type : null,
              version: resourcePrinter.version ? resourcePrinter.version : 0,
              queues: this.formatQueuesArray(this.setQueues(printerQueueResource, resourcePrinter)),
              options: {
                isAllPrintJobsSelected: false,
                allowToPrint: true,
                isSelectedToPrintOnIt: false,
                isFavorite: false
              }
            };
            if (resourcePrinter._links) {this.deserializeLinkObjects(printer, resourcePrinter._links);}
            if (resourcePrinter._embedded) {this.deserializeEmbeddedObjects(printer, resourcePrinter._embedded);}
            printerArray.push(printer);
          }
        }
      }
    }
    return printerArray;
  }

   private setQueues(printerQueueResource: any, resourcePrinter: any): any {
    let queues: Array<IQueue> = [];
    printerQueueResource.forEach(pqr => {
      let queue = pqr._embedded.queue;
      if (queue._links['px:printer'].href === resourcePrinter._links.self.href) {
         queues.push(pqr);
      }
    });
    return queues;
  }

  public formatQueuesArray (embedded): Array<IQueue> {
    let queusArray: Array<IQueue> = [];
    let printerQueueResource = embedded['px:releasePrinterQueueResources'];
    !printerQueueResource ? printerQueueResource = embedded : [];
    if (printerQueueResource) {
      for (let i = 0; i < printerQueueResource.length; i++) {
        if(printerQueueResource[i]._embedded.queue) {
          let resourceQueue: any = printerQueueResource[i]._embedded.queue;
          let queue: IQueue = {
            links: {
              self: resourceQueue._links.self.href ? resourceQueue._links.self.href : null,
              printer: resourceQueue._links['px:printer'].href ? resourceQueue._links['px:printer'].href : null
            },
            active: resourceQueue.active ? resourceQueue.active : false,
            alwaysReprint: resourceQueue.alwaysReprint ? resourceQueue.alwaysReprint : false,
            autoInstall: resourceQueue.autoInstall ? resourceQueue.autoInstall : false,
            autoUpdate: resourceQueue.autoUpdate ? resourceQueue.autoUpdate : false,
            defaultDuplex: resourceQueue.defaultDuplex ? resourceQueue.defaultDuplex : false,
            defaultMono: resourceQueue.defaultMono ? resourceQueue.defaultMono : false,
            errors: printerQueueResource[i].errors && printerQueueResource[i].errors.length > 0 ? printerQueueResource[i].errors : [],
            global: resourceQueue.global ? resourceQueue.global : false,
            name: resourceQueue.name ? resourceQueue.name : null,
            signId: resourceQueue.signId ? resourceQueue.signId : null,
            groups: resourceQueue ? this.formateGroupsArray(resourceQueue) : [],
            isFavorite: false,
            isDisabled: false,
            isLastUsedQueue: false,
            printerDetails: {
              location: null,
              colorSupport: false,
              duplexSupport: false
            },
          };
          queusArray.push(queue);
        }
      }
    }
    return queusArray;
  }

  public formateGroupsArray (queue): Array<string> {
    let groupsArray: Array<string> = [];
    if (queue._links && queue._links['px:group']) {
      if (Array.isArray(queue._links['px:group'])) {
        for (let i = 0; i < queue._links['px:group'].length; i++) {
          groupsArray.push(queue._links['px:group'][i].href);
        }
      } else {
        groupsArray.push(queue._links['px:group'].href);
      }
    }
    return groupsArray;
  }

  private deserializeLinkObjects(printer: IPrinter, links: any): void {
    if (links) {
      if (links['self'] && links['self'].href) {
        printer.links.self = links['self'].href;
      }
    }
  }

  private deserializeEmbeddedObjects(printer: IPrinter, embedded: any): void {
    if (embedded) {
      let embeddedParameters: IPrinterEmbedded = {
        printerModel: embedded['printerModel'] ? embedded['printerModel'] : null,
        queues: embedded['queues'] ? embedded['queues'] : [],
        status: embedded['status'] ? {
          errors: embedded['status']['errors'] ? embedded['status']['errors'] : [],
          lastPollTime: embedded['status']['lastPollTime'] ? embedded['status']['lastPollTime'] : null,
          lastSuccessfullPoll: embedded['status']['lastSuccessfullPoll'] ? embedded['status']['lastSuccessfullPoll'] : null,
          lastUsagePollTime: embedded['status']['lastUsagePollTime'] ? embedded['status']['lastUsagePollTime'] : null,
          links: embedded['status']['_links'] ? {
            self: embedded['status']['_links']['self'] ? embedded['status']['_links']['self']['href'] : null,
            monitorWs: embedded['status']['_links']['px:monitorWs'] ? embedded['status']['_links']['px:monitorWs']['href'] : null,
          } : null,
          pollStatus: embedded['status']['pollStatus'] ? embedded['status']['pollStatus'] : null,
          printerReportedState: embedded['status']['printerReportedState'] ? embedded['status']['printerReportedState'] : null,
          tonerLevels: embedded['status']['tonerLevels'] ? embedded['status']['tonerLevels'] : null,
          state: embedded['status']['state'] ? embedded['status']['state'] : null,
          warnings: embedded['status']['warnings'] ? embedded['status']['warnings'] : [],
        } : null
      };
      printer.embedded = embeddedParameters;
    }
  }

  private createResourceArrays(embedded: any): Array<string> {
    let printerIds: Array<string> = [];
    if (embedded['px:releasePrinterQueueResources']) {
      embedded['px:releasePrinterQueueResources'].forEach(r => {
        if (printerIds.indexOf(r._links['px:printer'].href) === -1) {
          printerIds.push(r._links['px:printer'].href);
        }
      });
    }
    return printerIds;
  }
}