import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ITenant } from '../tenant/models/tenant.model';
import { IUser } from '../user/models/user.model';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { takeUntil } from 'rxjs/operators';
import { Logger, LoggingService } from 'ionic-logging-service';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  private unSubscribe = new Subject<void>();
  private logger: Logger;

  constructor(
    private device: Device,
    private loggingService: LoggingService,
    private httpClient: HttpClient
  ) {
  this.logger = loggingService.getLogger("[DeviceService]");
  const methodName = "ctor";
  this.logger.entry(methodName);
}

  public registerDeviceToPrintix(tenant: ITenant, user: IUser) {
    const methodName = 'registerDeviceToPrintix';
    this.logger.info(methodName);

    const registerDeviceUrl = tenant ? tenant.links.userDevices : undefined;

   if (user) {
    const deviceInfo = {
      deviceName: user.name,
      deviceString: this.device.uuid,
      deviceType: this.device.model,
      deviceOs: this.device.platform + ' ' + this.device.version
    };

    this.logger.info(methodName, 'deviceName: ' + deviceInfo.deviceName);
    this.logger.info(methodName, 'deviceString: ' + deviceInfo.deviceString);
    this.logger.info(methodName, 'deviceType: ' + deviceInfo.deviceType);
    this.logger.info(methodName, 'deviceOS: ' + deviceInfo.deviceOs);
  }

    // if (registerDeviceUrl) {
    //   this.httpClient.post(tenant.links.userDevices, deviceInfo)
    //   .pipe(takeUntil(this.unSubscribe))
    //   .subscribe((response) => {
    //       // console.log('deviceInfo response', response);
    //   }, (httpErrorResponse: HttpErrorResponse) => {
    //      this.logger.info('removeStorageData() registerDeviceToPrintix() httpErrorResponse: ' + httpErrorResponse.toString());
    //   });

    // }
  }
}