import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, Injector, OnInit } from '@angular/core';
import { Logger, LoggingService } from 'ionic-logging-service';
import { Observable, Subject, TimeoutError } from 'rxjs';
import { delay, finalize, retryWhen, take, takeUntil, tap } from 'rxjs/operators';
import { DialogService } from '../dialog/dialog.service';
import { EnvironmentService } from '../environment/environment.service';
import { Broadcaster } from '../events/broadcaster.class';
import { LogsService } from '../logs/logs.service';
import { NetworkService } from '../network/network.service';
import { PlatformService } from '../platform/platform.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorService implements OnInit {

  private isCordova: boolean = this.platformService.isCordova;
  private isInternetAccessAvailable = true;
  public stateError: Boolean = false;
  private timer: number = 0;
  private httpErrorResponse: HttpErrorResponse;
  private unSubscribe = new Subject<void>();
  private logger: Logger;
  private checkServerHealthUrl: string;
  private environmentService: EnvironmentService;

  constructor(
    private broadcaster: Broadcaster,
    private dialogService: DialogService,
    private httpClient: HttpClient,
    private injector: Injector,
    private loggingService: LoggingService,
    private logService: LogsService,
    private platformService: PlatformService,
    private networkService: NetworkService
  ) {
    this.logger = loggingService.getLogger("[ErrorService]");
    const methodName = "ctor";
    this.logger.entry(methodName);
  }

  ngOnInit(): void {

  }

  public canConnectToServer(): Observable<boolean> {
    this.logger.info('canConnectToServer()');
    return new Observable((observer) => {
      this.checkServerHealth()
      // this.errorService.returnSetStatusCode(internalTestReturnSetStatusCode, 500)
      .subscribe((response: any) => {
        observer.next(true);
        observer.complete();
      },(httpErrorResponse: HttpErrorResponse) => {
        observer.next(false);
        observer.complete();
      });
    });
  }

  public checkServerHealth(): Observable<any> {
    this.logger.info('checkServerHealth()');
    if (!this.environmentService) {
      this.environmentService = this.injector.get(EnvironmentService);
    }
    this.checkServerHealthUrl = this.environmentService.getEnvironmentEndpoint['checkServerHealth'];
    return new Observable((observer) => {
      this.httpClient.get<any>(this.checkServerHealthUrl).subscribe((response: any) => {
        observer.next(response);
        observer.complete();
      }, (httpErrorResponse: HttpErrorResponse) => {
        observer.next(httpErrorResponse);
        observer.complete();
      });
    });
  }

  public delayServerResponse(internalTestWaitTimeUrl: string, timeToWait: number): Observable<any> { // GET a url from PrintJobsPage, a service cannot get a variable from another service
    this.logger.info('delayServerResponse()');
    return new Observable((observer) => {
      this.dialogService.showLoadingSpinnerDialog('delayServerResponse()').subscribe(() => {
        let waitSetTimeUrl: string = internalTestWaitTimeUrl + timeToWait;
        this.httpClient.post<any>(waitSetTimeUrl, {})
        .subscribe((response: any) => {
          this.dialogService.hideLoadingSpinnerDialog('delayServerResponse()');
          observer.next(response);
          observer.complete();
        }, (httpErrorResponse: HttpErrorResponse) => {
          this.dialogService.hideLoadingSpinnerDialog('ERROR delayServerResponse()');
          this.handleHttpClientResponseError(httpErrorResponse, 'POST', 'delayedServerResponse');
          observer.error(httpErrorResponse);
          observer.complete();
        });
      });
    });
  }

  public returnSetStatusCode(internalTestReturnSetStatusCode: string, statusCode: number) {
    this.logger.info('returnSetStatusCode(' + statusCode + ')');
    return new Observable((observer) => {
      if (this.timer > 4) {
        statusCode = 200;
      }
      let returnSetStatusCodeUrl: string = internalTestReturnSetStatusCode + statusCode;
      this.httpClient.get<any>(returnSetStatusCodeUrl)
      .pipe(retryWhen(error => error.pipe(
        delay(1000),
        take(3),
        // return httpErrorResponse.status > 499 ? Observable.of(true) : Observable.throw(httpErrorResponse);
        tap((httpErrorResponse: HttpErrorResponse) => {this.httpErrorResponse = httpErrorResponse}),
        finalize(() => {
          this.handleHttpClientResponseError(this.httpErrorResponse, 'GET', '[ErrorService] returnSetStatusCode()');
          observer.error(this.httpErrorResponse);
          observer.complete();
        })
      )))
      .subscribe((response: any) => {
        this.timer = 0;
        observer.next(response);
        observer.complete();
      });
    });
  }

  public handleHttpClientResponseError(httpErrorResponse: HttpErrorResponse, requestMethod: string, calledFunction: string): void {
    if (this.isCordova) {
      // this.dialogService.hideSplashScreen();
    }
    this.logService.logErrorData(httpErrorResponse, requestMethod, calledFunction);

    this.networkService.isInternetAvailable
    .pipe(takeUntil(this.unSubscribe))
    .subscribe((isInternetAccessAvailable: boolean) => {
      this.unSubscribe.next();
      this.unSubscribe.complete();
      if (isInternetAccessAvailable) {
        if (httpErrorResponse instanceof TimeoutError) {
          this.handleTimeoutError(httpErrorResponse, requestMethod, calledFunction);
        } else {
          let errorStatus: number;

          errorStatus = httpErrorResponse.status ? httpErrorResponse.status : null;
          switch (true) {
            case errorStatus === 401 || errorStatus === 403: {
              break;
            }
            case errorStatus > 399 || errorStatus === 0: {
              this.broadcaster.broadcast('showErrorModal', ({
                httpErrorResponse: httpErrorResponse,
                requestMethod: requestMethod
              }));
            }
          }
        }
      }
    });
  }

  public handleTimeoutError(timeOutError: TimeoutError, requestMethod: string, calledFunction: string) {
    this.logger.error('handleTimeoutError() from: ' + calledFunction);
    this.broadcaster.broadcast('showErrorModal', ({
      httpErrorResponse: timeOutError['name'],
      requestMethod: requestMethod
    }));
  }

  public logOutUnAuthenticatedUser(logOutMessage) {
    this.logger.error('logOutUnAuthenticatedUser()');
    let navParams: {} = {};
    navParams['URL_PARAM_ERROR'] = logOutMessage;
    navParams['URL_PARAM_LOGOUT'] = false;
    navParams['URL_PARAM_UNAUTHENTICATED_USER'] = true;
    this.broadcaster.broadcast('logOutUser', navParams);
  }
}
