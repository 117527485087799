import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from '../storage/storage.service';
import { Platform } from '@ionic/angular';
import { ErrorService } from '../error/error.service';
import { Broadcaster } from '../events/broadcaster.class';
import { ILanguageItem } from './models/language.model';
import { delay, finalize, first, retryWhen, switchMap, take, tap } from 'rxjs/operators';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Logger, LoggingService } from 'ionic-logging-service';
import { PlatformService } from '../platform/platform.service';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  private storageKeyUserSelectedLanguage: string = 'userLanguage';
  private httpErrorResponse: HttpErrorResponse;
  private logger: Logger;
  public isCordova: boolean = this.platformService.isCordova;

  constructor(
    private translate: TranslateService,
    private storageService: StorageService,
    private loggingService: LoggingService,
    private platform: Platform,
    private platformService: PlatformService,
    private httpClient: HttpClient,
    private errorService: ErrorService,
    private broadCaster: Broadcaster
  ) {
    this.logger = this.loggingService.getLogger("[LanguageService]");
    const methodName = "ctor";
    this.logger.entry(methodName);
    this.setListeners();
   }

   private setListeners(): void {
    this.platform.ready().then(() => {
      if (this.isCordova) {
        this.storageService.getDatabaseState().subscribe((isReady: boolean) => {
          console.log('## isReady FROM LANGUAGE ##', isReady);
          if (isReady) {
            this.setTranslationData();
          }
        });
      } else {
        this.setTranslationData();
      }
    });
  }

  private setTranslationData(): void {
    this.logger.info('setTranslationData()');
    this.storageService.getItemFromLocalStorage(this.storageKeyUserSelectedLanguage, 'setTranslationData()')
    .pipe(
      first() // Automatically unsubscribe after the first value is received
    ).subscribe({
      next: (userSelectedLanguage: string | null) => {
        console.log('## SELECTED_LANGUAGE ##');
        console.log('userSelectedLanguage', userSelectedLanguage);
        if (userSelectedLanguage) {
          this.translate.use(userSelectedLanguage);
        } else {
          let browserLanguage = this.translate.getBrowserLang() || 'en';
          console.log('browserLanguage', browserLanguage);
          let languageKeyToUse: string = this.extractLanguageKey(browserLanguage);
          this.translate.use(languageKeyToUse);
        }
      },
      error: (e) => {
        this.logger.info('setTranslationData() ERROR: ' + JSON.stringify(e));
      },
      complete: () => { console.log('Retrieval complete.');}
    });
  }

  private extractLanguageKey(languageKey: string): string {
    this.logger.info('extractLanguageKey()');
    let languageKeyToUse: string = '';
    if (languageKey.split('_').length > 1) {
      languageKeyToUse = languageKey.split('_')[0];
    } else if (languageKey.split('-').length > 1) {
      languageKeyToUse = languageKey.split('-')[0];
      if (languageKeyToUse === 'zh') { // For chinese languages
        let chineseCode = (languageKey.split('-')[1]).toLowerCase();
        languageKeyToUse = languageKeyToUse + '-' + chineseCode;
      }
    } else {
      languageKeyToUse = languageKey;
    }
    return languageKeyToUse;
  }

  public getLanguages(): Observable<Array<ILanguageItem>> {
    this.logger.info('getLanguages()');
    let endpointLanguagesSupported = 'https://assets.printix.net/captions/Captions.json';
    return new Observable((observer) => {
      this.httpClient.get<any>(endpointLanguagesSupported)
      .pipe(retryWhen(error => error.pipe(
        delay(1000),
        take(3),
        // return httpErrorResponse.status > 499 ? Observable.of(true) : Observable.throw(httpErrorResponse);
        tap((httpErrorResponse: HttpErrorResponse) => {this.httpErrorResponse = httpErrorResponse}),
        finalize(() => {
          if (this.httpErrorResponse.status === 401 || this.httpErrorResponse.status ===  403) {
            this.logger.info('getLanguages() httpErrorResponse === ' + this.httpErrorResponse.status);
          } else {
            this.errorService.handleHttpClientResponseError(this.httpErrorResponse, 'GET', '[LanguageService] getLanguages()');
          }
          observer.error(this.httpErrorResponse);
          observer.complete();
        })
      )))
      .subscribe((languageItems: any) => {
        let languageItemsList: Array<ILanguageItem> = [];
        for (let languageItem of languageItems.Languages) {
          languageItemsList.push({
            codeLanguage: languageItem.LanguageCode,
            codeFlag: languageItem.LanguageCode,
            nameEnglish: languageItem.EnglishName,
            nameNative: languageItem.NativeName
          });
        }
        observer.next(languageItemsList);
        observer.complete();
      });
    });
  }

  public getCurrentLanguageCode(): string {
    return this.translate.currentLang;
  }

  public changeLanguage(languageKey: string): void {
    this.logger.info('changeLanguage(), languageKey: ' + languageKey);
    if (languageKey) {
      let languageKeyToUse: string = this.extractLanguageKey(languageKey);
      this.translate.use(languageKeyToUse);
      this.storageService.addItemToLocalStorage(this.storageKeyUserSelectedLanguage, languageKeyToUse, 'changeLanguage()')
      .subscribe(() => {
        this.translate.setDefaultLang(languageKeyToUse);
        this.broadCaster.broadcast('languageChange', languageKey); // Broadcast to change language dynamically for moment.js relative time library
      });
    }
  }
}

export function TranslateHttpLoaderFactory(http: HttpClient) {
  const endpointLanguages = 'https://assets.printix.net/captions/Caption_App_';
  return new TranslateHttpLoader(http, endpointLanguages, '.json');
}
