import { ChangeDetectorRef, Component, inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NavController, Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Broadcaster } from 'src/app/services/events/broadcaster.class';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit {

  private router = inject(Router);

  @Input() iconName: string;
  @Input() menuToggleRight: boolean;
  @Input() showErrorMessageNetwork: boolean;

  public showTitle: boolean = false;
  public currentTitle: string = 'Title';
  private route: string;
  public currentUrl: string;
  public back: any;
  public show_header = true;
  public platformResumeEventSubscription: Subscription = null;
  public isIos = this.platform.is('ios');
  public isAndroid = this.platform.is('android');


  constructor(
    private broadcaster: Broadcaster,
    private navController: NavController,
    private platform: Platform,
    private changeDetector: ChangeDetectorRef
  ) { }

  public getTitle() {
    let title;
    let urlTree = this.router.parseUrl(this.router.url);
    urlTree.queryParams = {}
    this.currentUrl = urlTree.toString();
    if (this.currentUrl) {
      switch (this.currentUrl) {
        case '/print-jobs': {
          title = 'Print';
          break;
        }
        case '/printer-list': {
          title = 'Printers';
          break;
        }
        case '/capture': {
          title = 'Capture';
           break;
        }
        case '/recents': {
          title = 'Recents';
          break;
        }
        case '/tenant-select': {
          title = 'ChangeHome';
          break;
        }
        case '/go': {
          title = 'CardsAndCodes';
          break;
        }
        case '/register-nfc-tag': {
          title = 'RegisterNfcTag';
          break;
        }
        case '/release-history': {
          title = 'PrintHistory';
          break;
        }
        case '/capture-history': {
          title = 'CaptureHistory';
          break;
        }
      }
    }
    return title;
  }

  public allowRouterLink() {
    let allowLink = true;
    if (this.currentUrl) {
      switch (this.currentUrl) {
        case '/tenant-select': {
          allowLink = false;
          break;
        }
        case '/sign-in': {
          allowLink = false;
          break;
        }
        default: {
          allowLink = true;
          break;
        }
      }
    }
    return allowLink;
  }

  ngOnInit(): void {
    this.platform.ready().then(() => {
      this.route = this.router.url;
      this.currentTitle = this.getTitle();
      this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
        // set page title based on route, after navigation ends
        this.route = event.urlAfterRedirects;
        this.currentTitle = this.getTitle();
      });
    });
  }
}

