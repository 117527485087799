import { NgModule, APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { IonicModule, IonicRouteStrategy, NavParams } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { MenuComponent } from './components/menu/menu.component';
import { ErrorNetworkComponent } from './components/error-network/error-network.component';
import { PopoverComponent } from './components/popover/popover.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { ModalComponent } from './components/modal/modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { HttpClient, HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { LanguageService, TranslateHttpLoaderFactory } from './services/language/language.service';
import { NetworkInterceptor } from './services/network/network.interceptor';
import { DefaultHeadersInterceptor } from './services/http/default-headers.interceptor';
import { AuthInterceptor } from './services/auth/auth.interceptor';
import { SecurePrintService } from './services/secure-print/secure-print.service';
import { Broadcaster } from './services/events/broadcaster.class';
import { ReleaseResourceService } from './services/release-resource/release-resource.service';
import { PrintJobService } from './services/print-job/print-job.service';
import { PagingService } from './services/paging/paging.service';
import { PrinterService } from './services/printer/printer.service';
import { TenantService } from './services/tenant/tenant.service';
import { UserService } from './services/user/user.service';
import { AuthService } from './services/auth/auth.service';
import { StorageService } from './services/storage/storage.service';
import { ErrorService } from './services/error/error.service';
import { IntuneMamService } from './services/intune-mam/intune-mam.service';
import { NetworkService } from './services/network/network.service';
import { DialogService } from './services/dialog/dialog.service';
import { Subject } from 'rxjs';
import { PasswordService } from './services/password/password.service';
import { Keyboard } from '@awesome-cordova-plugins/keyboard/ngx';
import { SQLite } from '@awesome-cordova-plugins/sqlite/ngx';
import { EventService } from './services/events/event.service';
import { Vibration } from '@awesome-cordova-plugins/vibration/ngx';
import { Deeplinks } from '@awesome-cordova-plugins/deeplinks/ngx';
import { QRScanner } from '@ionic-native/qr-scanner/ngx/';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { DeviceService } from './services/device/device.service';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { SafariViewController } from '@awesome-cordova-plugins/safari-view-controller/ngx'
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { VersionControlService } from './services/version-control/version-control.service';
import { ReleaseHistoryService } from './services/release-history/release-history.service';
import { SendLogsService } from './services/logs/send-logs.service';
import { HttpService } from './services/http/http.service';
import { LogoutService } from './services/logout/logout.service';
import { LoginService } from './services/login/login.service';
import { PlatformService } from './services/platform/platform.service';
import { ReleasePrintService } from './services/release-print/release-print.service';
import { NfcService } from './services/nfc/nfc.service';
import { NFC, Ndef } from '@awesome-cordova-plugins/nfc/ngx';
import { DatePipe } from '@angular/common';
import { EmailComposer } from '@awesome-cordova-plugins/email-composer/ngx';
import { HttpErrorModalComponent } from './components/http-error-modal/http-error-modal.component';
import { LogsService } from './services/logs/logs.service';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { IonicStorageModule } from '@ionic/storage-angular';
import { OrderModule } from 'ngx-order-pipe';
import { LoggingServiceModule, LoggingService } from "ionic-logging-service";
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { Clipboard } from '@awesome-cordova-plugins/clipboard/ngx';
import { SpinnerDialog } from '@awesome-cordova-plugins/spinner-dialog/ngx';
import { MomentModule } from 'ngx-moment';
import { EnvironmentService } from './services/environment/environment.service';
import { CaptureHistoryService } from './services/capture-history/capture-history.service';
import { CaptureService } from './services/capture/capture.service';
import { CaptureReviewService } from './services/capture/capture-review.service';
import { CaptureProcessorService } from './services/capture/capture-processor.service';
import { Camera } from '@awesome-cordova-plugins/camera/ngx';
import { Diagnostic } from '@awesome-cordova-plugins/diagnostic/ngx';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { Flashlight } from '@awesome-cordova-plugins/flashlight/ngx';
import { HeaderComponent } from './components/header/header.component';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { OrderByPipe } from './pipes/order-by/order-by.pipe';
import { PrintJobByPrinterPipe } from './pipes/print-job-by-printer/print-job-by-printer.pipe';
import { StringularPipe } from './pipes/stringular/stringular.pipe';
import { LoginPage } from './pages/auth/login/login.page';
import { NetworkCheckPage } from './pages/auth/network-check/network-check.page';
import { TenantSelectPage } from './pages/auth/tenant-select/tenant-select.page';
import { CapturePage } from './pages/capture/capture.page';
import { CaptureHistoryPage } from './pages/capture-history/capture-history.page';
import { GoPage } from './pages/go/go.page';
import { PrintJobsPage } from './pages/print-jobs/print-jobs.page';
import { PrinterListPage } from './pages/printer-list/printer-list.page';
import { RegisterNfcTagPage } from './pages/register-nfc-tag/register-nfc-tag.page';
import { ReleaseHistoryPage } from './pages/release-history/release-history.page';
import { configureLogging } from './logging-config/configure-logging-config';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    AppComponent,
    CapturePage,
    CaptureHistoryPage,
    ErrorNetworkComponent,
    GoPage,
    HeaderComponent,
    HttpErrorModalComponent,
    LoginPage,
    MenuComponent,
    ModalComponent,
    NetworkCheckPage,
    OrderByPipe,
    PopoverComponent,
    PrinterListPage,
    PrintJobByPrinterPipe,
    PrintJobsPage,
    RegisterNfcTagPage,
    ReleaseHistoryPage,
    SpinnerComponent,
    StringularPipe,
    TenantSelectPage
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    FormsModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    LoggingServiceModule,
    MomentModule,
    OrderModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: TranslateHttpLoaderFactory,
        deps: [HttpClient]
      }
    })
    // LocalStorageModule.forRoot({
    //   prefix: 'ls',
    //   storageType: 'localStorage'
    // }),
    // ServiceWorkerModule.register('ngsw-worker.js', {
    //   enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      //   registrationStrategy: 'registerWhenStable:30000'
    // })
  ],
  providers: [
    { provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NetworkInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DefaultHeadersInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => configureLogging,
      multi: true,
  },
    provideHttpClient(withInterceptorsFromDi()),
    AndroidPermissions,
    AuthService,
    Broadcaster,
    Camera,
    CaptureHistoryService,
    CaptureProcessorService,
    CaptureReviewService,
    CaptureService,
    Clipboard,
    DatePipe,
    Deeplinks,
    Device,
    DeviceService,
    Diagnostic,
    DialogService,
    EmailComposer,
    EnvironmentService,
    ErrorService,
    EventService,
    File,
    Flashlight,
    HttpService,
    InAppBrowser,
    IntuneMamService,
    Keyboard,
    LanguageService,
    LoggingService,
    LoginService,
    LogoutService,
    LogsService,
    NavParams,
    Ndef,
    Network,
    NetworkService,
    NFC,
    NfcService,
    OrderByPipe,
    PagingService,
    PasswordService,
    PlatformService,
    PrinterService,
    PrintJobByPrinterPipe,
    PrintJobService,
    QRScanner,
    ReleaseHistoryService,
    ReleasePrintService,
    ReleaseResourceService,
    SafariViewController,
    SecurePrintService,
    ScreenOrientation,
    SendLogsService,
    SQLite,
    SpinnerDialog,
    StatusBar,
    StorageService,
    StringularPipe,
    Subject,
    TenantService,
    TranslateHttpLoader,
    UserService,
    VersionControlService,
    Vibration
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }