import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';

import { AuthService } from './auth.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil, tap, timeout, mergeMap } from 'rxjs/operators';
import { PlatformService } from '../platform/platform.service';
import { Broadcaster } from '../events/broadcaster.class';
import { Logger, LoggingService } from 'ionic-logging-service';
import { EnvironmentService } from '../environment/environment.service';
import { setPxdSubdomainFromUrl } from '../environment/environment.pxd-dev';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  public authHeaderName: string = 'Authorization';
  public authHeaderTokenPrefix: string = 'Bearer ';
  private isCordova: boolean = this.platformService.isCordova;
  private logger: Logger;
  private unSubscribe = new Subject<void>();
  private endpointApi: string;
  private endpointFindTenant: string;
  private environmentService: EnvironmentService;
  private currentAPI: string;

  constructor(
    private broadcaster: Broadcaster,
    private injector: Injector,
    private loggingService: LoggingService,
    private platformService: PlatformService,
    private authService: AuthService,
    ) {
      this.logger = loggingService.getLogger("[AuthInterceptor]");
      const methodName = "ctor";
      this.logger.entry(methodName);
    }

  private isHttpInterceptorNeeded(httpRequest: HttpRequest<any>): boolean {
    if (httpRequest.url.includes('assets.printix.net')) {
      return false;
    } else {
      if (this.requestUrlHasEndpointApi(httpRequest.url)) {
        return true;
      }
      return false;
    }
  }

  private requestUrlHasEndpointApi(url: string): boolean {
    let hasApi = false;

    // Early return if the URL includes '/v1.2/find/'
    if (url.includes('/v1.2/find/')) {
      return hasApi;
    }

    if (url.includes('https://api')) {
      // Determine if this is a printix.dev or sandbox.printix.dev environment host
      // Don't use regex in order to include broadest platform support.
       const pxdSubdomain = setPxdSubdomainFromUrl(url);

      if (url.includes('https://api.printix.net')) {
        if (!this.currentAPI) {
          this.currentAPI = '### PROD_API ###';
          console.log(this.currentAPI);
        }
        hasApi = true;
      } else if (url.includes('https://api.testenv.printix.net')) {
        if (!this.currentAPI) {
          this.currentAPI = '### TEST_API ###';
          console.log(this.currentAPI);
        }
        hasApi = true;
      } else if (url.includes('https://api.devenv.printix.net')) {
        if (!this.currentAPI) {
          this.currentAPI = '### DEV_API ###';
          console.log(this.currentAPI);
        }
        hasApi = true;
      } else if (url.includes('https://api.us.printix.net')) {
        if (!this.currentAPI) {
          this.currentAPI = '### US_PROD_API ###';
          console.log(this.currentAPI);
        }
        hasApi = true;
      } else if (url.includes('https://api.testenv.us.printix.net')) {
        if (!this.currentAPI) {
          this.currentAPI = '### US_TEST_API ###';
          console.log(this.currentAPI);
        }
        hasApi = true;
      } else if (url.includes('https://api.devenv.us.printix.net')) {
        if (!this.currentAPI) {
          this.currentAPI = '### US_DEV_API ###';
          console.log(this.currentAPI);
        }
        hasApi = true;
      } else if (url.includes('https://api.' + pxdSubdomain)) {
        if (!this.currentAPI) {
          this.currentAPI = '### '+ pxdSubdomain.replace('.printix.dev','').toUpperCase() + '_API ###';
          console.log(this.currentAPI);
        }
        hasApi = true;
      }
    }
    return hasApi;
  }

  private isBrowserAuthenticated(): void {
    if (!this.isCordova) {
      this.authService.verifyUserCredentials()
      .pipe(takeUntil(this.unSubscribe))
      .subscribe(() => {
        this.unSubscribe.next();
        this.unSubscribe.complete();
      }, () => {
        location.reload();
      });
    }
  }

  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let newHttpRequest: HttpRequest<any> = httpRequest.clone();
    this.logger.info('intercept, newHttpRequest METHOD: ' + newHttpRequest.method);
    this.logger.info('intercept, newHttpRequest URL: ' + newHttpRequest.url);

    if (this.isHttpInterceptorNeeded(newHttpRequest) && !newHttpRequest.headers.has(this.authHeaderName)) { // Authorization token for requests to our server if none exists already
      this.isBrowserAuthenticated();
      const authService: AuthService = this.injector.get(AuthService);

      const token = new BehaviorSubject(null);
      return authService.tokenGetter(newHttpRequest.url)
      .pipe(mergeMap(newToken => { // set access token for request
        token.next(newToken);
        if (token) {
          newHttpRequest = newHttpRequest.clone({
            setHeaders: {
              'Authorization': 'Bearer ' + token.value
            }
          });
        }

        return next.handle(newHttpRequest).pipe(
          timeout(70000),
          tap((event: HttpEvent<any>) => {},
          (errorResponse: any) => {
            if (errorResponse.status === 401 || errorResponse.status === 403) { // handle forbidden/unAuthorized responses
              authService.refreshAccessToken()
              .pipe(takeUntil(this.unSubscribe))
              .subscribe((accessToken) => {
                const tokenLength = accessToken ? accessToken.length : '0';
                this.logger.info('httpErrorResponse access token refreshed - new accessToken.length: ' + tokenLength);
                if (accessToken) {
                  this.broadcaster.broadcast('access_token refreshed');
                  return next.handle(newHttpRequest).pipe(tap((event: HttpEvent<any>) => {}));
                } else {
                  this.logger.info('Missing accessToken after tokenRefresh');
                }
                this.unSubscribe.next();
                this.unSubscribe.complete();
              });
            }
          }
        ));
      }));
    } else {
      return next.handle(newHttpRequest);
    }
  }
}