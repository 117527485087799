import { Injectable, Injector, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Observable, Subject, Subscription } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { StorageService } from '../storage/storage.service';
import { TenantService } from '../tenant/tenant.service';
import { UserService } from '../user/user.service';
import { Broadcaster } from './broadcaster.class';
import * as _ from 'lodash';
import { first, takeUntil } from 'rxjs/operators';
import Pusher from 'pusher-js';
import { Router } from '@angular/router';
import { ITenantLite } from '../tenant/models/tenant-lite.model';
import { Logger, LoggingService } from 'ionic-logging-service';
import { EnvironmentService } from '../environment/environment.service';

export const CHANNELS = {
  ALL_TENANT_USERS: {
    CHANNEL_NAME: "TENANT_USER",
    EVENTS: {
      PRINTER_ADDED: "printer_added",
      PRINTER_REMOVED: "printer_removed",
      PRINTER_UPDATE: "printer_updated",
      PRINTER_STATUS_UPDATE: "printer_status_update",
      PRINTER_CREATION_UPDATE: "printer_creation_update",
      WORKSTATION_NETWORK_CHANGED: "workstation_network_changed",
      PRINT_QUEUE_ADDED: "print_queue_added",
      PRINT_QUEUE_REMOVED: "print_queue_removed",
      PRINT_QUEUE_UPDATED: "print_queue_updated",
      PRINT_QUEUE_GROUPS_UPDATED: "print_queue_groups_updated",
      TENANT_UPDATED: "tenant_updated",
      BILLING_DATA_UPDATED: "billing_data_updated",
      AUTH_CONFIG_UPDATED: "auth_config_updated",
      BATCH_EXECUTED: 'batch_executed',
      SECURE_PRINT_CONFIGURATION_ADDED: 'secure_print_configuration_added',
      SECURE_PRINT_CONFIGURATION_UPDATED: 'secure_print_configuration_updated',
      SECURE_PRINT_CONFIGURATION_REMOVED: 'secure_print_configuration_removed',
      WORKFLOW_CREATED: 'workflow_created',
      WORKFLOW_DELETED: 'workflow_deleted',
      WORKFLOW_UPDATED: 'workflow_updated'
    }
  },
  SPECIFIC_USER: {
    CHANNEL_NAME: "USER",
    EVENTS: {
      JOB_ADDED: "job_added",
      JOB_REMOVED: "job_removed",
      JOB_STATE_CHANGED: "job_state_changed",
      JOB_UPDATED: "job_updated",
      TASK_PROGRESS_USER: "task_progress_user",
      DISCOVERY_REPORT: "discovery_report",
      USER_RELEASE_CREATED: "release_created",
      USER_RELEASE_UPDATED: "release_updated",
      USER_RELEASE_DELETED: "release_deleted",
      USER_SCAN_CREATED: "scan_created",
      USER_SCAN_UPDATED: "scan_updated",
      USER_SCAN_DELETED: "scan_deleted",

    }
  }
};

@Injectable({
  providedIn: 'root'
})
export class EventService implements OnInit {

  private adSecurePrintSubscription: Subscription = null;
  private accessToken: string = null;
  private appTimer;
  private logger: Logger;
  private channels: any = _.cloneDeep(CHANNELS);
  private pusher: any;
  private pusherConfig: any = null;
  private removeSecurePrintSubscription: Subscription = null;
  private tenant: any = null;
  private tenantUpdateSubscription: Subscription = null;
  private unSubscribe = new Subject<void>();
  private unSubscribeUdateAccesssToken = new Subject<void>();
  private updateSecurePrintSubscription: Subscription = null;
  public user: any = null;
  private resumeEnabled = true;
  private currentUrl: string;

  public getTenantAndUserSubscription = new Subject<void>();

  private endpointApi: string;
  private environmentService: EnvironmentService;

  public EULA_ACCEPTED_STORAGE_STRING = 'eula-accepted';
  public EULA_ACCEPTED_STORAGE_KEY = 'is-eula-accepted';

  constructor(
    private authService: AuthService,
    private broadcaster: Broadcaster,
    private injector: Injector,
    private loggingService: LoggingService,
    private platform: Platform,
    private router: Router,
    private storageService: StorageService,
    private tenantService: TenantService,
    private userServce: UserService
  ) {
    this.logger = loggingService.getLogger("[EventService]");
    const methodName = "ctor";
    this.logger.entry(methodName);

    // let urlTree = this.router.parseUrl(this.router.url);
    // urlTree.queryParams = {}
    // this.currentUrl = urlTree.toString();
  }

  ngOnInit(): void {
    this.startListeners();
  }

  // if the pusherConnectionState is null (meaning not configured yet), or 'disconnected' a connection should be made
  public startEventService() {
      this.logger.info('startEventService()');
      this.storageService.getItemFromLocalStorage('lastUsedTenant')
      .pipe(
        first() // Automatically unsubscribe after the first value is received)
      )
      .subscribe({
        next: (tenant: ITenantLite) => {
          if (tenant) {
            this.tenant = tenant;
            this.logger.info('startEventService() TENANT IS MISSING!!');
            this.getAccessToken();
          } else {
            this.logger.info('startEventService() TENANT IS MISSING!!');
          }
          this.getTenantAndUserSubscription.next();
          this.getTenantAndUserSubscription.complete();
        },
        complete: () => {}
    });
  }

  private startListeners(): void {
    // if (!this.tenantUpdateSubscription) {
    //   this.tenantUpdateSubscription = this.broadcaster.on<string>(CHANNELS.ALL_TENANT_USERS.EVENTS.TENANT_UPDATED) // MOVE TO MENU COMONENT
    //   .subscribe(data => {
    //     console.log('**PUSHER tenant updated: eventService', data);
    //     this.logger.info('**PUSHER tenant updated');
    //     // this.refreshTenant().subscribe(() => {});
    //   });
    // }

    // if (!this.adSecurePrintSubscription) {
    //   this.adSecurePrintSubscription = this.broadcaster.on<string>(CHANNELS.ALL_TENANT_USERS.EVENTS.SECURE_PRINT_CONFIGURATION_ADDED) // MOVE TO MENU COMONENT
    //   .subscribe(data => {
    //     console.log('**PUSHER secure print configuration added: eventService', data);
    //     this.logger.info('**PUSHER secure print configuration added');
    //     // this.refreshTenant().subscribe(() => {});

    //   });
    // }

    if (!this.updateSecurePrintSubscription) {
      this.updateSecurePrintSubscription = this.broadcaster.on<string>(CHANNELS.ALL_TENANT_USERS.EVENTS.SECURE_PRINT_CONFIGURATION_UPDATED) // MOVE TO MENU COMONENT
      .subscribe(data => {
        console.log('**PUSHER secure print configuration updated: eventService', data);
        this.logger.info('**PUSHER secure print configuration updated');
        // this.refreshTenant().subscribe(() => {});

      });
    }

    if (!this.removeSecurePrintSubscription) {
      this.removeSecurePrintSubscription = this.broadcaster.on<string>(CHANNELS.ALL_TENANT_USERS.EVENTS.SECURE_PRINT_CONFIGURATION_REMOVED) // MOVE TO MENU COMONENT
      .subscribe(data => {
        console.log('**PUSHER secure print configuration removed: eventService', data);
        this.logger.info('**PUSHER secure print configuration removed');
        // this.refreshTenant().subscribe(() => {});

      });
    }

    this.broadcaster.on<string>('restartService').subscribe(() => {
      this.logger.info('service restarted: getAccessToken');
      console.log('## RESTART_SERVICE_EVENT_SERVICE getAccessToken()');
      this.getAccessToken();
    });

    this.broadcaster.on('DISABLE_RESUME').subscribe(() => {
      this.logger.info('DISABLE_RESUME');
      this.resumeEnabled = false;
    });

    this.broadcaster.on('ENABLE_RESUME').subscribe(() => {
      this.logger.info('ENABLE_RESUME');
      this.resumeEnabled = true;
    });

    this.platform.resume.subscribe(() => {
      if (this.resumeEnabled) {
        this.logger.info('platform resume');
        console.log('## PLATFORM_RESUME_EVENT_SERVICE getAccessToken()');
        if (this.hasAcceptedEula()) {
          this.getAccessToken();
        }
        this.broadcaster.broadcast('PLATFORM_RESUMED');
      }
    });
  }

  private hasAcceptedEula() {
    console.log('## HAS_ACCEPTED_EULA_EVENT_SERVICE ##');
    return this.storageService.getItemFromLocalStorage(this.EULA_ACCEPTED_STORAGE_KEY, 'deviceService - hasAcceptedEula()')
    .pipe(first())
    .subscribe((EULA_ACCEPTED) => {
      if (EULA_ACCEPTED === this.EULA_ACCEPTED_STORAGE_STRING) {
        return true;
      } else {
        return false;
      }
    });
  }

  public startTokenExpireCountDown() {
    this.logger.info('startTokenExpireCountDown()');
    clearTimeout(this.appTimer);
    this.appTimer = setTimeout(() => {
      this.logger.info('startTokenExpireCountDown() - REFRESH_FROM_COUNT_DOWN');
      this.broadcaster.broadcast('reload_page');
    }, 530000); // 530000
  }

  private getAccessToken() {
    console.log('## GET_ACCESS_TOKEN_EVENT_SERVICE');
    this.logger.info('getAccessToken()');
    this.authService.tokenGetter()
    .pipe(takeUntil(this.unSubscribe))
    .subscribe((token) => {
      this.accessToken = token;
      this.startTokenExpireCountDown();
      if (!this.pusherIsConnected()) {
        this.handlePusherConnection();
      }
      this.unSubscribe.next();
      this.unSubscribe.complete();
    });
  }

  private handlePusherConnection() {
    this.logger.info('handlePusherConnection()');
    this.unsubscribePusher();
    this.configurePusherObject();
    this.setChannels();
  }

  private getTenant(): void {
    this.tenant = this.tenantService.tenant;
  }

  private getUser(): void {
    this.user = this.userServce.user;
  }

  private configurePusherObject() {
    this.logger.info('configurePusherObject()');
    if (this.tenant) {
      this.logger.info('pusher_client_info_key: ' + this.tenant.embedded.PUSHER_CLIENT_INFO.key)
      if (!this.environmentService) {
        this.environmentService = this.injector.get(EnvironmentService);
      }
      this.endpointApi = this.environmentService.getEnvironmentEndpoint['endpointApi'];
      this.pusherConfig = {
        cluster: this.tenant.embedded.PUSHER_CLIENT_INFO.cluster,
        encrypted: true,
        authEndpoint: this.endpointApi + '/pusher/auth',
        auth: {
          headers: {
            Authorization: 'Bearer ' + this.accessToken
          }
        }
      };
      this.pusher = new Pusher(this.tenant.embedded.PUSHER_CLIENT_INFO.key, this.pusherConfig);
      this.pusher.logToConsole = true;
      // this.pusher.config.auth.headers.Authorization = 'Bearer ' + this.accessToken;
    } else {
      this.logger.info('configurePusherObject() - Tenant = null; no re-connect to PUSHER');
    }

  }

  private setChannels() {
    this.logger.info('setChannels()');
    // this.getTenant();
    if (this.tenant) {
      _.forEach(this.channels, (channel) => {
        this.pusher.subscribe(this.tenant.embedded.PUSHER_CLIENT_INFO.channels[channel.CHANNEL_NAME]); // Check channel names
        _.forEach(channel.EVENTS, (event) => {
          this.pusher.bind('pusher:subscription_error', ((status) => {
            this.logger.info('## mysterious PUSHER subscription status: ' + JSON.stringify(status));
          }));
          this.pusher.bind(event, (data) => {
            this.logPusherData(event, data);
            this.broadcaster.broadcast(event, data);
          }, (error) => { });
          this.pusher.connection.bind("state_change", (states) => {});
        });
      });
    }
  }

  private logPusherData(event, data): void {
    this.logger.info('pusher.bind() EVENT: ' + event);
    if (event === 'printer_status_update') {
      this.logger.info('pusher.bind() printer: ' + data['printer']);
      this.logger.info('pusher.bind() pollStatus: ' + data['pollStatus']);
    }
    if (event === 'job_added') {
      this.logger.info('pusher.bind() release: ' + data['job']);
    }
    if (event === 'release_created') {
      this.logger.info('pusher.bind() release created: ' + data['release']);
    }
  }

  private refreshTenant(): Observable<any> {
    return new Observable((observer) => {
      if (this.tenantService.tenant) {
        this.logger.info('refreshTenant()');
        this.tenantService.refreshCurrentTenant(this.tenantService.tenant.links.self)
          .pipe(takeUntil(this.unSubscribe))
          .subscribe((tenant) => {
            console.log('tenant', tenant);
            this.tenant = tenant;
            observer.next();
            observer.complete();
          });
      }
    });
  }

  public resetPusher() {
    this.logger.info('resetPusher()');
    this.logger.info('resetPusher() pusher: ' + this.pusher);
    this.logger.info('resetPusher() pusherConfig: ' + this.pusherConfig);
    clearTimeout(this.appTimer);
    this.unsubscribePusher();
    this.pusherConfig = null;
  }

  private unsubscribePusher() {
    this.logger.info('unsubscribePusher()');
    const pusherHasValue = this.pusher ? 'true' : 'false';
    this.logger.info('unsubscribePusher() - pusherHasValue: ' + pusherHasValue);
    if (this.pusher) {
      _.forEach(this.channels, (channel) => {
        this.pusher.unsubscribe(this.tenant.embedded.PUSHER_CLIENT_INFO.channels[channel.CHANNEL_NAME]); // Check channel names
      });
      const pusherConnectionState = this.pusher.connection.state;
      if (pusherConnectionState === 'connected' || pusherConnectionState === 'connecting') {
        console.log('## DISCONNECTING_PUSHER');
        this.pusher.disconnect();
      }
    }
  }

  public pusherIsConnected(): boolean {
    this.logger.info('pusherIsConnected()');
    let pusherIsConnected = false;
    const pusherConnectionState = this.pusher ? this.pusher.connection.state : null;
    this.logger.info('startEventService() - pusherConnectionState: ' + pusherConnectionState);
    if (pusherConnectionState === 'connected' || pusherConnectionState === 'connecting') {
      pusherIsConnected = true;
    }
    return pusherIsConnected;
  }
}