import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, inject, NgZone, OnDestroy, OnInit } from '@angular/core';
import { NFC, Ndef } from '@awesome-cordova-plugins/nfc/ngx';
import { QRScanner, QRScannerStatus } from '@ionic-native/qr-scanner/ngx';
import { Vibration } from '@awesome-cordova-plugins/vibration/ngx';
import { ModalController, NavController, NavParams, Platform, PopoverController } from '@ionic/angular';
import { Observable, Subject, Subscriber, Subscription } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { OrderPipe } from 'ngx-order-pipe';
import { DeviceService } from 'src/app/services/device/device.service';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { ErrorService } from 'src/app/services/error/error.service';
import { Broadcaster } from 'src/app/services/events/broadcaster.class';
import { CHANNELS, EventService } from 'src/app/services/events/event.service';
import { LogsService } from 'src/app/services/logs/logs.service';
import { NetworkService } from 'src/app/services/network/network.service';
import { NfcService } from 'src/app/services/nfc/nfc.service';
import { PagingService } from 'src/app/services/paging/paging.service';
import { PlatformService } from 'src/app/services/platform/platform.service';
import { IPrintJob } from 'src/app/services/print-job/models/print-job.model';
import { PrintJobService } from 'src/app/services/print-job/print-job.service';
import { IPrinter } from 'src/app/services/printer/models/printer.model';
import { IQueue } from 'src/app/services/printer/models/queue.model';
import { PrinterService } from 'src/app/services/printer/printer.service';
import { ReleasePrintService } from 'src/app/services/release-print/release-print.service';
import { ReleaseResourceService } from 'src/app/services/release-resource/release-resource.service';
import { SecurePrintService } from 'src/app/services/secure-print/secure-print.service';
import { ITenant } from 'src/app/services/tenant/models/tenant.model';
import { TenantService } from 'src/app/services/tenant/tenant.service';
import { IUser } from 'src/app/services/user/models/user.model';
import { isArray, isEmptyString } from 'src/app/services/utils/utils';
import * as _ from 'lodash';
import { PopoverComponent } from 'src/app/components/popover/popover.component';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { UserService } from 'src/app/services/user/user.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { ITenantLite } from 'src/app/services/tenant/models/tenant-lite.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Router } from '@angular/router';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { Logger, LoggingService } from 'ionic-logging-service';
import { OrderByPipe } from '@app/pipes/order-by/order-by.pipe';
import { PrintJobByPrinterPipe } from '@app/pipes/print-job-by-printer/print-job-by-printer.pipe';
import { Camera } from '@awesome-cordova-plugins/camera/ngx';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { LocaleService } from 'src/app/services/language/locale.service';
import { TranslateService } from '@ngx-translate/core';

export const SECURE_PRINT_LEVELS = {
  QR: 'QR_SCAN',
  NFC: 'NFC_SCAN',
  ON_DEVICE_RELEASE: 'ON_DEVICE_RELEASE'
};

@Component({
  selector: 'app-print-jobs',
  templateUrl: './print-jobs.page.html',
  styleUrls: ['./print-jobs.page.scss'],
})
export class PrintJobsPage implements OnInit, OnDestroy {

  // private router = inject(Router);

  public appIsActive = true;
  public batchExecutedSubscription: Subscription = null;
  public  cloudStorage: boolean = false;
  public componentSubscription = new Subject<void>();
  public  currentBaseUrl: string = null;
  public  currentLanguage: string = null;
  public  currentUrl: string = null;
  public  dataIsLoaded: boolean;
  public debouncerRunning: boolean = false;
  public debounceTimer;
  public  deletedPrintJobPrinterList: Array<IPrinter> = [];
  public  errorMsg: string;
  public  favoriteQueueUrls: Array<string> = [];
  public  gettingPrinter: boolean = false;
  public  invalidQRCode: boolean = false;
  public isCordova: boolean = this.platformService.isCordova;
  public  isInternetAccessAvailable = true;
  public  isSelected: boolean = false;
  private lastUsedTenant: ITenantLite = null
  private  tooManyPrintJobsInReleaseErrorParameters: string[] = [];
  public nav: any;
  public platformResumeEventSubscription: Subscription = null;
  public platformResumeBroadcastEventSubscription: Subscription = null;
  public platformIsIos: boolean = this.platform.is("ios");
  public platformPauseEventSubscription: Subscription = null;
  public reloadPageEventSubscription: Subscription = null;
  public tenantUpdatedEventSubscription: Subscription = null;
  public updateSecurePrintSubscription: Subscription = null;
  public isInternetAvailableSubscription: Subscription = null;
  public jobAddedSubscription: Subscription = null;
  public jobRemovedSubscription: Subscription = null;
  public jobUpdatedSubscription: Subscription = null;
  public releaseUpdatedSubscription: Subscription = null;
  public createdReleaseSubscription: Subscription = null;
  public lastUsedPrinterUrl: string = null;
  public languageChangeSubscription: Subscription = null;
  public  lastUsedAnywhereQueue: IQueue = null;
  private logger: Logger;
  public  networks: Array<string> = [];
  public nfcEnabled: boolean = false;
  private nfcReaderFlags = null;
  private nfcReaderMode = null;
  public nfcListenerSubscription: Subscription = null;
  public nfcReadyPopover: any;
  public nfcSubscription = new Subject<void>();
  public getTenantAndUserSubscription = new Subject<void>();
  public noProxiesForPrinter: boolean = false;
  public patchErrorCount: number = 0;
  public  pinnedPrinterUrls: any = null;
  public  printAnywhere: boolean = null;
  public  printerAccessError: boolean = false;
  public printerInErrorState: boolean = false;
  public  printJobsPageType = this.getPageType();
  public  printerList: Array<IPrinter> = [];
  public  printerOffline: boolean = false;
  public  printerQueue: IPrinter = null;
  public printerRemovedSubscription: Subscription = null;
  public printerStatusSubscription: Subscription = null;
  public printerUpdatedSubscription: Subscription = null;
  public printQueueRemovedSubscription: Subscription = null;
  public printQueueUpdatedSubscription: Subscription = null;
  public printQueueGroupsUpdatedSubscription: Subscription = null;
  public  printerUrlString: string;
  public  printJobList: Array<IPrintJob> = [];
  public  printJobPrinterLinks: Array<any> = [];
  public printJobsToAdd: Array<string> = [];
  public refresher: any = null;
  public releaseNotPossible: boolean = false;
  public  releaseFailed: boolean = false;
  public  releaseResource: any = null;
  public scannedNfcId: string = null;
  public scannedPrinter: IPrinter = null;
  public scannedQRUrl: string = null;
  public scannedSignId: string = null;
  public scanning: boolean = false;
  public scanningMethod: string = null;
  public  securePrinting: boolean = false;
  public securePrintLevel: string = null;
  public securePrintMethods: Array<string> = [];
  public  selectedPrinterParameters: any;
  public  selectedPrintJobs: Array<IPrintJob> = [];
  public selectedPrinter: IPrinter = null;
  public selectedQueue: IQueue = null;
  public selectAllClicked: boolean = false;
  public showTransparentBody: boolean = false;
  public  signInMethods: {};
  public  storedNetworkFilters: Array<any> = [];
  public  storedNetworkFiltersUrl: Array<string> = [];
  public  successMsg: string;
  public  tenant: ITenant = null;
  public  tenantNetworks: any;
  public  unavailablePrintJobs: boolean = false;
  public unSubscribe = new Subject<void>();
  public unSubscribeQRScanner = new Subject<void>();
  public unSubscribeRemovePinnedPrinters = new Subject<void>();
  public unSubscribeSetAvailablePinnedPrinterQueues = new Subject<void>();
  public unSubscribeInternetAvailability = new Subject<void>();
  public unSubscribeMatchingQueues = new Subject<void>();
  public unSubscribeChangePrinterQueuePinStatus = new Subject<void>();
  public  user: IUser = null;
  private paramSubscription: any;
  private errorModal: any = null;

  constructor(
      private translateService: TranslateService,
    private authService: AuthService,
    private broadcaster: Broadcaster,
	  private androidPermissions: AndroidPermissions,
	  private camera: Camera,
    private deviceService: DeviceService,
    private dialogService: DialogService,
    private errorService: ErrorService,
    private eventService: EventService,
    private file: File,
    private httpClient: HttpClient,
    private localeService: LocaleService,
    private logsService: LogsService,
    private loggingService: LoggingService,
    private modalCtrl: ModalController,
    public  navCtrl: NavController,
    private navParams: NavParams,
    private ndef: Ndef,
    private networkService: NetworkService,
    private nfc: NFC,
    private nfcService: NfcService,
    private orderPipe: OrderPipe,
    private orderByPipe: OrderByPipe,
    private pagingService: PagingService,
    public platform: Platform,
    private platformService: PlatformService,
    public  popoverCtrl: PopoverController,
    private printerService: PrinterService,
    private printJobByPrinterPipe: PrintJobByPrinterPipe,
    private printJobService: PrintJobService,
    private qrScanner: QRScanner,
    private releasePrintService: ReleasePrintService,
    private releaseResourceService: ReleaseResourceService,
    private router: Router,
    private securePrintService: SecurePrintService,
    private storageService: StorageService,
    private tenantService: TenantService,
    public  userService: UserService,
    private vibration: Vibration,
    private changeDetectorRef: ChangeDetectorRef,
    private zone: NgZone
  ) {
    // super(userService, navController);
    this.platform.ready().then(() => {
      this.logger = loggingService.getLogger("[" + this.printJobsPageType + "]");
      const methodName = "ctor";
      this.logger.entry(methodName);

      this.signInMethods = {
        LOCAL_AD: 'AD',
        AZURE: 'Microsoft',
        EMAIL: 'Email',
        GOOGLE: 'Google',
        OKTA: 'Okta',
        ONELOGIN: 'OneLogin',
        VERTICAL: 'Vertical',
        client_credentials: 'API',
        KIOSK: 'Kiosk',
        OIDC: 'OIDC'
      };

      if (this.platform.is('android') && this.currentUrl === '/print-jobs') {
        this.platform.backButton.subscribeWithPriority(-1, () => {
          // let newPageType = this.getPageType();
          // console.log('## Back button clicked');
          // if (newPageType === 'PrintJobsPage') {
          //   navigator['app'].exitApp();
          // }
        });
      }
      // console.log('this.platform.is("android")', this.platform.is("android"));
      // console.log('this.platform.is("ios")', this.platform.is("ios"));
      // console.log('this.platform.is("hybrid")', this.platform.is("hybrid"));
      // console.log('this.platform.is("cordova")', this.platform.is("cordova"));
      // console.log('this.platform.is("desktop")', this.platform.is("desktop"));
      // console.log('this.platform.is("mobile")', this.platform.is("mobile"));
      // console.log('IS_IT_CORDOVA', this.isCordova);
    });
  }


  private setPreventNFCListener() {
    this.logger.info('settingNFCListener()');
    this.nfcReaderFlags = this.nfc.FLAG_READER_NFC_A | this.nfc.FLAG_READER_NFC_V;

    this.nfc.readerMode(this.nfcReaderFlags).subscribe(
        (tag) => {
            console.log(tag);
        },
        (err) => {
            console.log('Error reading tag', err);
        }
    );
  }

  public filteredPrintJobs(printJobList, printerID) {
    let sortedPrintJobByPrinter: Array<IPrintJob>;
    sortedPrintJobByPrinter = this.printJobByPrinterPipe.transform(printJobList, printerID);
    return sortedPrintJobByPrinter;
  }

  private getPageType() {
    let urlTree = this.router.parseUrl(this.router.url);
    urlTree.queryParams = {}
    this.currentUrl = urlTree.toString();
    let pageType = null;
    if (this.currentUrl === '/recents') {
      pageType = 'RecentPrintJobsPage';
    } else if (this.currentUrl === '/print-jobs') {
      pageType = 'PrintJobsPage';
    }
    return pageType;
  }

  private isCurrentUrlThisPage() {
    let isThisPage = false;
    let currentUrl = null;
    let urlTree = this.router.parseUrl(this.router.url);
    urlTree.queryParams = {}
    currentUrl = urlTree.toString();
    // if (currentUrl && (currentUrl === '/recents' || currentUrl === '/print-jobs')) {
    if (currentUrl === this.currentUrl) {
      isThisPage = true;
    };
    return isThisPage;
  }

  ngOnInit(): void {
    this.platform.ready().then(() => {
      const methodName = 'ngOnInit() ';
      this.logger.info(methodName);

      // this.eventService.startEventService();
      // this.paramSubscription = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      //   console.log('## ON_NAVIGATION_END');
      //   let urlTree = this.router.parseUrl(this.router.url);
      //   urlTree.queryParams = {}
      //   this.currentUrl = urlTree.toString();
      //   console.log('currentUrl', this.currentUrl);
      //   // this.printJobsPageType =  this.currentUrl === '/recents'? 'RecentPrintJobsPage' : 'PrintJobsPage';
      // });

      let pinnedPrinterUrls = this.user && this.user.userAppSettings.pinnedPrinters ? this.user.userAppSettings.pinnedPrinters : [];
      this.favoriteQueueUrls = this.user && this.user.userAppSettings.pinnedPrinterQueues ? this.user.userAppSettings.pinnedPrinterQueues : [];

      this.logger.info(methodName + 'favoriteQueuesCount: ' + this.favoriteQueueUrls.length);

      if (pinnedPrinterUrls.length !== 0) { // old code to get rid of pinned(favorite printers) in the code. may be removed when we are certain, no more users have pinned printers in their user app settings
        this.removePinnedPrinters(pinnedPrinterUrls);
      }

      if (this.favoriteQueueUrls.length > 0) { // prevent deleted queues to exist in favorites queues list
        this.removeUnavailablePrinterQueues();
      }

      if (this.isCordova) {
        this.deviceService.registerDeviceToPrintix(this.tenantService.tenant, this.user);
      }
    });
  }

  private removePinnedPrinters(pinnedPrinterUrls) {
    this.logger.info('removePinnedPrinters() pinnedPrinterUrls.length > 0');
    this.userService.changePrinterPinStatus(pinnedPrinterUrls, false)
      .pipe(takeUntil(this.unSubscribeRemovePinnedPrinters))
      .subscribe(() => {
        this.unSubscribeRemovePinnedPrinters.next();
        this.unSubscribeRemovePinnedPrinters.complete();
    });
  }

  private removeUnavailablePrinterQueues() {
    const methodName = 'ngOnInit()';
    this.logger.info('removeUnavailablePrinterQueues() pinnedPrinterUrls.length > 0');
    let queueParameters = this.pagingService.getQueueParameters(0, 500, [], null, this.favoriteQueueUrls);
    queueParameters.printerTypes = ['NETWORK', 'VIRTUAL'];
    this.printerService.getPrinterQueuesList(queueParameters)
    .pipe(takeUntil(this.unSubscribeSetAvailablePinnedPrinterQueues))
    .subscribe((queues: Array<IQueue>) => {
      queues.pop()['page'];
      queues.pop()['printers'];
      if (queues.length === 0) {
         // reset favorite queues on userAppSettings
         this.favoriteQueueUrls = [];
         this.userService.setAvailablePinnedPrinterQueues(this.favoriteQueueUrls)
         .pipe(takeUntil(this.unSubscribeSetAvailablePinnedPrinterQueues))
         .subscribe(() => {
           this.userService.refreshCurrentUser(this.tenant.links.currentUser)
           .pipe(takeUntil(this.unSubscribeSetAvailablePinnedPrinterQueues))
           .subscribe((user) => {
              this.user = user;
              this.favoriteQueueUrls = this.user.userAppSettings.pinnedPrinterQueues;
              this.unSubscribeSetAvailablePinnedPrinterQueues.next();
              this.unSubscribeSetAvailablePinnedPrinterQueues.complete();
           });
         });
      } else {
        // set only the availble queues from the favorites list (remove unavailable queues)
        const availableQueues = [];
        queues.forEach(q => {
          availableQueues.push(q.links.self);
        });
        this.userService.setAvailablePinnedPrinterQueues(availableQueues)
         .pipe(takeUntil(this.unSubscribeSetAvailablePinnedPrinterQueues))
         .subscribe(() => {
            this.userService.refreshCurrentUser(this.tenant.links.currentUser)
            .pipe(takeUntil(this.unSubscribeSetAvailablePinnedPrinterQueues))
            .subscribe((user) => {
              this.user = user;
              this.favoriteQueueUrls = this.user.userAppSettings.pinnedPrinterQueues;
              this.unSubscribeSetAvailablePinnedPrinterQueues.next();
              this.unSubscribeSetAvailablePinnedPrinterQueues.complete();
          });
        });
      }
    });
  }

  // ############### REFRESH ###############################################################################

  public refreshPrintJobList(refresher?): void {
    this.logger.info('refreshPrintJobList()');
    this.printJobsPageType = this.getPageType();
    this.refresher = refresher;


    if (!this.isInternetAccessAvailable && this.refresher) { // prevent refresher from getting stuck, when internet connection is out
      this.refresher.target.complete();
    }
    this.dialogService.showLoadingSpinnerDialog('refreshPrintJobList()').subscribe(() => {
      this.eventService.startEventService();
      this.dataIsLoaded = false; // to prevent empty favoritePrinters popover


      // Get Tenant and User every time the page is refreshed
      this.tenantService.refreshCurrentTenant(this.lastUsedTenant.links.self)
      .pipe(takeUntil(this.getTenantAndUserSubscription))
      .subscribe((tenant) => {
        this.userService.refreshCurrentUser(tenant.links.currentUser)
        .pipe(takeUntil(this.getTenantAndUserSubscription))
        .subscribe((user) => {
          this.user = user;
          this.tenant = tenant;
          this.getTenantAndUserSubscription.next();
          this.getTenantAndUserSubscription.complete();

          this.lastUsedPrinterUrl = this.user && this.user.userAppSettings.lastUsedPrinter ? this.user.userAppSettings.lastUsedPrinter : null;
          this.favoriteQueueUrls = this.user && this.user.userAppSettings.pinnedPrinterQueues ? this.user.userAppSettings.pinnedPrinterQueues : [];
          this.printerUrlString = this.pagingService.getUrlString(0, 500);
          if (this.user && this.user.embedded.user.language) {
            this.currentLanguage = this.localeService.formatLocaleString(this.user.embedded.user.language);
          }
          this.securePrintMethods = this.user && this.user.userAppSettings.securePrintMethods ? this.user.userAppSettings.securePrintMethods : [];
          this.initiatePage();
        });
      });
    });


  }

  // ############### /REFRESH ###############################################################################

  // ############### INIT ###############################################################################

  private initiatePage() {
    this.logger.info('initiatePage()');
    this.securePrintService.getUserSecurityLevel()
    .pipe(takeUntil(this.unSubscribe))
    .subscribe((securePrintLevel) => { // security level
      this.securePrintLevel = securePrintLevel;
      this.broadcaster.broadcast('securePrintLevel', this.securePrintLevel);
      this.broadcaster.broadcast('printJobsPageType', this.printJobsPageType);
      this.securePrintService.setSecurePrintMethods(this.user)
      .pipe(takeUntil(this.unSubscribe))
      .subscribe((securePrintMethods) => { // set secure print methods
        this.securePrintMethods = securePrintMethods;
        this.handleNfcAvailability();
        this.printerService.getSinglePrinter(this.lastUsedPrinterUrl, this.printerUrlString) // get last used printer
        .pipe(takeUntil(this.unSubscribe))
        .subscribe((lastUsedPrinter) => {
          if (lastUsedPrinter.length > 1) {
            this.logsService.logLastUsedPrinter(lastUsedPrinter[0], this.printJobsPageType);
            this.selectedPrinter = lastUsedPrinter[0];
            this.checkForLastUsedQueue(); // set lastUsedAnywhereQueue/selectedPrinter
          } else {
            this.selectedPrinter = null;
          }
          this.logger.info('getPrintJobList()');
          this.printJobService.getPrintJobList()
          .pipe(takeUntil(this.unSubscribe))
          .subscribe((printJobList: Array<IPrintJob>) => {
            this.printJobList = [];
            this.printJobList = this.printJobsPageType === 'PrintJobsPage' ? printJobList.filter(printJob => !printJob.deletedTime) : printJobList.filter(printJob => printJob.deletedTime); // filter printJobs on deleted time, showing either printJobs or recentPrintJobs
            // console.log('this.printJobList ON REFRESH', this.printJobList);
            this.printerService.getPrintersWithPrintJobs(this.printJobList, this.printerUrlString)
            .pipe(takeUntil(this.unSubscribe))
            .subscribe((printerList) => {
              this.printerList = printerList;
              this.setPrinterLocation();
              if (!this.hasValidSelectedPrintJobs()) { // HAS VALID SELECTED PJs
                this.selectedPrintJobs = [];
                this.resetPrintersAllowedToPrintProperty();
                if (this.scanning && this.scanningMethod === 'QR_SCAN') {
                  this.cancelQRScanner();
                }
              }
              this.dataIsLoaded = true;
              if (this.refresher) {
                this.refresher.target.complete();
              }

              this.unSubscribe.next();
              this.unSubscribe.complete();
              this.logger.info('About to create release on page refresh');
              this.createRelease(null, false);
            }, () => {this.unSubscribe.complete(); this.dialogService.hideLoadingSpinnerDialog('refreshPrintJobList - ERROR getPrintersWithPrintJobs');});
          }, () => {this.unSubscribe.complete(); this.dialogService.hideLoadingSpinnerDialog('refreshPrintJobList - ERROR getPrintJobList');});
        }, () => {this.unSubscribe.complete(); this.dialogService.hideLoadingSpinnerDialog('refreshPrintJobList - ERROR getLastUsedPrinter (singlePrinter)');});
      });
    });
  }

  private handleNfcAvailability(): void {
    if (!this.nfcEnabled) {
      this.logger.info('remove NFC from secure print methods, if it is not enabled on the device');
      let nfcIndex: number = this.securePrintMethods.indexOf('NFC');
      if (nfcIndex !== -1) {
        this.securePrintMethods.splice(nfcIndex, 1);
      }
    }
  }

  private hasValidSelectedPrintJobs = function(): boolean {
    this.logger.info('hasValidSelectedPrintJobs()');
    let hasValidPrintJobsSelected: boolean = false;
    if (this.selectedPrintJobs.length > 0) {
      for (let i = 0; i < this.printJobList.length; i++) {
        for (let j = 0; j < this.selectedPrintJobs.length; j++) {
          let printJob = this.printJobList[i];
          if (printJob.links.self === this.selectedPrintJobs[j].links.self) {
            hasValidPrintJobsSelected = true;
            printJob.options.isSelected = true;
            this.isAllSelected(this.findPrinterForPrintJob(printJob));
          }
        }
      }
    }
    return hasValidPrintJobsSelected;
  };

  public createRelease(releaseQueue?: any, scanStartedForRelease?: boolean): void {
    const methodName = 'createRelease()';
    this.logger.info(methodName);
    let releaseObject: object = {};
    if (releaseQueue && this.selectedPrinter) {
      releaseObject['printer'] = this.selectedPrinter ? this.selectedPrinter.links.self : null;
      releaseObject['queue'] = releaseQueue;
    } else if (this.lastUsedAnywhereQueue && this.selectedPrinter) {
      releaseObject['printer'] = this.selectedPrinter ? this.selectedPrinter.links.self : null;
      releaseObject['queue'] = this.lastUsedAnywhereQueue.links.self;
    }

    if (this.selectedPrintJobs.length !== 0) {
      let selectedJobs: Array<string> = [];
      this.selectedPrintJobs.forEach(job => {
        selectedJobs.push(job.links.self);
      });
      releaseObject['jobs'] = selectedJobs;
    }

    this.releaseResourceService.createReleaseResource(releaseObject)
    .pipe(takeUntil(this.unSubscribe))
    .subscribe((response) => {
      this.logger.info(methodName + 'createReleaseResource() - ReleaseID: ' + response['resourceId']);
      this.dialogService.hideLoadingSpinnerDialog('createRelease()');
      this.releaseResource = response;
      this.unavailablePrintJobs = false;
      this.setPrinterAccessError(response.printerQueue);
      this.setReleaseJobsErrors(response.releaseJobs);

      if (scanStartedForRelease) {
        this.findScannedPrinter();
      }

      this.unSubscribe.next();
      this.unSubscribe.complete();
    }, (error) => {
      this.dialogService.hideLoadingSpinnerDialog('ERROR - createRelease()');
    });
  }

  private setPrinterAccessError(printerQueue): void {
    const methodName = 'setPrinterAccessError()';
    this.logger.info('setPrinterAccesError()');
    if (printerQueue && printerQueue.errors.length !== 0) {
      this.printerAccessError = true;
      this.noProxiesForPrinter = false;
      this.releaseNotPossible = false;
      this.printerInErrorState = false;
      this.printerOffline = false;
      this.printerQueue = printerQueue.selectedPrinter;
      this.setPrinterQueueErrors(printerQueue);
    } else {
      this.noProxiesForPrinter = false;
      this.releaseNotPossible = false;
      this.printerAccessError = false;
      this.printerInErrorState = false;
      this.printerOffline = false;
    }
  }

  private setPrinterQueueErrors(printerQueue) {
    this.logger.info('setPrinterQueueErrors()');
    printerQueue.errors.forEach(error => {
      switch(error['type']) {
        case 'RELEASE_NOT_POSSIBLE':
          this.releaseNotPossible = true;
        break;
        case 'NO_PROXIES':
          this.noProxiesForPrinter = true;
        break;
        case 'PRINTER_IN_ERROR_STATE':
          if (this.selectedPrinter && this.selectedPrinter.embedded.status.pollStatus && this.selectedPrinter.embedded.status.pollStatus === 'DEVICE_NOT_FOUND') {
            this.printerOffline = true;
          }
          if (this.selectedPrinter && this.selectedPrinter.embedded.status.errors.length !== 0) {
            this.selectedPrinter.embedded.status.errors.forEach(printerError => {
              if (printerError === 'OFFLINE') {
                this.printerOffline = true;
              }
            });
          }
          this.printerInErrorState = true;
        break;
      }
    });
  }

  private setReleaseJobsErrors(releaseJobs) {
    this.logger.info('setReleaseJobsErrors()');
    if (this.selectedPrintJobs.length > 0) {
      this.selectedPrintJobs.forEach(selectedPJ => {
        releaseJobs.forEach(resourcePj => {
          if (selectedPJ.links.self === resourcePj.jobId) {
            if (resourcePj.errors.length === 0) {
              this.printJobList.forEach(pj => {
                if (resourcePj.jobId === pj.links.self) {
                  pj.options.resourceErrors = [];
                }
              });
            } else {
              this.unavailablePrintJobs = true;
              this.printJobList.forEach(pj => {
                if (resourcePj.jobId === pj.links.self) {
                  pj.options.resourceErrors = resourcePj.errors;
                }
              });
            }
          }
        });
      });
    }
  }

  private resetPrintersAllowedToPrintProperty (): void {
    this.logger.info('resetPrintersAllowToPrintProperty()');
    _.forEach(this.printerList, (printer) => {
      printer.options.allowToPrint = true;
      printer.options.isAllPrintJobsSelected = false;
    });
  }

  private setPrinterLocation(): void {
    this.logger.info('setPrinterLocation()');
    for (let j = 0; j < this.printJobList.length; j++) {

      for (let i = 0; i < this.printerList.length; i++) {
        if (!(this.printerList[i].location) || isEmptyString(this.printerList[i].location)) {
          this.printerList[i].location = null;
        }
      }
    }
  }

  // BE CAREFUL when you change last used queue stuff, since it can really mess up the app with errors if the is a mismatch between last used printer and queue.
  private checkForLastUsedQueue(): void {
    const methodName = 'checkForLastUsedQueue() ';
    this.logger.info(methodName);
    const lastUsedAnywhereQueueUrl: string = this.user && this.user.userAppSettings.lastUsedQueue ? this.user.userAppSettings.lastUsedQueue : null; // lastUsedAnywhereQueue is set in UserAppSettings
    if (lastUsedAnywhereQueueUrl !== null) {
      let printerHasLastUsedQueue = false;  // find lastUsedPrinter from favoritePrinterList
      this.selectedPrinter.queues.forEach(q => {
        if (q.links.self === lastUsedAnywhereQueueUrl) {
          printerHasLastUsedQueue = true;      // if printer is found and queue is matched set last used printer and queue
          q.isLastUsedQueue = true;
          q.isFavorite = true;
          this.lastUsedAnywhereQueue = q;
        }
      });
      if (!printerHasLastUsedQueue) { // if last used queue is not on the printer
        this.logger.info(methodName + 'LastUsedQueue not present on printer');
        this.resetPrinterAndQueue();
      }
    } else {
      this.logger.info(methodName + 'lastUsedAnywhereQueue === null');
      this.resetPrinterAndQueue();
    }
  }

  public resetPrinterAndQueue(): void {
    this.logger.info('resetPrinterAndQueue()');
    this.lastUsedAnywhereQueue = null;
    this.selectedPrinter = null;
    this.userService.removeLastUsedPrinterAndQueue()
    .subscribe(() => {
      this.userService.refreshCurrentUser(this.tenant.links.currentUser)
      .pipe(takeUntil(this.unSubscribe))
      .subscribe((user) => {
         this.dialogService.hideLoadingSpinnerDialog('resetPrinterAndQueue()');
        this.user = user;
        this.selectedPrintJobs = [];
        this.selectedQueue = null;
        this.scanning = false;
        if (this.appIsActive && this.isCurrentUrlThisPage()) {
         console.log('## refreshPrintJobList() - resetPrinterAndQueue()');
          this.refreshPrintJobList();
        }
      });
    });
  }

  // ############### /INIT ###############################################################################

  private isAllSelected(printer: IPrinter): void { // hasValidSelectedPrintJobs(), selectAllToggle(), selectPrintJob()
    this.logger.info('isAllSelected()');
    let printerJobList = [];
    let selectedPrintJobs = [];
    this.printJobList.forEach(pj => {
      if (pj.links.printer === printer.links.self) {
        printerJobList.push(pj);
      }
    });

    printerJobList.forEach(pj => {
      if (pj.options.isSelected) {
        selectedPrintJobs.push(pj);
      }
    });

    if (printerJobList.length === selectedPrintJobs.length && selectedPrintJobs.length > 0) {
      printer.checked = true;
    } else {
      printer.checked = false;
    }

    this.showPrinterSelectWindow();
  }

  private showPrinterSelectWindow(): boolean { // trickered by isAllSelected(), shows the action button panel at the bottom of the page when an anywhere document has been selected
    this.logger.info('showPrinterSelectWindow()');
    for (let j = 0; j < this.printerList.length; j++) {
      for (let i = 0; i < this.selectedPrintJobs.length; i++) {
        if (this.printerList[j].links.self === this.selectedPrintJobs[i].links.printer) {
          if (this.printerList[j].type === 'FREEDOM') {
            this.printAnywhere = true;
            return;
          }
        }
      }
    }
    this.printAnywhere = false;
  }

  public findPrinterForPrintJob = function (printJob: IPrintJob): IPrinter { // hasValidSelectedPrintJobs()
    this.logger.info('findPrinterForPrintJob()');
    let printer: IPrinter = null;
    for (let i = 0; i < this.printerList.length; i++) {
      if (this.printerList[i].links.self === printJob.links.printer) {
        printer = this.printerList[i];
      } else {
        this.printerList[i].options.allowToPrint = false;
      }
    }
    return printer;
  };

  private findPrintJobAndRemove(printJob: IPrintJob): void { // selectPrintJob()
    this.logger.info('findPrintJobAndRemove()');
    let printJobID = printJob.links.self;
    for (let i = 0; i < this.selectedPrintJobs.length; i++) {
      if (this.selectedPrintJobs[i].links.self === printJobID) {
        this.selectedPrintJobs.splice(i, 1);
      }
    }
  }

  private resetPrinterAllowToPrintProperty(printerSelectedPrintJob: Number, printJob?: IPrintJob): void { // selectPrintJob() selectAllToggle()
    this.logger.info('resetPrinterAllowToPrintProperty()');
    _.forEach(this.printerList, (printer) => {
      if (Number(printerSelectedPrintJob) > 0) {
        if (printJob && this.checkPrintJobOnPrinter(printer, printJob)) {
          printer.options.allowToPrint = true;
        } else {
          printer.options.allowToPrint = false;
        }
      } else {
        printer.options.allowToPrint = true;
      }
    });
  }

  private checkPrintJobOnPrinter(printer: IPrinter, printJob: IPrintJob): boolean { // resetPrinterAllowToPrintProperty()
    this.logger.info('checkPrintJobOnPrinter()');
    if (printer.links.self === printJob.links.printer) {
      return true;
    }
    return false;
  }

  private async presentAlert(printer: IPrinter, releaseJobs: Array<any>, alertType: string): Promise<any> { // ALERT for unavailable selected printJobs
    this.logger.info('presentAlert() for deselect/releaseMatching/tooManyPrintJobs printJobs or cancelRelease');
    let dataPackage: any = {
      type: alertType,
      securePrintLevel: this.securePrintLevel,
      releaseJobs: releaseJobs,
      errorParameters: this.tooManyPrintJobsInReleaseErrorParameters
    };

    let popover = await this.popoverCtrl.create({
      component: PopoverComponent,
      componentProps: {dataPackage},
      cssClass: alertType,
      backdropDismiss: false
    });

    this.handleDismissedAlertDialog(popover, releaseJobs, printer);
  }

  private async handleDismissedAlertDialog(popover, releaseJobs, printer) {
    await popover.present();

    await popover.onDidDismiss()
    .then(popoverData => {
      if (popoverData.data === 'deselectPrintJobs') {
        this.handleDismissedDialogDeselectUnavailablePrintJobs(releaseJobs, printer);
      } else if (popoverData.data === 'releaseMatchingPrintJobs') {
        this.handleDismissedDialogReleaseMatchingPrintJobs(releaseJobs);
      } else if (popoverData.data === 'cancelRelease') {
        this.logger.info('onDidDismiss() cancelRelease');
        if (this.scanningMethod === 'QR_SCAN') {
          this.cancelQRScanner();
        }
        this.selectedQueue = null;
        this.scanning = false;
        if (this.appIsActive) {
          console.log('##refreshPrintJobList() - cancelRelease()');
          this.refreshPrintJobList();
        }
      } else if (popoverData.data === 'unMatchedPrinter') {
        if (this.scanningMethod === 'QR_SCAN') {
          this.cancelQRScanner();
        }
        this.selectedPrintJobs = [];
        this.selectedQueue = null;
        this.scanning = false;
        console.log('## refreshPrintJobList() - unMatchedPrinter()');
        this.refreshPrintJobList();
      } else if (popoverData.data === 'tooManyPrintJobSelected') {
        if (popoverData.role === 'ok') {
          this.selectPrintJobsToLimit(); // Select only allowed amount of PJ's
        } else {
          printer.checked = false;
          this.selectAllToggle(printer);
        }
      }
    });
  }

  private handleDismissedDialogDeselectUnavailablePrintJobs(releaseJobs, printer) {
    this.logger.info('onDidDismiss() deselectPrintJobs');
    this.printJobsToAdd = [];
    releaseJobs.forEach((resourcePj) => {
      if (resourcePj.errors.length !== 0) {
        this.printJobList.forEach(pj => { // update UI deselecting unavailable PJ's
          if (resourcePj.jobId === pj.links.self) {
            pj.options.isSelected = false;
            pj.options.resourceErrors = [];
            this.findPrintJobAndRemove(pj);
          }
        });
      } else {
        this.printJobsToAdd.push(resourcePj.jobId);
      }
    });
    printer.options.isAllPrintJobsSelected = false;
    this.isAllSelected(printer);
    this.updatePrintJobOnRelease(printer);
    this.resetPrinterAllowToPrintProperty(this.selectedPrintJobs.length, this.selectedPrintJobs ? this.selectedPrintJobs[0] : null);
  }

  private handleDismissedDialogReleaseMatchingPrintJobs(releaseJobs) {
    this.logger.info('onDidDismiss() releaseMatchingPrintJobs');
    this.selectedPrintJobs = [];
    releaseJobs.forEach((resourcePj) => {
      if (resourcePj.errors.length === 0) {
        this.printJobList.forEach(pj => { // update UI deselecting unavailable PJ's
        if (resourcePj.jobId === pj.links.self) {
            this.selectedPrintJobs.push(pj);
            pj.options.isSelected = true;
          }
        });
      }
    });
    if (this.selectedPrintJobs.length > 0) {
      this.updateReleaseResourceBeforeRelease(this.selectedPrinter, this.selectedPrintJobs, this.selectedQueue); // RELEASE MATCHING PRINT JOBS
    } else {
      this.printJobsToAdd = [];
      this.scanning = false;
      if (this.appIsActive) {
        console.log('## refreshPrintJobList - handleDismissedDialogReleaseMatchingPrintJobs() no selected PrintJobs');
        this.refreshPrintJobList();
      }
    }
  }

  private handleExpiredRelease(error, queueUrl?: string) {
    this.logger.info('handleExpiredRelease()');
    if (error.status === 400 && error.error.errorCode === 'PATCHING_FINISHED_RELEASE') {
      this.dialogService.showLoadingSpinnerDialog('handleExpiredRelease()').subscribe(() => {
        this.createRelease(queueUrl, false);
      });
    }
  }

  private selectPrintJobsToLimit() {
    this.dialogService.showLoadingSpinnerDialog('selectPrintJobsToLimit()');
    const printJobLimitString = this.tooManyPrintJobsInReleaseErrorParameters[0];
    const printJobLimitNumber = parseInt(printJobLimitString, 10);
    if (this.selectedPrintJobs.length > printJobLimitNumber) {
      this.selectedPrintJobs.forEach((pj, index) => {
        if (index >= printJobLimitNumber) {
          pj.options.isSelected = false;
        }
      });
      this.selectedPrintJobs = this.selectedPrintJobs.slice(0, printJobLimitNumber);
    }
    this.dialogService.hideLoadingSpinnerDialog('selectPrintJobsToLimit()');
  }

  // ############### VIEW TRIGGERED ###############################################################################

  public goToReleaseHistoryPage(): void {
    this.logger.info('(click) goToReleaseHistoryPage()');
    this.navCtrl.navigateForward('/release-history');
  }

  public selectPrintJob(printJob: IPrintJob, printer: IPrinter): void { // VIEW
    this.logger.info('(click) selectPrintJob()');
    this.eventService.startTokenExpireCountDown();

    if (printer.options.allowToPrint) {

      printJob.options.isSelected = !printJob.options.isSelected;
      let printJobUrl = printJob.links.self;

      if (printJob.options.isSelected) {
        this.selectedPrintJobs.push(printJob);
      } else {
        this.findPrintJobAndRemove(printJob);
        printJob.options.resourceErrors = [];
      }

      if (this.debouncerRunning) {
        clearTimeout(this.debounceTimer); // Clear timer
      } else {
        this.debouncerRunning = true;
      }
      this.setDebounceTime(printer);

      this.isAllSelected(printer);
      this.resetPrinterAllowToPrintProperty(this.selectedPrintJobs.length, printJob);
    }
  }

/////////////////////////////////////////////////////////////
////////////////////// SET DEBOUNCE TIME ////////////////////
/////////////////////////////////////////////////////////////

  public setDebounceTime(printer: IPrinter): any {
    this.logger.info('setDebounceTime()');
    this.debounceTimer = setTimeout(() => {
      this.dialogService.hideLoadingSpinnerDialog('setDebounceTime()');
      this.updatePrintJobOnRelease(printer);
    }, 800);
  }

/////////////////////////////////////////////////////////////
/////////// UPDATE PRINT JOBS ON RELEASE RESOURCE ///////////
/////////////////////////////////////////////////////////////

  public updatePrintJobOnRelease(printer: IPrinter) {
    const methodName = 'updatePrintJobOnRelease() ';
    this.logger.info(methodName);
    let queueUrl: string;
    let printerUrl: string;
    if (printer.type === 'FREEDOM') {
      queueUrl = this.lastUsedAnywhereQueue ? this.lastUsedAnywhereQueue.links.self : null;
      printerUrl = this.selectedPrinter ? this.selectedPrinter.links.self : null;
    } else {
      queueUrl = this.selectedPrintJobs.length > 0 ? this.selectedPrintJobs[0].links.queue : null;
      printerUrl = printer.links.self;
    }

    this.printJobsToAdd = []; // reset print jobs to add before setting them in order to avoid duplicates
    this.sortSelectedPrintJobs(); // sort the selected PJ's, to keep the order with the newest PJ first
    this.selectedPrintJobs.forEach(pj => { // add all selected print job id's to addPrintJobs array
      this.printJobsToAdd.push(pj.links.self);
    });

    this.dialogService.showLoadingSpinnerDialog('updatePrintJobOnRelease()');
    this.releaseResourceService.updatePrintJobsOnRelease(this.printJobsToAdd, printerUrl, queueUrl, this.releaseResource.resourceId)
    .pipe(takeUntil(this.unSubscribe))
    .subscribe((response) => {
      this.dialogService.hideLoadingSpinnerDialog('updatePrintJobOnRelease()');
      this.logger.info(methodName + '- ReleaseID: ' + response['resourceId']);
      this.releaseResource = response;
      let releaseJobs = response.releaseJobs;
      this.unSubscribe.next();
      this.unSubscribe.complete();

      this.debouncerRunning = false;
      this.unavailablePrintJobs = false;

      this.setPrinterAccessError(response.printerQueue);
      this.setReleaseJobsErrors(releaseJobs);

      this.printJobsToAdd = [];
      this. checkIfAllSelected(printer, releaseJobs);
      this.checkSecurePrintLevel();
    }, (error) => {
      this.dialogService.hideLoadingSpinnerDialog('updatePrintJobOnRelease()');
      if (error.status === 422 && error.error.errorCode === 'TOO_MANY_PRINT_JOBS_IN_RELEASE') {
        this.tooManyPrintJobsInReleaseErrorParameters = error.error.parameters;
        this.presentAlert(printer, null, 'tooManyPrintJobSelected');
      } else {
        this.handleExpiredRelease(error, queueUrl); // UpdatePrintJobsOnRelease
      }
    });
  }

  private sortSelectedPrintJobs() {
    if (this.printJobsPageType === 'PrintJobsPage') {
      this.selectedPrintJobs = this.selectedPrintJobs.sort((a, b) => a.submitTime < b.submitTime ? 1 : -1);
    } else {
      this.selectedPrintJobs = this.selectedPrintJobs.sort((a, b) => a.deletedTime < b.deletedTime ? 1 : -1);
    }
  }

  private checkIfAllSelected(printer, releaseJobs) {
    if (this.selectAllClicked) {
      this.selectAllClicked = false;
      if (this.unavailablePrintJobs) {
        if (this.isCordova) {
          this.presentAlert(printer, releaseJobs, 'deselectPrintJobs');
        } else {
          if (!this.securePrintLevel || this.securePrintLevel === 'NONE') {
            this.presentAlert(printer, releaseJobs, 'deselectPrintJobs');
          }
        }
      }
    }
  }

  private checkSecurePrintLevel() {
    if (this.securePrintLevel && this.securePrintLevel === 'ON_DEVICE_RELEASE' && this.selectedPrintJobs.length > 0) {
      this.presentAlert(null, null, 'onDeviceRelease');
    } else if (this.securePrintLevel && this.securePrintLevel !== 'NONE' && !this.isCordova && this.selectedPrintJobs.length > 0) {
      this.presentAlert(null, null, 'securePrintEnabled');
    }
  }

  public selectAllToggle(printer: IPrinter): void { // TOGGLE ALL PJ
    this.logger.info('(click) selectAllToggle()');
    console.log('printer', printer);
    console.log('this.debouncerRunning', this.debouncerRunning);
    if (printer.options.allowToPrint) {
      this.dialogService.showLoadingSpinnerDialog('selectAllToggle()');
      this.selectAllClicked = true;

      if (this.debouncerRunning) {
        clearTimeout(this.debounceTimer);
      }
      this.debouncerRunning = true;

      this.printJobsToAdd = [];
      this.selectedPrintJobs = [];

      this.handlePrinterToggleData(printer, printer.checked);
      this.isAllSelected(printer);
      this.setDebounceTime(printer);

      // this.resetPrinterAllowToPrintProperty(this.selectedPrintJobs.length);
      // printer.options.allowToPrint = true;
    }
  }

  private handlePrinterToggleData(printer, printerChecked) {
    this.printJobList
    .filter((printJob: IPrintJob) => printJob.links.printer === printer.links.self)
    .forEach((printJob: IPrintJob) => {
      printJob.options.isSelected = printerChecked;
      if (printerChecked) {
        this.selectedPrintJobs.push(printJob);
      }
    });
    printer.options.isAllPrintJobsSelected = printerChecked;
  }

  public deleteSelectedPrintJobs (printJob?: IPrintJob) { // DELETE PJ
    this.logger.info('(click) deleteSelectedPrintJobs()');
    this.dialogService.showLoadingSpinnerDialog('deleteSelectedPrintJobs()').subscribe(() => {
      let printJobsToDelete: Array<IPrintJob> = [];

      if (printJob) {
        printJob['options']['isSelected'] = false;
        printJobsToDelete.push(printJob);
        let indexToDelete;
        this.selectedPrintJobs.forEach((pj, index) => {
          if (pj.links.self === printJob.links.self) {
            indexToDelete = index;
          }
        });
        this.selectedPrintJobs.splice(indexToDelete, 1);
      } else {
        printJobsToDelete = this.selectedPrintJobs;
        this.selectedPrintJobs = [];
      }

      this.printJobService.deleteSelectedPrintJobs(printJobsToDelete)
      .pipe(takeUntil(this.unSubscribe))
      .subscribe(() => {
        this.dialogService.hideLoadingSpinnerDialog('deleteSelectedPrintJobs()');
        this.releaseResource = null;
        if (this.appIsActive) {
          console.log('## refreshPrintJobList() - deleteSelectedPrintJobs()');
          this.refreshPrintJobList();
        }
      });
    });
  }

  // ############### /VIEW TRIGGERED ###############################################################################

  // ################# MODAL #####################################################################################
  public async presentModal(event, target, modalType: string, fromPrinterList, printJobs) {
    const methodName = 'updatePrintJobOnRelease()';
    this.logger.info('(click) presentModal() ' + modalType);
    if (printJobs) {
      target.printJobs = printJobs;
    }

    let dataPackage: any = {
      target: target,
      printer: target,
      cssClass: modalType
    };

    const infoModal = await this.modalCtrl.create({
      component: ModalComponent,
      componentProps: {dataPackage},
      cssClass: modalType
    });

    await infoModal.present();
    this.handleModalDismissed(infoModal, modalType, fromPrinterList);
  }

  private async handleModalDismissed(infoModal, modalType, fromPrinterList) {
    await infoModal.onDidDismiss()
    .then(modalData => {
      this.logger.info(modalType + ' onDidDismiss() ' + modalData);
      if (modalType === 'printerInfo' && fromPrinterList) {
        this.presentPopover(event, 'select-printer-menu', this.printerList);
      } else if (modalType === 'selectPrintQueue') {
        if (modalData.data) {
          if (modalData.data === 'cancelRelease') {
            if (this.scanningMethod === 'QR_SCAN') {
              this.cancelQRScanner();
            }
            this.scanning = false;
            if (this.appIsActive) {
              console.log('## refreshPrintJobList() - cancelRelease()');
              this.refreshPrintJobList();
            }
          } else {
            this.dialogService.showLoadingSpinnerDialog('modalDismissed->updateReleaseresourceBeforeRelease()').subscribe(() => {
              this.selectedQueue = modalData.data.queue;
              this.updateReleaseResourceBeforeRelease(modalData.data.printer, modalData.data.printJobs, this.selectedQueue); // SELECT QUEUE
            });
          }
        }
      }
    });

  }
  // ################# /MODAL #####################################################################################

  // ################# POPOVER #####################################################################################
  public async presentPopover(myEvent, popoverType: string, data): Promise<void> {
    this.logger.info('(click) presentPopover() ' + popoverType);

    if(this.dataIsLoaded) {

      if (popoverType === 'select-printer-menu') { // to show list of favorite queues
        this.handleSelectPrinterMenuData(popoverType, myEvent);
      } else {
        if (popoverType === 'printJobMenu') {
          let dataPackage: any = {
            type: popoverType,
            printJob: data
          };

          let popover = await this.popoverCtrl.create({
            component: PopoverComponent,
            componentProps: {dataPackage},
            cssClass: popoverType
          });

          await popover.present();
          this.handlePopoverDismissed(popover, popoverType);
        }
      }
    }
  }

  private handleSelectPrinterMenuData(popoverType, myEvent) {
    this.logger.info('handleSelectPrinterMenuData()');
    let matchingQueuesUrl: string = this.releaseResource.matchingQueues;
    let lastUsedAnywhereQueueUrl: string = this.lastUsedAnywhereQueue ? this.lastUsedAnywhereQueue.links.self : null ;

    // if (lastUsedAnywhereQueueUrl !== null && this.favoriteQueueUrls.indexOf(lastUsedAnywhereQueueUrl) === -1) { // add lastUsed/selected- printer to the favorite printers list
    //   this.favoriteQueueUrls.push(lastUsedAnywhereQueueUrl);
    // }

    if (this.favoriteQueueUrls.length !== 0) {
      this.dialogService.showLoadingSpinnerDialog('handleSelectPrinterMenuData()').subscribe(() => {
        this.releaseResourceService.getAvailablePrintersList(matchingQueuesUrl, this.favoriteQueueUrls, 0, 500, null, [])
        .pipe(takeUntil(this.unSubscribeMatchingQueues))
        .subscribe( async (response) => {
          let availableFavoritePrinters: Array<IPrinter> = response.printers;
          availableFavoritePrinters.forEach(p => {
            p.queues.forEach(q => {
              q.isFavorite = true;
              if (q.links.self === lastUsedAnywhereQueueUrl) {
                q.isLastUsedQueue = true;
              }
            });
          });

          this.unSubscribeMatchingQueues.next();
          this.unSubscribeMatchingQueues.complete();
          if (availableFavoritePrinters.length !== 0) {

              let dataPackage: any = {
                type: popoverType,
                printerList: availableFavoritePrinters,
                printJobs: this.selectedPrintJobs,
                originalEvent: myEvent
              };

              let popover = await this.popoverCtrl.create({
                component: PopoverComponent,
                componentProps: { dataPackage },
                cssClass: popoverType,
                animated: true
              });

              this.dialogService.hideLoadingSpinnerDialog('handleSelectPrinterMenuData()');
              await popover.present();
              this.handlePopoverDismissed(popover, popoverType);
            } else {
             this.dialogService.hideLoadingSpinnerDialog('ERROR - handleSelectPrinterMenuData()');
            this.navCtrl.navigateForward(['/printer-list'], {queryParams: { resource: this.releaseResource }});
          }
        }, (error) => {
          // It is ok to see matching queues for an expired release, a new release will be created, when a release is started
        });
      });
    } else {
      this.navCtrl.navigateForward(['/printer-list'], {queryParams: { resource: this.releaseResource }});
    }
  }

  private async handlePopoverDismissed(popover, popoverType) {
    await popover.onDidDismiss()
    .then(popoverData => {
      this.logger.info(popoverType + ' onDidDismiss() ' + popoverData);
      const popData = popoverData.data
      if (popData && popData.event) {
        switch (popData.event) {
          case 'showPrintJobInfo':
            this.presentModal(popData.originalEvent, popData.target, popData.event, true, null);
          break;
          case 'deletePrintJob':
            if (this.printJobsToAdd.length === 0) {
              this.deleteSelectedPrintJobs(popData.target);
            }
          break;
          case 'print':
            this.selectedPrintJobs = popData.printJobs;
            const selectedPrinter = popData.printer;
            const selectedQueue = popData.target;
            this.userService.setLastUsedPrinter(selectedPrinter) // set last used printer
            .pipe(takeUntil(this.unSubscribe))
            .subscribe(() => {
              this.logger.info('releaseSelectedPrintJobs() - setting lastUsedQueue: ' + selectedQueue.links.self);
              this.userService.setLastUsedQueue(selectedQueue)
              .pipe(takeUntil(this.unSubscribe))
              .subscribe(() => {
                this.unSubscribe.next();
                this.unSubscribe.complete();
                // this.user.userAppSettings.lastUsedPrinter = popData.printer.links.self;
                // this.user.userAppSettings.lastUsedQueue = popData.target.links.self;
                if (this.appIsActive) {
                  console.log('## refreshPrintJobList() - handlePopoverDismissed() - print');
                  this.refreshPrintJobList();
                }
              });
            });
          break;
          case 'printerInfo':
            this.presentModal(popData.originalEvent, popData.target, popData.event, true, null);
          break;
          case 'showPrintQueuesListPage':
            this.navCtrl.navigateForward(['/printer-list'], {queryParams: { resource: this.releaseResource }});
            // this.navController.push(PrinterListPage, { resource: this.releaseResource });
          break;
        }
      }
      if (popoverData === null) {
        this.favoriteQueueUrls = [];
        if (this.appIsActive) {
          console.log('## refreshPrintJobList() - handlePopoverDismissed() - popoverData === null');
          this.refreshPrintJobList();
        }
      }
    });
  }
  // ################# /POPOVER #####################################################################################

  // ######################### RELEASE PRINTJOBS #############################################################################################################################################
  private releaseSelectedPrintJobs(selectedPrinter: IPrinter, selectedPrintJobs: Array<IPrintJob>, selectedQueue): void {
    const methodName = 'releaseSelectedPrintJobs() ';
    this.logger.info(methodName);
    this.debouncerRunning = true;
    let printJobUrls: Array<string> = [];
    selectedPrintJobs.forEach(pj => {
      printJobUrls.push(pj.links.self);
    });
    this.logsService.logReleaseItems(selectedPrinter, selectedQueue, printJobUrls, this.releaseResource.resourceId, this.printJobsPageType);
    if (selectedPrinter) {
      this.releasePrintService.releaseSelectedPrintJobs(selectedPrinter, selectedPrintJobs, selectedQueue, this.releaseResource.startRelease)
      .pipe(takeUntil(this.unSubscribe))
      .subscribe((response) => {
        // console.log('response', response);
        this.releaseResource = null;
        this.selectedPrintJobs = [];
        this.selectedQueue = null;
        this.scanning = false;

        _.forEach(this.printerList, (printer) => {
          printer.checked = false;
        });

        this.setFavoriteQueueAndRefreshPage(selectedQueue);

      },(error) => {
          this.scanning = false;
          if (error.status === 400 && error.error.errorCode === 'PATCHING_FINISHED_RELEASE') {
            this.logger.info(methodName + 'ReleaseResource expired');
          } else {
            this.logger.info(methodName + 'release failed');
          }
        });
      } else {
        this.scanning = false;
        this.logger.info(methodName + 'selectedPrinter is missing');
      throw "print: selectedPrinter is missing";
    }
  }

  private setFavoriteQueueAndRefreshPage(selectedQueue) {
    this.debouncerRunning = false;
    if (selectedQueue && this.favoriteQueueUrls.indexOf(selectedQueue.links.self) === -1) { // set the queue as favorite if it is not favorite already
      this.userService.changePrinterQueuePinStatus(selectedQueue.links.self, selectedQueue.isFavorite)
      .pipe(takeUntil(this.unSubscribe))
      .subscribe(() => {
        if (this.appIsActive) {
          console.log('## refreshPrintJobList() - setFavoriteQueueAndRefreshPage() - changePrinterQueuePinStatus()');
          this.refreshPrintJobList();
        }
      }, (error) => {
        if (this.appIsActive) {
          console.log('## refreshPrintJobList() - setFavoriteQueueAndRefreshPage() - changePrinterQueuePinStatus() - Error');
          this.refreshPrintJobList();
        }
      });
    } else {
      if (this.appIsActive) {
        console.log('## refreshPrintJobList() - setFavoriteQueueAndRefreshPage() - no selected queue()');
        this.refreshPrintJobList();
      }
    }
  }

  public print(): void { // VIEW
    this.logger.info('(click) print()');

    let selectedPrinter: IPrinter;

    if (this.printAnywhere) {
      selectedPrinter = this.selectedPrinter;
    } else {
      let selectedPrinterUrl = this.selectedPrintJobs[0].links.printer;
      let selectedPrinterFromUrl = this.printerList.filter(printer => printer.links.self === selectedPrinterUrl);
      selectedPrinter = selectedPrinterFromUrl[0];
      this.lastUsedAnywhereQueue = null;
    }

    this.releaseSelectedPrintJobs(selectedPrinter, this.selectedPrintJobs, this.lastUsedAnywhereQueue);
  }

  // ##################### /RELEASE PRINTJOBS ################################################################################

  // ##################### START SCANNING ################################################################################

  public async startScanning(event, popoverType): Promise<void> {
    this.logger.info('(click) startScanning()');
    // ############################################ //
    // ##  which scanning methods are available  ## //
    // ##  if one? start that one                ## //
    // ##  if multiple? show them for selection  ## //
    // ############################################ //
    if (this.securePrintMethods.length === 1) {
      this.activateChosenScanningMethod(this.securePrintMethods[0]);
    } else if (this.securePrintMethods.length > 1) { // show popover of scanning choices

      let dataPackage: any = {
        type: popoverType,
        securePrintMethods: this.securePrintMethods,
        securePrintLevel: this.securePrintLevel
      };

      let popover = await this.popoverCtrl.create({
        component: PopoverComponent,
        componentProps: {dataPackage},
        cssClass: 'choose-scanning-method'
      });

      await popover.present();

      await popover.onDidDismiss()
      .then((chosenScanningMethod) => {
        this.activateChosenScanningMethod(chosenScanningMethod.data);
      });

    } else {
      this.dialogService.showAlertDialog("you don't have any scanning/printer ID options enabled");
    }
  }

  private activateChosenScanningMethod(chosenScanningMethod): void {
    this.logger.info('activateChosenScanningMethod():' + chosenScanningMethod);
    switch (chosenScanningMethod) {
      case 'QR':
        this.openQRScanner();
      break;
      case 'NFC':
       this.activateNfc();
      break;
    }
  }

  public checkActiveSecureLevel(): boolean {
    let disableScan: boolean = true;

    if (this.securePrintLevel === 'NFC') {
      if (this.securePrintMethods.indexOf('NFC') > -1) {
        disableScan = false;
      }
    } else if (this.securePrintLevel === 'QR') {
      if (this.securePrintMethods.indexOf('QR') > -1 || this.securePrintMethods.indexOf('NFC') > -1) {
        disableScan = false;
      }
    }

    return disableScan;
  }

// ##################### /START SCANNING ##############################################################################

// ##################### NFC SCANNING ################################################################################

  public activateNfc() {
    // console.log('this.readerMode$', this.readerMode$);
    if (this.nfcReaderMode) {
      this.scanning = false;
      this.nfcReaderMode.unsubscribe();
      if (this.platform.is('android')) {
        this.setPreventNFCListener();
      }
    }
    this.broadcaster.broadcast('DISABLE_RESUME');
    this.scanNfcTag();
  }

  private scanNfcTag(): void {
    const methodName = 'scanNfcTag() ';
    this.logger.info(methodName);
    if (this.platform.is('android')) {
      console.log('## PlatformIsAndroid');
      this.scanWithAndroid();
    } else if (this.platform.is('iphone')) {
      this.scanTagWithIOS('scanTag')
      .subscribe((event) => {
          this.prepareScannedData(event);
      }, (error) => {
          this.logger.info(methodName + 'NFC scanTag() ERROR: ' + JSON.stringify(error));
          this.logger.info(methodName + 'Trying scanNdef() method');
          this.scanTagWithIOS('scanNdef')
          .subscribe((event) => {
              this.prepareScannedData(event);
          }, (error) => {
            this.logger.info(methodName + 'NFC scanNdef() ERROR: ' + JSON.stringify(error));
            this.logger.error(methodName + 'Not able to scan tag');
            this.dialogService.showAlertDialog('Not able to scan tag');
          });
      });
    }
  }

  private scanTagWithIOS(scanMethod): Observable<any> {
    const methodName = 'scanTagWithIOS() ';
    this.logger.info(methodName);
    return new Observable((observer: Subscriber<any>) => {
      if (scanMethod === 'scanNdef') {
        this.nfc.scanNdef().then((event) => {
          this.logger.info(methodName + 'Scanned Ndef success');
          observer.next(event)
          observer.complete();
        }, (error) => {
          this.logger.info(methodName + 'Scanned Ndef failure');
          observer.error(error);
          observer.complete();
        });
      } else if (scanMethod === 'scanTag') {
        this.nfc.scanTag().then((event) => {
          this.logger.info(methodName + 'Scanned Tag success');
          observer.next(event)
          observer.complete();
        }, (error) => {
          this.logger.info(methodName + 'Scanned Tag failure');
          observer.error(error);
          observer.complete();
        });
      }
    });
  }

  private async scanWithAndroid() {
    const methodName = 'scanWithAndroid() ';
    this.logger.info(methodName);
    this.showScanReadyMessageAndroid();

    // handle Android scan
    let flags = this.nfc.FLAG_READER_NFC_A | this.nfc.FLAG_READER_NFC_V;
    this.nfcReaderMode = this.nfc.readerMode(flags).subscribe(
    (tag) => {
        console.log(tag);
        this.logger.info(methodName + 'canMakeReadOnly: ' + JSON.stringify(tag['canMakeReadOnly']));
        this.logger.info(methodName + 'isWritable: ' + JSON.stringify(tag['isWritable']));
        this.logger.info(methodName + 'maxSize: ' + JSON.stringify(tag['maxSize']));
        this.logger.info(methodName + 'tag type: ' + JSON.stringify(tag['type']));
        this.logger.info(methodName + 'tag: ' + JSON.stringify(tag));
        this.prepareScannedData(tag);
    },
    (err) => {
      console.log('Error reading tag', err);
      this.nfcReaderMode.unsubscribe();
      this.setPreventNFCListener();
    });
  }


  private async showScanReadyMessageAndroid() {
    let dataPackage: any = {
      type: 'scanNfcReadyNotice'
    };

    this.nfcReadyPopover = await this.popoverCtrl.create({
      component: PopoverComponent,
      cssClass: 'scan-nfc-ready',
      backdropDismiss: false,
      componentProps: {dataPackage}
    });

    await this.nfcReadyPopover.present();
    await this.nfcReadyPopover.onDidDismiss()
    .then(popoverData => {
      if (popoverData.data === 'cancel') {
        this.nfcReaderMode.unsubscribe();
        this.setPreventNFCListener();
        this.scanning = false;
      }
    });
  }

  private prepareScannedData(event) {
    const methodName = 'prepareScannedData()';
    this.logger.info(methodName);
    this.vibration.vibrate(500);

    if (this.platform.is('android')) {
      this.nfcReadyPopover.dismiss();
      this.nfcReaderMode.unsubscribe();
      this.setPreventNFCListener();
    }

    this.scannedNfcId = this.getTagId(event);
    this.scanningMethod = SECURE_PRINT_LEVELS.NFC;
    this.scanning = true;

    this.findScannedPrinter();
  }

  private getTagId(event) {
    const methodName = 'getTagId() ';
    this.logger.info(methodName);

    let scannedTagId = null;

    const tagId = event['id'];
    this.logger.info(methodName + 'tagId: ' + tagId);

    if (tagId) {
      scannedTagId = this.nfc.bytesToHexString(tagId);
      this.logger.info(methodName + '#### scannedTagId: ' + scannedTagId);
    } else {
      this.dialogService.showAlertDialog('Tag does not have an id, and cannot have been registered with a printer');
    }
    return scannedTagId;
}

  // ##################### /NFC SCANNING ################################################################################

  // ######################## QR SCANNING ##############################################################################################################

  public openQRScanner = function (): void { // VIEW
    const methodName = 'openQRScanner() ';
    this.logger.info(methodName);

    this.broadcaster.broadcast('DISABLE_RESUME');

    this.qrScanner.prepare().then((status: QRScannerStatus) => {
      this.broadcaster.broadcast('ENABLE_RESUME');
      if (status.authorized) {
        console.log('this.selectedPrintJobs.length', this.selectedPrintJobs.length);
        this.toggleQRScanningState(true);
      } else if (status.denied) {
        // camera permission was permanently denied
        // you must use QRScanner.openSettings() method to guide the user to the settings page
        // then they can grant the permission from there
        this.logger.info(methodName + 'status.denied, the user needs to authorize the app to use the camera from the settings, in order to scan QR codes');
        this.dialogService.showAlertDialog('You need to authorize the app to use the camera from the settings, in order to scan QR codes');
        this.qrScanner.openSettings();
      } else {
        this.logger.info(methodName + 'status not authorized, the user needs to authorize the app to use the camera from the settings, in order to scan QR codes');
        this.dialogService.showAlertDialog('You need to authorize the app to use the camera from the settings, in order to scan QR codes');
        this.qrScanner.openSettings();
        // permission was denied, but not permanently. You can ask for permission again at a later time.
      }
    })
    .catch((e: any) => this.logger.info(methodName + 'qrScanner.prepare() ERROR: ' + JSON.stringify(e)));

  };

  private toggleQRScanningState(on: boolean): void {
    this.logger.info('toggleQRScanningState()');
    this.scanning = on;
    this.showTransparentBody = on;
    if (on) {
      this.scanQRCode();
    } else {
      this.qrScanner.hide();
      this.qrScanner.destroy();
    }
    this.changeDetectorRef.detectChanges();
  }

  public scanQRCode(): void { // openQRScanner()
    const methodName = 'scanQRCode() ';
    this.logger.info(methodName);
    this.qrScanner.show().then(() => {
      this.qrScanner.scan()
      .pipe(takeUntil(this.unSubscribeQRScanner))
      .subscribe((qrUrl: string) => {
        this.logger.info(methodName + 'qrScanner.scan()');
        this.logger.info(methodName + 'scannedQRUrl: ' + qrUrl);
        this.scannedQRUrl = qrUrl;
        this.vibration.vibrate(500);
        this.unSubscribeQRScanner.next();
        this.unSubscribeQRScanner.complete();
        if (this.checkHostingDomain()) {
          this.scanningMethod = SECURE_PRINT_LEVELS.QR;
          this.findScannedPrinter();
        } else {
          this.invalidQRCode = true;
          this.changeDetectorRef.detectChanges();
          setTimeout(() => {
            this.invalidQRCode = false;
            this.changeDetectorRef.detectChanges();
            this.scanQRCode();
          }, 4000);
        }
      }, (error) => {
        console.log('error', error);
      });
    });
  }

  // ##################### /QR SCANNING #######################################################################################

  // ##################### FIND PRINTER #######################################################################################

  private findScannedPrinter(): void {
    this.logger.info('findScannedPrinter()');
    switch (this.scanningMethod) {
      case 'QR_SCAN':
        this.getPrinterFromSignId();
      break;
      case 'NFC_SCAN':
        this.getPrinterFromTagId();
      break;
    }
  }

  private getPrinterFromSignId(): void { // QR scan
    this.logger.info('QR-scan getPrinterFromSignId()');
    let urlSplit: any = this.scannedQRUrl.split('/');
    let signIdIndex: number = urlSplit.length - 1;
    this.scannedSignId = urlSplit[signIdIndex];
    let printerParameters: any = this.pagingService.getPrinterParameters();
    printerParameters.signIds = [];
    printerParameters.signIds.push(this.scannedSignId);
    // this.dialogService.showLoadingSpinnerDialog('getPrinterFromSignId()').subscribe(() => {
    //   this.printerService.getPrinterList(printerParameters, this.printerUrlString)
    //   .pipe(takeUntil(this.unSubscribe))
    //   .subscribe((scannedPrinter: Array<IPrinter>) => {
    //     this.logsService.logScannedPrinter(scannedPrinter[0], this.printJobsPageType);
    //     this.selectedPrinter = scannedPrinter[0];
    //     console.log('this.selectedPrinter', this.selectedPrinter);
    //     this.toggleQRScanningState(false);
    //     setTimeout(() => {
    //       this.dialogService.hideLoadingSpinnerDialog('getPrinterFromSignId()');
    //       this.checkForSelectedPrintJobs();
    //     }, 50);
    //   }, (error) => {
    //     this.dialogService.hideLoadingSpinnerDialog('ERROR - getPrinterFromSignId()');
    //     this.logger.info('QR-scan getPrinterFromSignId() SCANNED PRINTER NOT FOUND');
    //     this.dialogService.showAlertDialog('Scanned Printer not found');
    //   });
    // });
  }

  private checkHostingDomain(): boolean {
    this.logger.info('checkHostingDomain()');
    const urlSplit: string[] = this.scannedQRUrl.split('/');
    let validHostingDomain = false;
    let hostingDomain: string = null;

    const isValidHostingDomain = (url: string, domain: string): boolean => {
      return url.includes(domain) && url === this.tenant.tenantData.hostingDomain;
    }

    const domains = ['.printix.net', '.printix.dev']; // valid domains for QR code

    for (const url of urlSplit) {
      if (domains.some(domain => isValidHostingDomain(url, domain))) {
        hostingDomain = url;
        validHostingDomain = true;
        break;  // exit the loop early if a valid domain is found
      }
    }

    return validHostingDomain;
  }

  private getPrinterFromTagId() {
    this.logger.info('getPrinterFromTagId()');
    this.dialogService.showLoadingSpinnerDialog('getPrinterFromTagId()').subscribe(() => {
      this.nfcService.getPrinterFromTagId(this.scannedNfcId)
      .pipe(takeUntil(this.unSubscribe))
      .subscribe((scannedPrinter: IPrinter) => {
        this.dialogService.hideLoadingSpinnerDialog('getPrinterFromTagId()');
        this.logsService.logScannedPrinter(scannedPrinter, this.printJobsPageType);
        this.broadcaster.broadcast('ENABLE_RESUME');
        this.selectedPrinter = scannedPrinter;
        this.unSubscribe.next();
        this.unSubscribe.complete();
        this.checkForSelectedPrintJobs();
      }, (error) => {
        this.dialogService.hideLoadingSpinnerDialog('ERROR - getPrinterFromTagId()');
        this.scanning = false;
        if (error.status === 404) {
          this.logger.info('getPrinterFromTagId() - ## TAG_NOT_FOUND ##');
          console.log('## TAG_NOT_FOUND ##');
          this.dialogService.showTranslatedMessageDialog('TagNotFound');
        }
      });
    });
  }

  // ##################### /FIND PRINTER ######################################################################################

  // ##################### CHECK FOR SELECTED PRINT JOBS ###################################################################################

  private checkForSelectedPrintJobs(): void {
    this.logger.info('checkForSelectedPrintJobs()');
    const arePrintJobsSelected = this.selectedPrintJobs.length > 0 ? true : false;
    if (arePrintJobsSelected) {
      if (this.hasSelectedAnywhereJobs()) {
        this.handleOnlyAnywhereJobsSelected();
      } else {
        this.handleOnlyLaterJobsSelected();
      }
    } else {
      let hasAnywhereJobs = false;

      this.printerList.forEach(printer => {
        if (printer.type === 'FREEDOM') {
          hasAnywhereJobs = true;
        }
      });

      this.autoSelectPrintJobsForScannedRelease(hasAnywhereJobs);
    }
  }

  private hasSelectedAnywhereJobs(): boolean {
    let hasSelectedAnywhereJobs: boolean = false;

    this.selectedPrintJobs.forEach(printJob => {
      this.printerList.forEach(printer => {
        if (printJob.links.printer === printer.links.self && printer.type === 'FREEDOM') {
          hasSelectedAnywhereJobs = true;
        }
      });
    });
    this.logger.info('hasSelectedAnywhereJobs() hasSelectedAnywhereJobs: ' + hasSelectedAnywhereJobs);
    return hasSelectedAnywhereJobs;
  }

    // ##################### SELECTED ANYWHERE JOBS ONLY ##################################################################################
      private handleOnlyAnywhereJobsSelected() {
        this.checkQueuesForRelease();
      }
    // ##################### /SELECTED ANYWHERE JOBS ######################################################################################


    // ##################### SELECTED LATER JOBS ONLY #####################################################################################
      private handleOnlyLaterJobsSelected() {
        if (this.matchingPrinter()) {
          if (this.selectedPrinter.queues.length !== 0) { // The queue, in this case is not important, since it is only later documents, and the the server will set the correct queue, anyway.
            this.selectedQueue = this.selectedPrinter.queues[0];
            this.updateReleaseResourceBeforeRelease(this.selectedPrinter, this.selectedPrintJobs, this.selectedQueue); // ONLY LATER JOBS
          } else {
            this.dialogService.hideLoadingSpinnerDialog('handleOnlyLaterJobsSelected() - NoPrintQueue');
            this.dialogService.showTranslatedMessageDialog("NoPrintQueue");
          }
        } else {
          this.dialogService.hideLoadingSpinnerDialog('unMatchedPrinter - handleOnlyLaterJobsSelected()');
          if (this.appIsActive) {
           this.presentAlert(this.selectedPrinter, this.selectedPrintJobs, 'unMatchedPrinter');
          }
        }
      }

      private matchingPrinter() {
        // is the print job printer and the scanned printer matching up
        return this.selectedPrinter.links.self === this.selectedPrintJobs[0].links.printer;
      }
    // ##################### /SELECTED LATER JOBS ####################################################################################

    private autoSelectPrintJobsForScannedRelease(hasAnywhereJobs) {
      this.logger.info('autoSelectPrinJobsForScannedRelease()');
      let printersWithEligiblePrintJobs: Array<IPrinter> = [];

      this.printerList.forEach(printer => { // collect anywhere printer as well as later printer matching the scanned printer id
        if (printer.signId === this.selectedPrinter.signId) {
          printersWithEligiblePrintJobs.push(printer);
        } else if (printer.type === 'FREEDOM') {
          printersWithEligiblePrintJobs.push(printer);
        }
      });

      this.printJobList.forEach(printJob => { // find print jobs on the eligible printers
        printersWithEligiblePrintJobs.forEach(printer => {
          if (printJob.links.printer === printer.links.self) {
            this.selectedPrintJobs.push(printJob);
          }
        });
      });

      if (this.selectedPrintJobs.length > 0) {
        this.handleSelectedPrintJobs(hasAnywhereJobs);
      } else {
         this.dialogService.hideLoadingSpinnerDialog('autoSelectPrintJobsForScannedRelease() - unMatchedPrinter');
        if (this.appIsActive) {
          this.presentAlert(this.selectedPrinter, this.selectedPrintJobs, 'unMatchedPrinter');
        }
      }
    }

    private handleSelectedPrintJobs(hasAnywhereJobs) {
      this.sortSelectedPrintJobs(); // sort the selected PJ's, to keep the order with the newest PJ first
      if (this.selectedPrinter.queues.length !== 0) { // The queue, in this case is not important, since it is only later documents, and the the server will set the correct queue, anyway.
        if (hasAnywhereJobs) {
          this.checkQueuesForRelease();
        } else {
          this.selectedQueue = this.selectedPrinter.queues[0];
          this.updateReleaseResourceBeforeRelease(this.selectedPrinter, this.selectedPrintJobs, this.selectedQueue);  // ONLY LATER JOBS
        }
      } else {
        this.dialogService.hideLoadingSpinnerDialog('handleSelectedPrintJobs() - NoPrintQueue');
        this.dialogService.showTranslatedMessageDialog("NoPrintQueue");
      }
    }

  // ##################### /CHECK FOR SELECTED PRINT JOBS #################################################################################


  // ##################### CHECK QUEUES FOR RELEASE #################################################################################

  private checkQueuesForRelease(): void { // only for Anywhere jobs
    const methodName = 'checkQueuesForRelease() ';
    this.logger.info(methodName);
    // NEW ENDPOINT FOR AVAILABLE QUEUES, THAT TAKES THE SELECTED PJ's
    // https://api.testenv.printix.net/v1/tenants/5567c318-de37-4267-a21e-20e2861ddf2b/users/c45acc67-556c-47cc-960d-78800d562a16/releaseQueues

    const releaseQueuesUrl: string = this.user['links']['releaseQueues'];

    this.printJobsToAdd = []; // reset print jobs to add before setting them in order to avoid duplicates
    this.selectedPrintJobs.forEach(printJob => { // add all selected print job id's to addPrintJobs array
      this.printerList.forEach(printer => {
        if (printer.type === 'FREEDOM' && printJob.links.printer === printer.links.self) {
          this.printJobsToAdd.push(printJob.links.self);
        }
      });
    });

    this.releaseResourceService.getAvailableQueuesForRelease(releaseQueuesUrl, this.printJobsToAdd, this.selectedPrinter.links.self)
      .pipe(takeUntil(this.unSubscribe))
      .subscribe((availableQueues) => {
        this.unSubscribe.next();
        this.unSubscribe.complete();
        this.logger.info(methodName + 'Available queues from server:');
        this.logsService.logAvailableQueues(availableQueues, this.printJobsPageType);
        if (availableQueues.length > 0) {

          this.setAvailableQueuesForPrinter(availableQueues);

          if (this.selectedPrinter.queues.length === 1) {
            this.selectedQueue = this.selectedPrinter.queues[0];
            this.updateReleaseResourceBeforeRelease(this.selectedPrinter, this.selectedPrintJobs, this.selectedQueue); // THE ONE AND ONLY QUEUE
          } else {
            this.dialogService.hideLoadingSpinnerDialog('checkQueuesForRelease() - getAvailableQueuesForRelease - selectPrintQueue');
            this.presentModal(null, this.selectedPrinter, 'selectPrintQueue', false, this.selectedPrintJobs);
          }
        } else {
          this.dialogService.hideLoadingSpinnerDialog('checkQueuesForRelease() - getAvailableQueuesForRelease - No available resources');
          this.logger.info(methodName + '## No available resources ##');
          this.selectedPrintJobs = [];
          this.selectedQueue = null;
          this.dialogService.showTranslatedMessageDialog('Printer not found');
        }
      }, (error) => {
        this.dialogService.hideLoadingSpinnerDialog('checkQueuesForRelease() - ERROR - getAvailableQueuesForRelease');
        this.handleHttpErrorOnScan(error, this.selectedPrinter.queues[0]); // UPDATE SCANNED PRINTER QUEUE
      });
  }

  private setAvailableQueuesForPrinter(availableQueues): void {
    const methodName = 'setAvailableQueuesForPrinter() ';
    this.logger.info(methodName);

    let queuesToSelect: Array<IQueue> = [];

    availableQueues.forEach(q => { // Get the favorite queues
      if (q.active && this.favoriteQueueUrls.indexOf(q.links.self) > -1) {
        q.isFavorite = true;
        queuesToSelect.push(q);
      }
    });

    if (queuesToSelect.length !== 1) { // if there is more than one favorite queue, to select, give a choice of other active queues to select
      availableQueues.forEach(q => {
        if (q.active && queuesToSelect.indexOf(q) === -1) {
          queuesToSelect.push(q);
        }
      });
    }

    this.logger.info(methodName + 'Available queues active on printer');
    this.logsService.logAvailableQueues(queuesToSelect, this.printJobsPageType);
    this.selectedPrinter.queues = queuesToSelect;
  }

  // ##################### /CHECK QUEUES FOR RELEASE ###########################################################################


  // ##################### RELEASE ON SCANNING ################################################################################

  // ######################### UPDATE RESOURCE ############################# //
  private updateReleaseResourceBeforeRelease(printer: IPrinter, printJobs: Array<IPrintJob>, queue: IQueue): void {
    this.logger.info('updateReleaseResourceBeforeRelease()');
    this.printJobsToAdd = [];
    let queueUrl: string = queue ? queue.links.self : null;
    let resourceId: string = this.releaseResource.resourceId;
    let printerUrl: string = printer ? printer.links.self : null;

    printJobs.forEach(printJob => {
      this.printJobsToAdd.push(printJob.links.self);
    });
    this.releaseResourceService.updatePrintJobsOnRelease(this.printJobsToAdd, printerUrl, queueUrl, resourceId, this.scanningMethod)
    .pipe(takeUntil(this.unSubscribe))
    .subscribe((response) => {
      this.logsService.logUpdatedReleaseItemsBeforeReleaseStart(response, this.printJobsPageType);
      this.releaseResource = response;
      let releaseJobs: Array<any> = response.releaseJobs;

      this.unavailablePrintJobs = false;
      this.setPrinterAccessError(response.printerQueue);
      this.setReleaseJobsErrors(releaseJobs);

      this.printJobsToAdd = [];
      this.unSubscribe.next();
      this.unSubscribe.complete();
      if (this.scanningMethod === 'QR_SCAN') {
        this.cancelQRScanner();
      }
      this.dialogService.hideLoadingSpinnerDialog('updateReleaseResourceBeforeRelease()');
      if (this.unavailablePrintJobs) {
        this.presentAlert(this.selectedPrinter, releaseJobs, 'releaseMatchingPrintJobs');
      } else {
        if (queue && queue.isFavorite !== true) {
          queue.isFavorite = true;
        }
        queue = this.hasSelectedAnywhereJobs() ? queue : null;
        this.releaseSelectedPrintJobs(printer, printJobs, queue);
      }
    }, (error) => {
       this.dialogService.hideLoadingSpinnerDialog('ERROR - updateReleaseResourceBeforeRelease()');
      this.handleHttpErrorOnScan(error, queue);
    });
  }

  private handleHttpErrorOnScan(error, queue?: IQueue): void {
    this.logger.info('handleHttpErrorOnScan()');
    if (error.status === 400 && error.error.errorCode === 'PATCHING_FINISHED_RELEASE') {
      this.logger.info('ReleaseResource expired');
      this.dialogService.showTranslatedMessageDialog("Something whent wrong, please try to release again");
    }
    this.selectedPrintJobs = [];
    this.selectedQueue = null;
    this.scanning = false;
    if (this.scanningMethod === 'QR_SCAN') {
      this.cancelQRScanner();
    }
    if (this.appIsActive) {
      console.log('## refreshPrintJobList() - handleHttpErrorOnScan() - no selected queue()');
      this.refreshPrintJobList();
    }
  }

  public cancelQRScanner = function (): void {
    this.logger.info('cancelQRScanner()');
    this.invalidQRCode = false;
    if (this.unSubscribeQRScanner) {
      this.unSubscribeQRScanner.complete();
    }
    this.toggleQRScanningState(false);

    this.printerList.forEach(printer => { // uncheck 'isAllSelected' button when all printJobs are cancelled from the qr-scan view
      if (printer.type === 'FREEDOM') {
        this.isAllSelected(printer);
      }
    });
  };

  // ##################### /RELEASE ON SCANNING ################################################################################

  //################### LISTENERS #################################################################

  private startListener(): void {
    const methodName = 'startListener() ';
    this.logger.info(methodName);

    this.jobAddedSubscription = this.broadcaster.on<object>(CHANNELS.SPECIFIC_USER.EVENTS.JOB_ADDED) // get request
    .subscribe(data => {
      console.log('**PUSHER job added', this.printJobsPageType === 'PrintJobsPage' ? 'printJob page' : 'recent page', data);
      this.logger.info('**PUSHER job added');
      if (!this.scanning && this.appIsActive && this.isCurrentUrlThisPage()) {
        this.getPrintJobList();
        // console.log('this.printJobList COUNT', this.printJobList.length);
        // console.log('this.releaseResource', this.releaseResource);
      }
    });

    this.jobRemovedSubscription = this.broadcaster.on<object>(CHANNELS.SPECIFIC_USER.EVENTS.JOB_REMOVED)
    .subscribe(data => {
      console.log('**PUSHER job removed', this.printJobsPageType === 'PrintJobsPage' ? 'printJob page' : 'recent page', data);
      this.logger.info('**PUSHER job removed');
      if (this.printJobsPageType !== 'PrintJobsPage' && !data['permanent']) {
        // do nothing
        // printing a document will also trigger a 'job removed' PUSHER event on the recent page
        // only remove print jobs on the recent page if it the user deletes it
      } else {
        if (!this.scanning && this.appIsActive && this.isCurrentUrlThisPage()) {
          this.handleRemovedJobEvent(data);
        }
      }
    });

    this.jobUpdatedSubscription = this.broadcaster.on<object>(CHANNELS.SPECIFIC_USER.EVENTS.JOB_UPDATED) // TEST THIS ********
    .subscribe((data: any) => {
      console.log('**PUSHER job updated', this.printJobsPageType === 'PrintJobsPage' ? 'printJob page' : 'recent page', data);
      this.logger.info('**PUSHER job updated');
      if (!this.scanning && this.appIsActive && this.isCurrentUrlThisPage()) {
        this.handleJobUpdatedEvent(data);
      }
    });

    if (!this.createdReleaseSubscription) {
      this.createdReleaseSubscription = this.broadcaster.on<string>(CHANNELS.SPECIFIC_USER.EVENTS.USER_RELEASE_CREATED)
      .subscribe((data) => {
          // check state to see if page needs refresh
          console.log('**PUSHER release CREATED', 'printJobList', data);
          this.logger.info('**PUSHER release CREATED');
          this.changeDetectorRef.detectChanges();
      });
    }

    this.releaseUpdatedSubscription = this.broadcaster.on<object>(CHANNELS.SPECIFIC_USER.EVENTS.USER_RELEASE_UPDATED) // TEST THIS ********
    .subscribe((data: any) => {
      console.log('**PUSHER release updated', this.printJobsPageType === 'PrintJobsPage' ? 'printJob page' : 'recent page', data);
      this.logger.info('**PUSHER release updated');
      if (!this.scanning && this.appIsActive && this.isCurrentUrlThisPage()) {
        if (data['releaseStateChange'] && data['releaseStateChange']['stateAfter'] === 'FINISHED') {
          this.refreshPrintJobList();
        }
      }
    });

    this.printerStatusSubscription = this.broadcaster.on<object>(CHANNELS.ALL_TENANT_USERS.EVENTS.PRINTER_STATUS_UPDATE)
    .subscribe(data => {
      console.log('**PUSHER printer status updated', this.printJobsPageType === 'PrintJobsPage' ? 'printJob page' : 'recent page', data);
      this.logger.info('**PUSHER printer status updated');
      if (!this.scanning  && this.appIsActive && this.isCurrentUrlThisPage()) {
        this.handlePrinterStatusUpdatedEvent(data);
      }
    });

    this.printerRemovedSubscription = this.broadcaster.on<object>(CHANNELS.ALL_TENANT_USERS.EVENTS.PRINTER_REMOVED) // TEST THIS *******
    .subscribe(data => {
      console.log('**PUSHER printer removed', this.printJobsPageType === 'PrintJobsPage' ? 'printJob page' : 'recent page', data);
      this.logger.info('**PUSHER printer removed');
      if (!this.scanning && this.appIsActive && this.isCurrentUrlThisPage()) {
        console.log('## refreshPrintJobList() - PUSHER printer removed');
        this.refreshPrintJobList();
        // this.handlePrinterRemovedEvent(data);
      }
    });

    this.printerUpdatedSubscription = this.broadcaster.on<object>(CHANNELS.ALL_TENANT_USERS.EVENTS.PRINTER_UPDATE)
    .subscribe(data => {
      console.log('**PUSHER printer updated', this.printJobsPageType === 'PrintJobsPage' ? 'printJob page' : 'recent page', data);
      this.logger.info('**PUSHER printer updated');
      if (!this.scanning && this.appIsActive && this.isCurrentUrlThisPage()) {
        this.handlePrinterUpdatedEvent(data);
      }
    });

    this.printQueueUpdatedSubscription = this.broadcaster.on<object>(CHANNELS.ALL_TENANT_USERS.EVENTS.PRINT_QUEUE_UPDATED)
    .subscribe(data => {
      console.log('**PUSHER print queue updated', this.printJobsPageType === 'PrintJobsPage' ? 'printJob page' : 'recent page', data);
      this.logger.info('**PUSHER print queue updated');
      if (!this.scanning && this.appIsActive && this.isCurrentUrlThisPage()) {
        this.handlePrintQueueEvents(data, false);
      }
    });

    this.printQueueGroupsUpdatedSubscription = this.broadcaster.on<object>(CHANNELS.ALL_TENANT_USERS.EVENTS.PRINT_QUEUE_GROUPS_UPDATED)
    .subscribe(data => {
      console.log('**PUSHER print queue groups updated', this.printJobsPageType === 'PrintJobsPage' ? 'printJob page' : 'recent page', data);
      this.logger.info('**PUSHER print queue groups updated');
      if (!this.scanning && this.appIsActive && this.isCurrentUrlThisPage()) {
        this.handlePrintQueueEvents(data, false);
      }
    });

    this.printQueueRemovedSubscription = this.broadcaster.on<object>(CHANNELS.ALL_TENANT_USERS.EVENTS.PRINT_QUEUE_REMOVED) // remove queue
    .subscribe(data => {
      console.log('**PUSHER print queue removed', this.printJobsPageType === 'PrintJobsPage' ? 'printJob page' : 'recent page', data);
      this.logger.info('**PUSHER print queue removed');
      if (!this.scanning && this.appIsActive && this.isCurrentUrlThisPage()) {
        console.log('## refreshPrintJobList() - PUSHER print queue removed');
        this.refreshPrintJobList();
        // this.handlePrintQueueEvents(data, true);
      }
    });

    this.tenantUpdatedEventSubscription = this.broadcaster.on<string>(CHANNELS.ALL_TENANT_USERS.EVENTS.TENANT_UPDATED)
    .subscribe(data => {
      console.log('**PUSHER tenant updated', this.printJobsPageType === 'PrintJobsPage' ? 'printJob page' : 'recent page', data);
      this.logger.info('**PUSHER tenant updated');
      if (!this.scanning) {
        this.tenantService.refreshCurrentTenant(this.tenant.links.self)
        .subscribe((currentTenant) => {
          console.log('## PJ_PAGE NEW_REFRESHED_TENANT');
          console.log('PJ_PAGE currentTenant', currentTenant);
          this.tenant = currentTenant
        });
      }
    });

    if (!this.updateSecurePrintSubscription) {
      this.updateSecurePrintSubscription = this.broadcaster.on<string>(CHANNELS.ALL_TENANT_USERS.EVENTS.SECURE_PRINT_CONFIGURATION_UPDATED)
      .subscribe(data => {
        console.log('**PUSHER secure print configuration updated', this.printJobsPageType === 'PrintJobsPage' ? 'printJob page' : 'recent page', data);
        this.logger.info('**PUSHER secure print configuration updated');
        if (!this.scanning) {
          this.refreshPrintJobList();
          // this.tenantService.refreshCurrentTenant(this.tenant.links.self)
          // .subscribe((currentTenant) => {
          //   console.log('## PJ_PAGE NEW_REFRESHED_TENANT');
          //   console.log('PJ_PAGE currentTenant', currentTenant);
          //   this.tenant = currentTenant
          //   this.changeDetectorRef.detectChanges();
          // });
        }
      });
    }

    this.batchExecutedSubscription = this.broadcaster.on<object>(CHANNELS.ALL_TENANT_USERS.EVENTS.BATCH_EXECUTED)
    .subscribe(data => {
      console.log('**PUSHER BATCH EXECUTED', this.printJobsPageType === 'PrintJobsPage' ? 'printJob page' : 'recent page', data);
      this.logger.info('**PUSHER BATCH EXECUTED');
      if (!this.scanning && this.appIsActive && this.isCurrentUrlThisPage()) {
        console.log('## refreshPrintJobList() - PUSHER BATCH EXECUTED');
        this.refreshPrintJobList();
      }
      if (data['reference'] === 'PRINT_QUEUES_DELETE_BATCH') {
      }
    });

    this.platformPauseEventSubscription = this.platform.pause.subscribe(async () => {
      this.appIsActive = false;
    });

    this.platformResumeEventSubscription = this.platform.resume.subscribe(async () => {
      this.appIsActive = true;
    });

    this.platformResumeBroadcastEventSubscription = this.broadcaster.on('PLATFORM_RESUMED').subscribe(() => {
      this.logger.info('PLATFORM_RESUMED');
      this.appIsActive = true;
      if (!this.scanning && this.isCurrentUrlThisPage()) {
        console.log('## refreshPrintJobList() - PLATFORM_RESUMED');
        this.refreshPrintJobList();
      }
    });

    this.reloadPageEventSubscription = this.broadcaster.on('reload_page').subscribe(() => {
      this.logger.info(methodName + 'reload_page');
      if (!this.scanning && this.appIsActive  && this.isCurrentUrlThisPage()) {
        this.refreshPrintJobList(); // for native
      }
    });

    this.isInternetAvailableSubscription = this.networkService.isInternetAvailable
    .pipe(takeUntil(this.unSubscribeInternetAvailability))
    .subscribe((isInternetAccessAvailable: boolean) => {
      this.isInternetAccessAvailable = isInternetAccessAvailable;
      this.changeDetectorRef.detectChanges();
      // console.log('PRINT_JOB_PAGE ############################################################################# isInternetAccessAvailable', isInternetAccessAvailable);
      if (isInternetAccessAvailable) {
        console.log('## refreshPrintJobList() - isInternetAvailableSubscription');
        this.refreshPrintJobList();
      }
    });

    this.languageChangeSubscription = this.broadcaster.on<string>('languageChange')
    .subscribe(data => {
      this.currentLanguage = this.localeService.formatLocaleString(data);
    });
  }

// ################### HANDLE LISTENERS #################################################################

  private getPrintJobList() {
    this.logger.info('getPrintJobList() after PUSHER update')
    this.printJobService.getPrintJobList()
    .subscribe((printJobList: Array<IPrintJob>) => {
      this.printJobList = [];
      this.printJobList = this.printJobsPageType === 'PrintJobsPage' ? printJobList.filter(printJob => !printJob.deletedTime) : printJobList.filter(printJob => printJob.deletedTime); // filter printJobs on deleted time, showing either printJobs or recentPrintJobs
      this.printerService.getPrintersWithPrintJobs(this.printJobList, this.printerUrlString)
      .subscribe((printerList) => {
        this.printerList = printerList;
        this.setPrinterLocation();
        this.changeDetectorRef.detectChanges();
      });
    });
  }

  // private handleAddedJobEvent(data: object): void {

    // this.printJobService.getSinglePrintJob(data['job'])
    // .pipe(takeUntil(this.unSubscribe))
    // .subscribe((printJob: IPrintJob) => {
    //   console.log('printJob', printJob);
    //   let printerVisible: boolean = false;
    //   this.printJobList.push(printJob);
    //   this.printerList.forEach((printer, index) => {
    //     console.log('printer', printer);
    //     if (printer.links.self === printJob.links.printer) {
    //       printerVisible = true;
    //     }
    //   });
    //   if (!printerVisible) {
    //     this.printerService.getSinglePrinter(printJob.links.printer, this.printerUrlString)
    //     .pipe(takeUntil(this.unSubscribe))
    //     .subscribe((newPrinter) => {
    //       this.unSubscribe.next();
    //       this.unSubscribe.complete();
    //       let addedPrinter = newPrinter[0];
    //       if (this.selectedPrintJobs.length > 0) {
    //         if (addedPrinter['links']['self'] !== this.selectedPrintJobs[0]['links']['printer']) {
    //           addedPrinter['options']['allowToPrint'] = false;
    //         }
    //       }
    //       this.printerList.push(addedPrinter);
    //     });
    //   }
    //   this.unSubscribe.next();
    //   this.unSubscribe.complete();
    //   this.ref.detectChanges();
    // });
  // }

  private handleRemovedJobEvent(data: object): void { // will happen both, when printJobs are deleted, and when they are printed
    let deletedJobIndex: number = null;
    let printerUrl: string = null;
    let printersPrintJobs: Array<IPrintJob> = [];
    this.printJobList.forEach((printJob, index) => {
      if (printJob.links.self === data['job']) {
        deletedJobIndex = index;
        printerUrl = printJob.links.printer;
      }
    });

    this.printJobList.splice(deletedJobIndex, 1); // remove job from jobList

    this.printJobList.forEach(printJob => {
      if (printJob.links.printer === printerUrl) {
        printersPrintJobs.push(printJob); // find remaining jobs from same printer
      }
    });

    this.resetPrinterAllowToPrintProperty(0);

    if (printersPrintJobs.length === 0) { // if printer has no more jobs, remove printer from
      let printerIndexToRemove: number = null;
      this.printerList.forEach((printer, index) => {
        if (printer.links.self === printerUrl) {
          printerIndexToRemove = index;
        }
      });
      this.printerList.splice(printerIndexToRemove, 1);
    }
  }

  private handleJobUpdatedEvent(data: object): void {
    this.logger.info('handleJobUpdatedEvent()');
    let jobStates: Array<string> = this.printJobService.PRINT_JOB_APP_STATES;
    let updatedPrintJob: IPrintJob = null;

    this.printJobList.forEach((printjob, index) => {
      if (printjob.links.self === data['job']) {
        updatedPrintJob = this.printJobList[index];
      }
    });
    if (updatedPrintJob !== null) {
     this.handleUpdatedPrintJobs(jobStates, data, updatedPrintJob);
    }
  }

  private handleUpdatedPrintJobs(jobStates, data, updatedPrintJob) {
    this.logger.info('handleUpdatedPrintJobs()');
    jobStates.forEach(state => {
      if (data['jobState'] === state) {
        updatedPrintJob['jobState'] = data['jobState'];
        updatedPrintJob['options']['printJobStatus'] = {
          status: data['jobState'],
          showStatusInfo: data['jobState'] === "CONVERTED" ? false : true,
          showLoader: false,
          isSuccess: data['jobState'] === "PRINT_OK" ? true : false,
          isError: data['jobState'] === "PRINT_FAILED" ? true : false,
        };

        if (data['jobState'] === 'CONVERTED') {
          this.printJobService.getSinglePrintJob(data['job'])  // get the job to update page count
          .pipe(takeUntil(this.unSubscribe))
          .subscribe((printJob: IPrintJob) => {
            this.unSubscribe.next();
            this.unSubscribe.complete();
            updatedPrintJob['pageCount'] = printJob['pageCount'];
          });
        }
      }
    });

    let ongoingRelease: Array<any> = [];

    if (data['_links'] && data['_links']['px:ongoingRelease'] && data['_links']['px:ongoingRelease']['href']) {
      ongoingRelease.push(data['_links']['px:ongoingRelease']);
    } else if (data['_links'] && data['_links']['px:ongoingRelease'] && isArray(data['_links']['px:ongoingRelease'])) {
      ongoingRelease = data['_links']['px:ongoingRelease'];
    }

    updatedPrintJob['links']['ongoingRelease'] = ongoingRelease;
    this.changeDetectorRef.detectChanges();
  }

  private handlePrinterStatusUpdatedEvent(data: object): void {
    this.logger.info('handlePrinterStatusUpdatedEvent()');
    this.printerList.forEach(printer => {
      if (printer.links.self === data['printer']) {
        data['warnings'] ? printer.embedded.status.warnings = data['warnings'] : printer.embedded.status.warnings = [];
        data['errors'] ? printer.embedded.status.errors = data['errors'] : printer.embedded.status.errors = [];
        this.changeDetectorRef.detectChanges();
      }
    });
  }

  private handlePrinterUpdatedEvent(data: object): void {
    this.logger.info('handlePrinterUpdatedEvent()');
    if (!data['partOfBatch']) {
      this.printerList.forEach((printer, index) => {
        if (printer.links.self === data['printer']) {
          this.printerService.getSinglePrinter(printer.links.self, this.printerUrlString)
          .pipe(takeUntil(this.unSubscribe))
          .subscribe((updatedPrinter) => {
            this.unSubscribe.next();
            this.unSubscribe.complete();
            this.printerList[index] = updatedPrinter[0];
            this.changeDetectorRef.detectChanges();
          });
        }
      });
    }
  }

  // private handlePrinterRemovedEvent(data: object): void {
  //   this.logger.info('handlePrinterUpdatedEvent()');
  //   if (!data['partOfBatch']) {
  //     let deletedPrinterIndex: number = null;
  //     this.printerList.forEach((printer, index) => {
  //       if (printer.links.self === data['printer']) {
  //         deletedPrinterIndex = index;
  //       }
  //     });
  //     if (deletedPrinterIndex) {
  //       this.printerList.splice(deletedPrinterIndex, 1);
  //     }
  //     if (data['printer'] === this.user.userAppSettings.lastUsedPrinter) {
  //       this.resetPrinterAndQueue();
  //     }
  //     this.removeFavouriteQueuesFromRemovedPrinter(deletedPrinterIndex);
  //     this.changeDetectorRef.detectChanges();
  //   }
  // }

  // private removeFavouriteQueuesFromRemovedPrinter(deletedPrinterIndex) {
  //   // remove queues from that printer from the favorite queues
  //   const deletedPrinter = this.printerList[deletedPrinterIndex];
  //   let hasDeletedQueue = false;
  //   deletedPrinter['queues'].forEach(q => {
  //     this.favoriteQueueUrls.forEach((qUrl, index) => {
  //       if (qUrl === q.links.self) {
  //         hasDeletedQueue = true;
  //         // throw it out
  //         this.favoriteQueueUrls.splice(index, 1);
  //       }
  //     });
  //   });
  //   if (hasDeletedQueue) {
  //     this.handleDeletedQueue();
  //   }
  // }

  // private handleDeletedQueue() {
  //   this.logger.info('handleDeletedQueue()');
  //   this.userService.setAvailablePinnedPrinterQueues(this.favoriteQueueUrls)
  //   .pipe(takeUntil(this.unSubscribe))
  //   .subscribe(() => {
  //     this.userService.refreshCurrentUser(this.tenant.links.currentUser)
  //     .pipe(takeUntil(this.unSubscribe))
  //     .subscribe(() => {
  //        this.dialogService.hideLoadingSpinnerDialog('handleDeletedQueue()');
  //       this.user = this.userService.user;
  //       this.unSubscribe.next();
  //       this.unSubscribe.complete();
  //     });
  //   });
  // }

  private handlePrintQueueEvents(data: object, queueRemoved: boolean): void {
    this.logger.info('handlePrintQueueEvents()');
    if (!data['partOfBatch']) {
      let printerWithQueueIndex: number = null;
      let deletedQueueIndex: number = null;
      this.printerList.forEach((printer, printerIndex) => {
        printer.queues.forEach((queue, queueIndex) => {
          if (queue.links.self === data['printQueue']) {
            if (!queueRemoved) {
              this.printerService.getSingleQueue(queue.links.self)
              .pipe(takeUntil(this.unSubscribe))
              .subscribe((updatedQueue) => {
                this.unSubscribe.next();
                this.unSubscribe.complete();
                this.printerList[printerIndex]['queues'][queueIndex] = updatedQueue;
              });
            } else {
              printerWithQueueIndex = printerIndex;
              deletedQueueIndex = queueIndex;
            }
          }
        });
      });
      if (queueRemoved) {
        this.handleQueueRemoved(deletedQueueIndex, printerWithQueueIndex, data);
      }
      this.changeDetectorRef.detectChanges();
    }
  }

  private handleQueueRemoved(deletedQueueIndex, printerWithQueueIndex, data) {
    this.logger.info('handleQueueRemoved()');
    if (deletedQueueIndex) {
      this.printerList[printerWithQueueIndex]['queues'].splice(deletedQueueIndex, 1);
    }
    if (data['printQueue'] === this.user.userAppSettings.lastUsedQueue) {
      this.resetPrinterAndQueue();
    }
    // remove the deleted queue from favorite queues
    let hasDeletedQueue = false;
    this.favoriteQueueUrls.forEach((qUrl, index) => {
      if (data['printQueue'] === qUrl) {
        // remove deleted q and update saved qUrls, with new array
        hasDeletedQueue = true;
        this.favoriteQueueUrls.splice(index, 1);
      }
    });

    if (hasDeletedQueue) {
      this.userService.setAvailablePinnedPrinterQueues(this.favoriteQueueUrls)
      .pipe(takeUntil(this.unSubscribe))
      .subscribe(() => {
        this.userService.refreshCurrentUser(this.tenant.links.currentUser)
        .pipe(takeUntil(this.unSubscribe))
        .subscribe((user) => {
          this.dialogService.hideLoadingSpinnerDialog('handleQueueRemoved() - hasDeletedQueue');
          this.user = user;
          this.unSubscribe.next();
          this.unSubscribe.complete();
        });
      });
    }
  }

  // ################### /HANDLE LISTENERS #################################################################

  // ################### /LISTENERS #################################################################

  ionViewWillEnter(): void {
    this.platform.ready().then(() => {
      this.logger.info('ionViewWillEnter()');
      this.storageService.getItemFromLocalStorage('lastUsedTenant')
     .pipe(
        first() // Automatically unsubscribe after the first value is received)
      )
      .subscribe({
        next: (tenant: ITenantLite) => {
          if (tenant) {
            this.logger.info('ionViewWillEnter() - got last used tenant');
            this.getTenantAndUserSubscription.next();
            this.getTenantAndUserSubscription.complete();
            this.lastUsedTenant = tenant;
            this.checkNfcAvailability();
            this.startListener();
          } else {
            this.logger.info('ionViewWillEnter() - NO last used tenant');
            this.authService.logOutUnAuthenticatedUser('ACCESS_DENIED');
          }
        },
        complete: () => {}
      });
    });
  }


  private checkNfcAvailability() {
    const methodName = 'checkNfcAvailability() ';
    this.logger.info(methodName);
    if (this.isCordova) {
      this.nfc.enabled().then((response) => {
        this.logger.info(methodName + 'constructor - NFC enabled()');
        this.nfcEnabled = true;
      }, (error) => {
        if (this.appIsActive && this.isCurrentUrlThisPage()) {
          // this.refreshPrintJobList();
        }
        this.logger.info(methodName + 'constructor - NFC NOT enabled()');
      });
    }
  }

  ionViewWillLeave(): void {
    this.logger.info('ionViewWillLeave()');
    this.unSubscribe.next();
    this.unSubscribe.complete();
    // this.paramSubscription.unsubscribe();
    this.nfcSubscription.next(); // constructor nfc initialisation subscription
    this.nfcSubscription.complete();
    if (this.nfcReaderMode) {
      this.scanning = false;
      this.nfcReaderMode.unsubscribe();
      if (this.platform.is('android')) {
        this.setPreventNFCListener();
      }
    }
    // this.appBackInFocusSubscription.unsubscribe();
    this.unsubscribePusherMessages();
    this.unsubscribeEvents();
    this.unSubscribeInternetAvailability.next();
    this.unSubscribeInternetAvailability.complete();
  }

  private unsubscribePusherMessages(): void {
    this.logger.info('unsubscribePusherMessages()');
    this.jobAddedSubscription.unsubscribe();
    this.jobRemovedSubscription.unsubscribe();
    this.jobUpdatedSubscription.unsubscribe();
    this.tenantUpdatedEventSubscription.unsubscribe();
    this.updateSecurePrintSubscription.unsubscribe();
    this.releaseUpdatedSubscription.unsubscribe();
    this.createdReleaseSubscription.unsubscribe();
    this.batchExecutedSubscription.unsubscribe();
    this.printerStatusSubscription.unsubscribe();
    this.languageChangeSubscription.unsubscribe();
    this.printerRemovedSubscription.unsubscribe();
    this.printerUpdatedSubscription.unsubscribe();
    this.printQueueRemovedSubscription.unsubscribe();
    this.printQueueUpdatedSubscription.unsubscribe();
    this.printQueueGroupsUpdatedSubscription.unsubscribe();
  }

  private unsubscribeEvents() {
    this.platformResumeEventSubscription.unsubscribe();
    this.platformResumeBroadcastEventSubscription.unsubscribe();
    this.reloadPageEventSubscription.unsubscribe();
    this.isInternetAvailableSubscription.unsubscribe();
  }

  // public showPrinterListPage(): void {
  //   this.logger.info('showPrinterListPage()');
  //   console.log('this.releaseResource', this.releaseResource);
  //   this.navCtrl.navigateForward(['/printer-list', this.releaseResource]);
  // }

  public showPrinterListPage(): void {
    this.logger.info('showPrinterListPage()');
    this.navCtrl.navigateForward(['/printer-list'], {queryParams: { resource: this.releaseResource }});
  }

  public goToCapturePage() {
    this.logger.info('goToCapturePage() - actionBar (click)');
    // const tenantLink = this.tenant.links.self;

    // let navigationExtras: NavigationExtras = {
    //   queryParams: {
    //       tenantLink: this.tenant.links.self
    //   }
    // };
    // this.navCtrl.navigateForward(['/printer-list', navigationExtras]);

    this.navCtrl.navigateRoot(['/capture']);
  }

  public showOtherPrintJobs() { // VIEW
    this.logger.info('showOtherPrintJobs()');
    if (this.printJobsPageType === 'RecentPrintJobsPage') {
      // this.printJobsPageType = 'PrintJobsPage';
      this.navCtrl.navigateBack('/print-jobs');
    } else {
      // this.printJobsPageType = 'RecentPrintJobsPage';
      this.navCtrl.navigateForward('/recents');
    }
  }

  ngOnDestroy(): void {
      if (this.unSubscribeQRScanner) {
        this.unSubscribeQRScanner.next();
        this.unSubscribeQRScanner.complete();
      }
  }

  /// ################### /CAMERA/GALLERY STUFF #################################################################

//   private openGallery() {
//     console.log('## OPEN_GALLERY');

//     const options: CameraOptions = {
//      quality: 100,
//      destinationType: this.camera.DestinationType.DATA_URL,
//      sourceType: this.camera.PictureSourceType.PHOTOLIBRARY,
//      mediaType: this.camera.MediaType.PICTURE
//    }

//    this.broadcaster.broadcast('DISABLE_RESUME');
//    this.camera.getPicture(options).then((imageData) => {
//        console.log('## PICTURE_GOT');
//        console.log('## imageData', imageData);
//        alert('## Picture_got imageData: ' + imageData);
//        this.broadcaster.broadcast('ENABLE_RESUME');
//        // imageData is either a base64 encoded string or a file URI
//      }, (error) => {
//        this.broadcaster.broadcast('ENABLE_RESUME');
//        console.log('## handle error from camera.getPicture() openGallery() error: ' + error);
//      }
//    );
//  }

//  public initiateCameera() {
//   this.logger.info('initiateCameera()');
//   console.log('## INITIATE_CAMERA');

//   // this.requestCameraPermission().subscribe((response) => {
//   // });
//   this.checkCameraPermission().subscribe((response) => {
//     console.log('## OPEN_GALLERY_AFTER_REQUEST');
//     if (response.hasPermission) {
//       this.openGallery();
//     } else {
//       console.log('## CAMERA_PERMISSION_DENIED', response);
//     }
//   });
// }


//  public checkCameraPermission(): Observable<any> {
//    this.logger.info('checkCameraPermission()');
//    console.log('## CHECK_CAMERA_PERMISSION');

//    console.log('this.androidPermissions', this.androidPermissions);
//    console.log('this.androidPermissions.PERMISSION', this.androidPermissions.PERMISSION);

//    return new Observable((observer) => {
//      this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.READ_MEDIA_IMAGES).then((read_media_vimages_result) => {
//        console.log('read_media_vimages_result', read_media_vimages_result);
//          if (!read_media_vimages_result.hasPermission) {
//            this.requestCameraPermission().subscribe((response) => {
//              observer.next(response);
//              observer.complete();
//            });
//          } else {
//            observer.next(read_media_vimages_result);
//            observer.complete();
//          }
//       }),
//       ((err) => {
//           console.log('err', err);
//       });
//    });
//  }

//  private requestCameraPermission(): Observable<any> {
//    this.logger.info('requestCameraPermission()');
// console.log('## REQUEST_CAMERA_PERMISSION');
//    return new Observable((observer) => {
//      this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.READ_MEDIA_IMAGES).then((response) => {
//        this.logger.info('requestCameraPermission() - response AFTER REQUESTED PERMISSION: ' + response);
//        console.log('response AFTER REQUESTED PERMISSION', response);
//        observer.next(response);
//        observer.complete();
//      });
//    });
//  }

  // public accessDenied() {
  //   console.log('## CLICKING_ACCESS_DENIED');
  //   this.authService.logOutUnAuthenticatedUser('ACCESS_DENIED');
  // }

  // public async showFileLogs() {
  //   console.log('## SHOW_LOGS');

  //   let logs = this.loggingService.getLogMessagesFromLocalStorage('deviceLogs');

  //   console.log('logs', logs);
    // let fileLogs = this.file.readAsText(this.file.dataDirectory, 'logFile.txt');

    // await fileLogs.then((value) => {
    //   console.log('value', value);

    // });

    // let directoryEntry = this.file.resolveDirectoryUrl(this.file.dataDirectory);

    // await directoryEntry.then((directory) => {

    //   let file = this.file.getFile(directory, 'logs.txt', {}).then((data) => {
    //     console.log('data', data);
    //   });
    // });
  // }

  // public async showErrorModal() {
  //   console.log('## SHOW_ERROR_MODAL_TEST');
  //   let errorObject = {
  //     errorCode: "TESTING_ERROR_MODAL",
  //     errorText: "Test error modal.",
  //     exClass: "net.printix.service.printermanager.proxy2.NoProxiesException",
  //     id: "32d5949f-0149-48f9-ad12-5025ea9da8e9",
  //     originatingService: "printix.service.printer.manager",
  //     parameterizedErrorText: "The printer is not monitored.",
  //     parameters: [],
  //     status: 503
  //   }

  //   let modalType: string = 'http-error-modal';

  //   this.broadcaster.broadcast('showErrorModal', ({
  //     httpErrorResponse: errorObject,
  //     requestMethod: 'FAKE'
  //   }));
  // }

  // public timeOUt() {
  //   this.errorService.delayServerResponse(internalTestWaitTimeUrl, 75000).subscribe(() => {
  //     console.log('## DELAYED');
  //   });
  // }

  // public connectToIntune() {
  //   console.log('## plugin about to start');
  //   let success = function(message) {
  //     alert(message);
  //   };

  //   let failure = function() {
  //       alert("Error calling Hello Plugin");
  //   };

  //   let successFromSDK = function(message) {
  //     console.log('SDK Message', message);
  //     console.log('## RETURNED FROM SDK!');
  //   };

  //   console.log('USER', this.user);
  //   window['IntuneSupport'].echo("HELLO!", success, failure);

  //   window['IntuneSupport'].loginAndEnrollAccount('mads@printix.net', successFromSDK, failure);

  //   console.log('window', window);
  // }

  // public removeAllFavoriteQueues() {
  //   this.userService.removeAllPinnedQueues()
  //   .takeUntil(this.unSubscribe)
  //     .subscribe(() => {
  //       console.log('## ALL FAVORITE QUEUES REMOVED FROM USER');
  //       this.user.userAppSettings.pinnedPrinterQueues = [];
  //       this.unSubscribe.next();
  //       this.unSubscribe.complete();
  //   });
  // }
}
