export const environment_us_dev = {
  production: false,
  appType: 'IONIC_APP',
  appTitle: 'Printix App',
  appLink: 'printixapp://',
  appLogo: 'assets/img/logo/logo-img.png',
  mainDBName: 'data.mainDB',
  tableNameKeyValue: 'printixapp_key_value',
  endpointLogInResponseForgotCode_WepApp: '&response_type=code&client_id=web_app&redirect_uri=https://',
  endpointLogInResponse_WepApp: '?response_type=code&client_id=web_app&redirect_uri=https://',
  endpointLogInResponse_Native: '?response_type=code&client_id=web_app&redirect_uri=',
  endPointAuthorization: 'grant_type=authorization_code&client_id=web_app&client_secret=1234&redirect_uri=',

    // --------------------------- UnActive Endpoints ---------------------------------------------------- //

    // logoUrl: '/assets/img/logo/logo-img.png',
    // navParamLogOut: 'logout',
    // enumerationUrl: 'https://api.us.printix.net/v1/enumerations/PrinterType',

 /********************************************************************************************************************** */
  /** DEV */
  /********************************************************************************************************************** */
  appEndpoint: 'https://app.devenv.printix.net',
  checkServerHealth: 'https://api.devenv.us.printix.net/manage/health',
  internalTestWaitTime: 'https://api.devenv.us.printix.net/internal/testing/wait/',
  internalTestReturnSetStatusCode: 'https://api.devenv.us.printix.net/internal/testing/status/',
  endpointApi: 'https://api.devenv.us.printix.net',
  endpointCodeExchange: 'https://auth.devenv.us.printix.net/oauth/token',
  endpointLogOut: 'https://auth.devenv.us.printix.net/oauth/revoke?token=',
  endpointLogInWepAppTenant: 'https://auth.devenv.us.printix.net/oauth/authorize/tenant/',
  endpointLogInMS: 'https://auth.devenv.us.printix.net/identity-providers/azure/signin?redirect_uri=',
  endpointLogInGoogle: 'https://auth.devenv.us.printix.net/identity-providers/google/signin?redirect_uri=',
  endpointGetTenantUser: 'https://api.devenv.us.printix.net/v1/users/me',
  endpointFindTenant: 'https://api.devenv.us.printix.net/v1.2/find/',
  environmentState: 'devenv'

  // --------------------------- UnActive Endpoints ---------------------------------------------------- //

  // endpointTenantList: 'https://api.devenv.us.printix.net/v1/tenants',
  // endpointError: 'https://api.devenv.us.printix.net',
  // endpointLogIn: 'https://auth.devenv.us.printix.net/oauth/authorize?response_type=code&client_id=hibrid_app&redirect_uri=',
  // endpointToken: 'https://auth.devenv.us.printix.net/oauth/token',
  // endpointRecoverPassword: 'https://auth.devenv.us.printix.net/recover/access',
  // endpointLogInVertical: 'https://auth.devenv.us.printix.net/identity-providers/vertical/signin?redirect_uri=',
  // endpointLogInOneLogin: 'https://auth.devenv.us.printix.net/identity-providers/onelogin/signin?tenant_domain=',
  // endpointLogInOkta: 'https://auth.devenv.us.printix.net/identity-providers/okta/signin?tenant_domain=',
  // directorySnippet: '&directory=printix-dev&redirect_uri=',
};