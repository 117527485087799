import { environment_dev } from '../../../environments/environment.dev';

export function getPxdDevEnvironmentFromSubdomain(subdomain: string): any {
  // devNN.printix.dev & pib-chNN-NN.sandbox.printix.dev environments should have the same configuration as devenv,
  // except for the URL endpoints.
  console.log('subdomain', subdomain);
  const environment_pxd_dev = JSON.parse(JSON.stringify(environment_dev));
  const replacementKeys = ['appEndpoint', 'checkServerHealth', 'internalTestWaitTime', 'internalTestReturnSetStatusCode',
    'endpointApi', 'endpointCodeExchange', 'endpointLogOut', 'endpointLogInWepAppTenant', 'endpointLogInMS',
    'endpointLogInGoogle', 'endpointGetTenantUser', 'endpointFindTenant'];
  replacementKeys.forEach(key => {
    environment_pxd_dev[key] = environment_pxd_dev[key].replace('devenv.printix.net', subdomain);
  });

  // Set the environment prefix like 'environment_pxd_dev01 || environment_pxd_pib-ch01-001'
  environment_pxd_dev.environmentPrefix = 'environment_pxd_' + subdomain.replace('.printix.dev', '');
  return environment_pxd_dev;
}

export function setPxdSubdomainFromUrl(url: string): string {
  // Split the URL into segments
  const urlSegments = url.split('.');

  // Filter segments to find the one that matches 'pib-chxx-xxx' where xx and xxx are at specific positions
  const pibSegment = urlSegments.filter(segment => segment.startsWith('pib-ch') && segment.length === 12)[0];
  const dev0xSegment = urlSegments.filter(segment => segment.startsWith('dev') && segment.length === 5)[0];

  const pxdSubdomain = pibSegment ? `${pibSegment}.sandbox.printix.dev` : `${dev0xSegment}.printix.dev`;
  return pxdSubdomain;
}